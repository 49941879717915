import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Layout from '../views/layout/layout.vue'
import Login from '../views/login/login.vue'
import Index from '../views/index/index.vue'

import BusinessOrderIndex from '../views/business/order/index.vue'
import BusinessOrder from '../views/business/order/order-list.vue'
import BusinessOrderDetail from '../views/business/order/order-detail.vue'

import BusinessPlatformIndex from '../views/business/platform/index.vue'
import BusinessPlatform from '../views/business/platform/platform-list.vue'
import BusinessPlatformDetail from '../views/business/platform/platform-detail.vue'

import OrderIndex from '../views/order/index.vue'
import OrderWallet from '../views/order/wallet.vue'
import OrderTrade from '../views/order/trade-list.vue'
import OrderContract from '../views/order/contract-list.vue'
import OrderRecord from '../views/order/record-list.vue'

import StoreCaseIndex from '../views/store/case/index.vue'
import StoreCaseList from '../views/store/case/case-list.vue'
import StoreCaseAdd from '../views/store/case/case-add.vue'

import StoreResultIndex from '../views/store/result/index.vue'
import StoreResultList from '../views/store/result/result-list.vue'
import StoreResultAdd from '../views/store/result/result-add.vue'
import StoreProduct from '../views/store/product/product-list.vue'

import CompanyBasic from '../views/company/basic/index.vue'
import CompanyAccountIndex from '../views/company/account/index.vue'
import CompanyAccountList from '../views/company/account/account.vue'
import CompanyAccountAdd from '../views/company/account/account-add.vue'
import authIndex from "../views/auth/index.vue";


const routes = [
    {
        path: '/',
        name: '',
        redirect: 'index',
        // component: Login
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '*',
        component: () => import('../views/error/404.vue')
    },
    {
        path: '/index',
        name: 'index',
        redirect: '',
        // redirect: 'business/order/list',
        component: Layout,
        meta: {
            // requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'index-list',
                component: Index,
            },
        ]
    },
    {
        path: '/store',
        name: 'store',
        component: Layout,
        meta: {
            // requiresAuth: true
        },
        children: [
            {
                path: 'case',
                name: 'store-case',
                component: StoreCaseIndex,
                children: [
                    {
                        path: 'list',
                        name: 'case-list',
                        component: StoreCaseList,
                    },
                    {
                        path: 'add',
                        name: 'case-add',
                        component: StoreCaseAdd
                    }
                ]
            },
            {
                path: 'result',
                name: 'store-result',
                component: StoreResultIndex,
                children: [
                    {
                        path: 'list',
                        name: 'result-list',
                        component: StoreResultList,
                    },
                    {
                        path: 'add',
                        name: 'result-add',
                        component: StoreResultAdd
                    }
                ]
            },
            {
                path: 'product',
                name: 'store-product',
                component: StoreProduct
            },
        ]
    },
    {
        path: '/business',
        name: 'business',
        component: Layout,
        children: [
            {
                path: 'order',
                name: 'business-order',
                redirect: 'order/list',
                component: BusinessOrderIndex,
                children: [
                    {
                        path: 'list',
                        name: 'order-list',
                        component: BusinessOrder,
                    },
                    {
                        path: 'detail',
                        name: 'order-detail',
                        component: BusinessOrderDetail
                    }
                ]
            },
            {
                path: 'platform',
                name: 'business-platform',
                redirect: 'platform/list',
                component: BusinessPlatformIndex,
                children: [
                    {
                        path: 'list',
                        name: 'platform-list',
                        component: BusinessPlatform,
                    },
                    {
                        path: 'detail',
                        name: 'platform-detail',
                        component: BusinessPlatformDetail
                    }
                ]
            }
        ]
    },
    {
        path: '/order',
        name: 'order',
        component: Layout,
        children: [
            {
                path: 'wallet',
                name: 'order-wallet',
                component: OrderWallet
            },
            {
                path: 'trade',
                name: 'order-trade',
                component: OrderTrade
            },
            {
                path: 'contract',
                name: 'order-contract',
                component: OrderContract
            },
            {
                path: 'record',
                name: 'order-record',
                component: OrderRecord
            },
        ]
    },
  {
    path: '/auth',
    name: 'auth',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'auth-index',
        component: authIndex
      }
    ]
  },
    {
        path: '/company',
        name: 'company',
        component: Layout,
        meta: {
            // requiresAuth: true
        },
        children: [
            {
                path: 'basic',
                name: 'basic',
                component: CompanyBasic,
            },
            {
                path: 'account',
                name: 'account',
                component: CompanyAccountIndex,
                children: [
                    {
                        path: 'list',
                        name: 'account-list',
                        component: CompanyAccountList,
                    },
                    {
                        path: 'add',
                        name: 'account-add',
                        component: CompanyAccountAdd
                    }
                ]
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// router.beforeEach((to, from, next) => {
//     let acsToken = sessionStorage.getItem('acsToken')
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         //这里判断用户是否登录，验证本地存储是否有token
//         if (!acsToken) { // 判断当前的token是否存在
//             next({
//                 path: '/login',
//                 // query: { redirect: to.fullPath }
//             })
//         } else {
//             next()
//         }
//     } else {
//         next() // 确保一定要调用 next()
//     }
// })

export default router
