<template>
  <div class="flex-container">
    <div class="header" ref="header">
      <!-- <el-descriptions title="" direction="vertical" :column="5">
        <el-descriptions-item label="项目签约总额"></el-descriptions-item>
        <el-descriptions-item label="设计签约"></el-descriptions-item>
        <el-descriptions-item label="施工签约"></el-descriptions-item>
        <el-descriptions-item label="设计签约总额"></el-descriptions-item>
        <el-descriptions-item label="施工签约总额"></el-descriptions-item>
      </el-descriptions> -->
      <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" class="form-inline">
        <el-row>
          <el-col :span="4">
            <el-form-item label-width="0" prop="signNum">
              <el-input
                v-model="queryParams.signNum"
                placeholder="签约编号"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0" prop="contractType">
              <el-select v-model="queryParams.contractType" placeholder="合同类型" clearable>
                <el-option
                  v-for="dict in dict.type.contract_type"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0" prop="businessOpportunityId">
              <el-input
                v-model="queryParams.businessOpportunityId"
                placeholder="所属商机"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0">
              <el-button type="primary" @click="handleQuery">查询</el-button>
              <el-button @click="resetQuery">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  
    <div class="content">
      <el-table v-loading="loading" v-if="tableHeight" :data="contractSignRecordList" :max-height="tableHeight">
        <el-table-column
          type="index"
          width="50">
        </el-table-column>
        <!-- <el-table-column type="selection" width="55" align="center" /> -->
        <el-table-column label="签约编号" align="center" prop="id" />
        <el-table-column label="合同编号" align="center" prop="signNum" width="200" />
        <el-table-column label="合同类型" align="center" prop="contractType" width="150">
          <template slot-scope="scope">
            <dict-tag :options="dict.type.contract_type" :value="scope.row.contractType"/>
          </template>
        </el-table-column>
        <el-table-column label="商机编号" align="center" prop="businessOpportunityCode" width="190"/>
        <el-table-column label="客户公司" align="center" prop="customerFirmName"  width="200"/>
        <el-table-column label="会员商家" align="center" prop="enterpriseName"  width="200"/>
        <el-table-column label="签约金额" align="center" prop="contractAmount" />
      
        <el-table-column label="签约日期" align="center" prop="signTime" width="180">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.signTime, '{y}-{m}-{d}') }}</span>
          </template>
        </el-table-column>
  
        <el-table-column label="审核状态" align="center" prop="checkStatus" >
          <template slot-scope="scope">
            <dict-tag :options="dict.type.sign_record_check_status" :value="scope.row.checkStatus"/>
          </template>
        </el-table-column>

        <!-- <el-table-column
            prop="status"
            label="操作"
            width="100"
            fixed="right"
            align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="handleUpdate(scope.row)">编辑</el-button>
                <el-button type="text" class="text-gray">删除</el-button>
              </template>
        </el-table-column> -->
        
      </el-table>
        
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: "contract-list",
  dicts: ['contract_type','sign_record_check_status'],

  data() {
    return {

      shDetail:false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 签约记录表格数据
      contractSignRecordList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        signNum: '',
        contractType: '',
        businessOpportunityId: ''
      },
      tableHeight: '',
     
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    let searchFormHeight = this.$refs['header'].clientHeight + 20 // 可变的查询高度
    let pageHeight = document.documentElement.clientHeight // 可用窗口的高度
    this.tableHeight = Number(pageHeight - (searchFormHeight + 200));
  },
  methods: {
    /** 查询签约记录列表 */
    getList() {
      this.loading = true;
        this.$api.get('/merchantApi/contractSignRecord/list', {
          ...this.queryParams
        })
        .finally(() => {
          this.loading = false;
        })
        .then(res => {
          if(res.code == 200) {
            this.contractSignRecordList = res.rows;
            this.total = res.total;
            this.loading = false;
          }
        });
    },
     /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
     /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    
     
  }
};
</script>

<style lang="less" scoped>
@import '../../assets/css/form.less';
.title{
  color: #333;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.header{
  ul{
    li{
      display: inline-block;
      margin-right: 100px;
      font-size: 14px;
      .font-large{
        font-size: 30px;
        font-weight: 700;
      }
    }
  }
}
::v-deep .el-descriptions {
  .el-descriptions-item__content {
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
