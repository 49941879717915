<template>
  <div class="flex-container">
    <div class="header" ref="header">
      <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" class="form-inline">
        <el-row>
          <el-col :span="4">
            <el-form-item label-width="0" prop="businessOpportunityName">
              <el-input
                v-model="queryParams.businessOpportunityName"
                placeholder="请输入关键字"
                clearable
              />
              <!-- @keyup.enter.native="handleQuery" -->
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label-width="0" prop="code">
              <el-date-picker
                v-model="queryParams.value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col> -->
          <el-col :span="4">
            <el-form-item label-width="0" prop="processResult">
              <el-select v-model="queryParams.processResult" placeholder="审核状态" clearable>
                <el-option
                  v-for="dict in dict.type.order_back_process_result"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0">
              <el-button type="primary" @click="handleQuery">查询</el-button>
              <el-button @click="resetQuery">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
     </div>
    <div class="content">
        <el-table v-loading="loading" v-if="tableHeight" :data="tableData" @row-click="openDetails" :max-height="tableHeight">
          <el-table-column
            type="index"
            width="50">
          </el-table-column>
          <el-table-column label="退单申请编号" prop="code" width="200"/>
          <el-table-column label="商机名称" prop="businessOpportunityName" width="150" />
          <el-table-column label="退款金额" prop="money" width="150" />

          <el-table-column label="退单原因" prop="reason" width="150">
              <template slot-scope="scope">
              <dict-tag
                  :options="dict.type.order_back_reason"
                  :value="scope.row.reason"
              /></template>
          </el-table-column>

          <el-table-column label="提交人" prop="createAccountName" width="120">
          </el-table-column>
          <el-table-column label="创建时间" prop="createTime" width="150" />

          <el-table-column label="平台处理时间" prop="processTime" width="150" />

          <el-table-column label="审核状态" align="center" prop="processResult">
            <template slot-scope="scope">
              <dict-tag :options="dict.type.order_back_process_result" :value="scope.row.processResult"/>
            </template>
          </el-table-column>
          
          <el-table-column
            label="操作"
            width="100"
            fixed="right"
            align="center">
            <template slot-scope="scope">
              <!-- <el-button type="text" @click="handleUpdate(scope.row)">编辑</el-button> -->
              <el-button type="text" class="text-gray" @click="handleDetail(scope.row.id)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
    </div>

    <el-dialog title="退单申请详情" :visible.sync="dialogTableVisible" destroy-on-close>
      <el-descriptions title="申请信息" v-loading="detailLoading" :column="3">
        <el-descriptions-item label="编号" :span="4">{{ details.code }}</el-descriptions-item>
        <el-descriptions-item label="申请人">{{ details.createAccountName }}</el-descriptions-item>
        <el-descriptions-item label="退单原因">
          <dict-tag :options="dict.type.order_back_reason" :value="details.reason"/>
        </el-descriptions-item>
        <el-descriptions-item label="审核状态">
          <dict-tag :options="dict.type.order_back_process_result" :value="details.processResult"/>
        </el-descriptions-item>
        <el-descriptions-item label="退单备注">
          {{ details.rejectReason }}
        </el-descriptions-item>
        <el-descriptions-item label="审核说明">{{ details.remarks }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="扣款记录" :column="3">
        <el-descriptions-item label="交易流水号" :span="4">{{ details.code }}</el-descriptions-item>
        <el-descriptions-item label="扣款金额">{{ details.money }}</el-descriptions-item>
        <el-descriptions-item label="平台处理时间	">{{ details.processTime }}</el-descriptions-item>
        <el-descriptions-item label="商机ID">{{ details.businessOpportunityId }}</el-descriptions-item>
        <!-- <el-descriptions-item label="关联项目ID">{{ details.payNo }}</el-descriptions-item> -->
        <!-- <el-descriptions-item label="商家客服">{{ details.payNo }}</el-descriptions-item> -->
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "OrderBackRecord",
   dicts: ['order_back_reason','order_back_process_result'],
   components: {
   
  },
  data() {
    return {
      dialogTableVisible: false,
      detailLoading: true,
      details: {},
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 退单记录表格数据
      orderBackRecordList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      tableHeight:'',
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        code: '',
        businessOpportunityName: '',
        processResult: ''
      },

      tableData: [],
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        enterpriseId: [
          { required: true, message: "退单申请所属商家ID不能为空", trigger: "blur" }
        ],
        businessOpportunityId: [
          { required: true, message: "退单申请所属商机ID不能为空", trigger: "blur" }
        ],
      }
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    let searchFormHeight = this.$refs['header'].clientHeight + 20 // 可变的查询高度
    let pageHeight = document.documentElement.clientHeight // 可用窗口的高度
    this.tableHeight = Number(pageHeight - ( searchFormHeight + 100 ));
  },
  methods: {
    openDetails(row){
      this.dialogVisible=true
    },
    /** 查询退单记录列表 */
    getList() {
      this.loading = true;
        this.$api.get('/merchantApi/orderBackRecord/list', {
          ...this.queryParams
        })
        .finally(() => {
          this.loading = false;
        })
        .then(res => {
          if(res.code == 200) {
            this.tableData = res.rows;
            this.total = res.total;
            this.loading = false;
          }
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        enterpriseId: null,
        businessOpportunityId: null,
        reason: null,
        remarks: null,
        processResult: null,
        rejectReason: null,
      
        status: "0",
        updateAccountId: null,
        createTime: null,
        updateTime: null,
        processTime: null,
        createAccountId: null,
        businessOpportunityName: null,
        customerId: null,
        money:null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    
    /** 详情按钮操作 */
    handleDetail(id) {
      this.dialogTableVisible = true;
      this.detailLoading = true;
      this.$api.get('/merchantApi/orderBackRecord/'+id)
      .finally(() => {
        this.detailLoading = false;
      })
      .then(res => {
        if(res.code == 200) {
          this.details = res.data;
        }
      });
    },
    
  }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/form.less';
.dialog .item{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.dialog .item>div:first-child {
  width: 300px;
}
.dialog .item .dialog-title{
  color: #80939a;
  margin-bottom: 3px;
}

::v-deep .el-dialog {
  .el-descriptions-item__container {
    align-items: center;
  }
}
</style>
