<template>
    <div>
        <el-form v-if="!loading" :inline="true" :model="setData" class="form-inline" label-position="right" style="width: 360px;">
            <el-form-item label="账号名称">
                <el-input v-model="setData.name" placeholder="请输入账号名称" disabled></el-input>
            </el-form-item>
            <el-form-item label="账号手机号">
                <el-row>
                    <el-col :span="18">
                        <el-input v-model="setData.phonenumber" disabled></el-input>
                    </el-col>
                    <el-col :span="6" style="text-align: right;">
                        <el-button type="primary" plain @click="mobileDialogVisible = true;">修改</el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="登入密码">
                <el-row>
                    <el-col :span="18">
                        <el-input type="password" v-model="loginPass" disabled></el-input>
                    </el-col>
                    <el-col :span="6" style="text-align: right;">
                        <el-button type="primary" plain @click="passDialogVisible = true;">修改</el-button>
                    </el-col>
                </el-row>
            </el-form-item>
        </el-form>

        <el-dialog title="修改手机号" :visible.sync="mobileDialogVisible" width="450px" class="set-dialog" @close="closeDialog('phoneForm')">
            <el-tabs value="1" v-if="setPhoneForm.phonenumber && !verifyNewPhone">
                <el-tab-pane label="验证当前手机号" name="1"></el-tab-pane>
            </el-tabs>
            <el-form :model="setPhoneForm" status-icon :rules="phoneRules" ref="phoneForm" label-width="70px" label-position="left" class="set-ruleForm">
                <el-form-item label="手机号" prop="phonenumber">
                    <el-input v-model="setPhoneForm.phonenumber" autocomplete="off" placeholder="请输入手机号码" :disabled="!verifyNewPhone"></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="verificationCode">
                    <div class="input-code">
                        <el-input v-model="setPhoneForm.verificationCode" autocomplete="off" placeholder="请输入验证码">
                        </el-input>
                        <el-button
                            v-if="!verifyNewPhone"
                            type="primary"
                            plain
                            class="code-btn"
                            @click="getCode('phoneForm')"
                            :disabled="verifyBtnDisabled">
                            {{ verifyBtnText }}
                        </el-button>
                        <el-button
                            v-else
                            type="primary"
                            plain
                            class="code-btn"
                            @click="getCode('phoneForm')"
                            :disabled="verifyBtnDisabled">
                            {{ verifyBtnText }}
                        </el-button>
                    </div>         
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="mobileDialogVisible = false">取 消</el-button>
                <el-button type="primary" v-if="setPhoneForm.phonenumber && !verifyNewPhone" :disabled="saving" @click="verifyNext('phoneForm')">下一步</el-button>
                <el-button type="primary" v-else :disabled="saving" @click="submitSetForm('phoneForm', 1)">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改密码" :visible.sync="passDialogVisible" width="480px" class="set-dialog" @close="closeDialog('passForm')">
            <el-tabs v-model="setTabName" @tab-click="handleClick('passForm')">
                <el-tab-pane label="登录密码验证" name="1"></el-tab-pane>
                <el-tab-pane label="手机号验证" name="2"></el-tab-pane>
            </el-tabs>
            <el-form :model="setPassForm" status-icon :rules="passRules" ref="passForm" label-width="100px" label-position="left" class="set-ruleForm">
                    <template v-if="setTabName == 2">
                        <el-form-item v-if="setData.phonenumber" label="手机号" prop="phonenumber" style="visibility: hidden; position: absolute;">
                            <el-input v-model="setPassForm.phonenumber" autocomplete="off"></el-input>
                        </el-form-item>

                        <el-form-item v-else label="手机号" prop="phonenumber">
                            <el-input v-model="setPassForm.phonenumber" autocomplete="off" placeholder="请输入手机号码"></el-input>
                        </el-form-item>

                        <el-form-item label="验证码" prop="verificationCode">
                            <div class="input-code">
                                <el-input v-model="setPassForm.verificationCode" autocomplete="off" placeholder="请输入验证码">
                                </el-input>
                                <el-button
                                    type="primary"
                                    plain
                                    class="code-btn"
                                    @click="getCode('passForm')"
                                    :disabled="verifyBtnDisabled">
                                    {{ verifyBtnText }}
                                </el-button>
                            </div>         
                        </el-form-item>
                    </template>
                    <el-form-item label="登录密码" prop="oldPassword" v-else>
                        <el-input type="password" v-model="setPassForm.oldPassword"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="newPassword">
                        <el-input type="password" v-model="setPassForm.newPassword"></el-input>
                    </el-form-item>
                    <el-form-item label="确认新密码" prop="confirmPassword">
                        <el-input type="password" v-model="setPassForm.confirmPassword"></el-input>
                    </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="passDialogVisible = false">取 消</el-button>
                <el-button type="primary" :disabled="saving" @click="submitSetForm('passForm', 2)">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import _ from 'lodash';
export default {
    name: 'basic-setting',
    components: {},
    data () {
        const checkPhone = (rule, value, callback) => {
            const reg = /^1[345678]\d{9}$/;
            if ( reg.test(value) ) {
                return callback();
            }
            callback('请输入合法的手机号');
        };
        return {
            loginPass: '******',
            loading: true,
            saving: false,
            verifyTimer: null,
            setData: {
                name: '',
                phonenumber: '',
                oldPassword: '',
            },
            setPhoneForm: {
                name: '',
                phonenumber: '',
                verificationCode: '',
            },
            setPassForm: {
                confirmPassword: '',
                newPassword: '',
                oldPassword: '',
                verificationCode: '',
                phonenumber: '',
            },
            verifyNewPhone: true,
            verifyBtnDisabled: false,
            verifyBtnText: '获取验证码',
            setTabName: '1',
            phoneRules: {
                phonenumber: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
				    { required: true, trigger: 'blur', validator: checkPhone }
                ],
                verificationCode: [{required: true, message: '请输入验证码', trigger: 'blur' } ]
            },
            passRules: {
                phonenumber: [],
                verificationCode: [{required: true, message: '请输入验证码', trigger: 'blur' } ],
                oldPassword: [{required: true, message: '请输入原密码', trigger: 'blur' } ],
                confirmPassword: [{required: true, message: '请输入新密码', trigger: 'blur' } ],
                newPassword: [{required: true, message: '请输入新密码', trigger: 'blur' } ]
            },
            mobileDialogVisible: false,
            passDialogVisible: false,
        };
    },
    created() {
        this.getSetData();
    },
    methods: {
        // 获取安全设置信息
        getSetData() {
            this.loading = true;
            this.$api.get('/merchantApi/merchant', {
            })
            .finally(() => {
                this.loading = false;
            })
            .then(res => {
                if(res.code == 200) {
                    this.setData = _.cloneDeep(res.data);
                    this.setPhoneForm.phonenumber = res.data.phonenumber;
                    this.setPassForm.phonenumber = res.data.phonenumber;
                    // this.setPhoneForm = _.assign(res.data);
                    // this.setPassForm = _.assign(res.data, this.setPassForm);
                    console.log(this.setPassForm);
                    if(this.setPhoneForm.phonenumber) {
                        this.verifyNewPhone = false;
                    }
                }
            })
        },
        verifyNext(formName) {
            // 
            if(this.saving) {
                return false;
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.saving = true;
                    this.$api.post('/merchantApi/merchant/validatePhone', {
                        phonenumber: this.setPhoneForm.phonenumber,
                        verificationCode: this.setPhoneForm.verificationCode,
                    })
                    .finally(res => {
                        this.saving = false;
                    })
                    .then(res => {
                        if(res.code == 200) {
                            this.verifyNewPhone = true;
                            this.closeDialog('phoneForm');
                            this.setPhoneForm.phonenumber = '';
                            this.setPhoneForm.verificationCode = '';
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 获取验证码
        getCode(formName) {
            this.$refs[formName].validateField('phonenumber', (valid) => {
                if(!valid){
                    // console.log(this.setPhoneForm);
                    this.tackCode();   //验证码倒数60秒

                    this.$api.get('/merchantApi/merchant/getVerificationCode', {
                        phone: this.setPhoneForm.phonenumber
                    })
                    .then(res => {
                        if(res.code == 200) {
                        }
                    });
                    
                }else{
                    console.log('未通过')
                    return;
                }
            })
        },
        tackCode(){       //验证码倒数60秒
            let time = 60;
            this.verifyTimer = setInterval(() => {
                if(time == 0){
                    clearInterval(this.verifyTimer);
                    this.verifyBtnText = '获取验证码';
                    this.verifyBtnDisabled = false;
                }else {
                    this.verifyBtnDisabled = true;
                    this.verifyBtnText = time + '秒后重试';
                    time--;
                }
            }, 1000);
        },
        // 修改绑定 手机号/密码
        submitSetForm(formName, type) {
            if(this.saving) {
                return false;
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    if(type == 1) {
                        // 手机号
                        this.saving = true;
                        this.$api.post('/merchantApi/merchant/bindPhone', {
                            phonenumber: this.setPhoneForm.phonenumber,
                            verificationCode: this.setPhoneForm.verificationCode,
                        })
                        .finally(res => {
                            this.saving = false;
                        })
                        .then(res => {
                            if(res.code == 200) {
                                this.$message.success(res.msg);
                                this.mobileDialogVisible = false;
                                this.getSetData();
                            }
                        });
                    } else {
                        // 密码
                        let form = _.cloneDeep(this.setPassForm);
                        if(form.confirmPassword != form.newPassword) {
                            this.$message.warning('两次密码输入不一致');
                            return false;
                        }
                        this.saving = true;
                        this.$api.post('/merchantApi/merchant/editPassword', form)
                        .finally(res => {
                            this.saving = false;
                        })
                        .then(res => {
                            if(res.code == 200) {
                                this.$message.success(res.msg);
                                this.passDialogVisible = false;
                                this.getSetData();
                            }
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        handleClick(formName) {
            this.$refs[formName].resetFields();
            this.$refs[formName].clearValidate();
        },
        closeDialog(formName) {
            this.setTabName = '1';
            clearInterval(this.verifyTimer);
            this.verifyBtnText = '获取验证码';
            this.verifyBtnDisabled = false;
            this.$refs[formName].resetFields();
            this.$refs[formName].clearValidate();
        }
    }
}

</script>
<style lang='less' scoped>
.el-tabs {
    margin-bottom: 20px;
}
</style>