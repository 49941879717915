import axios from 'axios';
import qs from 'qs'
import Vue from 'vue';
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import errorCode from '@/utils/errorCode'
import { getToken, setToken, setExpiresIn, removeToken } from '@/utils/auth'
import store from '@/store'

// Message.success(options)
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// axios.defaults.headers['Content-Type'] = 'Content-Type:application/x-www-form-urlencoded'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true,
  timeout: 10000,
});
let isReloginShow;

// axios.defaults.headers.common['Authorization'] = token;

service.interceptors.request.use(function(config) {
  // 在发送请求之前做些什么
  //若token存在，则在每次请求头中加入token
  let token = getToken();
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  // config.data = qs.stringify(config.data)
  return config;
  }, function(error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

service.interceptors.response.use(function(res) {
  // console.log(res);
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  // 二进制数据则直接返回
  // if(res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer'){
  //   return res.data
  // }
  if (code === 401 || code === 9999) {
    if (!isReloginShow) {
      isReloginShow = true;
      MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        isReloginShow = false;
        store.commit('SET_TOKEN', '')
        removeToken()
        
        if (window.location.hash.indexOf("#/login") != 0) {
          location.href = '/index';
        }

        // store.dispatch('FedLogOut').then(() => {
        //   // 如果是登录页面不需要重新加载
        //   if (window.location.hash.indexOf("#/login") != 0) {
        //     location.href = '/index';
        //   }
        // })
      }).catch(() => {
        isReloginShow = false;
      });
    }
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
  } else if (code === 500) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(new Error(msg))
  }else if (code !== 200) {
    Notification.error({
      title: msg
    })
    return Promise.reject('error')
  } else {
    return res.data
  }
  
}, function(error) {
	// Do something with response error
	return Promise.reject(error)
})

// const baseURL = 'http://120.24.36.71:8080';
// const baseURL = process.env.VUE_APP_BASE_API;
// Vue.prototype.$serviceUrl = baseURL;

export default {
  get: function (url, params) {
    return service.get(`${url}`, { params: params }).then(res => res)
  },
  post: function (url, params) {
    return service.post(`${url}`, params).then(res => res)
  },
  put: function (url, params) {
    return service.put(`${url}`, params).then(res => res)
  },
  delete: function (url) {
    return service.delete(`${url}`).then(res => res)
  },
  getRes: service,
}