<template>
  <div class="flex-container">
    <div class="header" ref="header">
      <el-form :inline="true" :model="formInline" class="form-inline" ref="formInline">
        <el-row>
          <el-col :span="4">
            <el-form-item label-width="0" prop="caseName">
              <el-input v-model="formInline.caseName" placeholder="案例标题"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0" prop="recommendType">
              <el-select v-model="formInline.recommendType" placeholder="推荐类型" clearable>
                <el-option
                  v-for="(item, index) in dict.type.case_is_recommend"
                  :key="index"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0" prop="industryType">
              <el-select v-model="formInline.industryType" placeholder="行业类型" clearable>
                <el-option
                  v-for="(item, index) in dict.type.firm_trade"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0" prop="caseArea">
              <el-select v-model="formInline.caseArea" placeholder="选择面积" clearable>
                <el-option
                  v-for="(item, index) in dict.type.area_array"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0" prop="personId">
              <el-select v-model="formInline.personId" placeholder="设计师选择" clearable>
                <el-option label="设计师选择" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0" prop="checkStatus">
              <el-select v-model="formInline.checkStatus" placeholder="审核状态" clearable>
                <el-option
                  v-for="(item, index) in dict.type.case_check_status"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0">
              <el-button type="primary" @click="onSubmit">查询</el-button>
              <el-button @click="reset('formInline')">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="content">
      <div class="table-handle">
        <el-button size="small" type="primary" @click="add">添加案例</el-button>
        <!-- <el-button size="small">置顶</el-button> -->
        <el-button size="small" @click="remove()">删除</el-button>
      </div>
      <el-table
        v-if="tableHeight"
        :data="tableData"
        style="width: 100%"
        :max-height="tableHeight"
        v-loading="loading"
        ref="multipleTable"
      >
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          prop="url"
          label="缩略图"
          width="150">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.coverFileUrl"
              style="width: 100px; height: 100px"
              :src="$fileBaseUrl + scope.row.coverFileUrl"
              fit="contain">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="caseName"
          label="案例名称"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="industryType"
          label="所属行业"
          width="150">
          <template slot-scope="scope">
            <dict-tag :options="dict.type.firm_trade" :value="scope.row.industryType"/>
          </template>
        </el-table-column>
        <el-table-column
          prop="caseArea"
          label="项目面积"
          width="150">
        </el-table-column>
        <!-- <el-table-column
          prop="caseType"
          label="项目属性"
          width="150">
          <template slot-scope="scope">
            <dict-tag :options="dict.type.lbg_project_type" :value="scope.row.caseType"/>
          </template>
        </el-table-column> -->

        <el-table-column
          prop="recommendType"
          label="推荐类型"
          width="150">
          <template slot-scope="scope">
            <dict-tag :options="dict.type.case_is_recommend" :value="scope.row.isRecommend"/>
          </template>
        </el-table-column>

        <el-table-column
          prop="checkStatus"
          label="审核状态"
        >
          <template slot-scope="scope">
            <dict-tag :options="dict.type.case_check_status" :value="scope.row.checkStatus"/>
          </template>
        </el-table-column>

        <el-table-column
          prop="createTime"
          label="上传时间"
          width="180">
        </el-table-column>

        <el-table-column
          prop="checkTime"
          label="审核通过时间"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="店铺推荐"
          width="120"
          fixed="right"
          align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isStoreRecommend"
              :active-value="1"
              :inactive-value="0"
              @change="onSwitchChange(scope.row,$event)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="操作"
          width="120"
          fixed="right"
          align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
            <el-button type="text" @click="remove(scope.row.id)" class="text-gray">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="formInline.pageNum"
        :limit.sync="formInline.pageSize"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script>
import {industryList, areaList, statusList} from '@/utils/variable';

export default {
  name: 'case-list',
  components: {},
  dicts: [
    'case_check_status',
    'case_is_recommend',
    'firm_trade',
    'lbg_project_type',
    'area_array'
  ],
  data() {
    return {
      industryList: industryList,
      areaList: areaList,
      statusList: statusList,
      total: 0,
      loading: true,
      formInline: {
        pageNum: 1,
        pageSize: 10,
        caseName: '',
        recommendType: '',
        industryType: '',
        caseArea: '',
        personId: '',
        checkStatus: ''
      },
      tableData: [],
      tableHeight: ''
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    let searchFormHeight = this.$refs['header'].clientHeight + 20 // 可变的查询高度
    let pageHeight = document.documentElement.clientHeight // 可用窗口的高度
    this.tableHeight = Number(pageHeight - (searchFormHeight + 100 + 130));
  },
  methods: {
    /**
     * 店铺推荐开关回调
     */
    onSwitchChange(row, e) {
      this.$api.put('/merchantApi/storeCase/storeRecommend', {
        id: row.id,
        isStoreRecommend: e
      }).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getList()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    getList() {
      this.loading = true;
      this.$api.get('/merchantApi/storeCase/list', {
        ...this.formInline
      })
        .finally(() => {
          this.loading = false;
        })
        .then(res => {
          if (res.code == 200) {
            this.tableData = res.rows;
            this.total = res.total;
          }
        });
    },
    add() {
      this.$router.push({
        path: '/store/case/add'
      });
    },
    edit(id) {
      this.$router.push({
        path: '/store/case/add',
        query: {id: id}
      });
    },
    remove(id) {
      let params = [];
      if (id) {
        // 单个删除
        params.push(id);
      } else {
        // 多个删除
        let ref = this.$refs.multipleTable;
        if (ref.selection && ref.selection.length) {
          ref.selection.map(item => {
            params.push(item.id);
          })
        } else {
          this.$message({
            message: '没有选中数据',
            type: 'warning'
          });
          return false;
        }
      }
      this.$confirm('是否确认删除？')
        .then(res => {
          this.$api.delete('/merchantApi/storeCase/' + params.join()).then(res => {
            this.getList();
          })
          done();
        })
        .catch(res => {
        });
    },
    onSubmit() {
      this.formInline.pageNum = 1;
      this.getList();
    },
    reset(formName) {
      this.$refs[formName].resetFields();
      this.onSubmit();
    },
    handleSizeChange() {
    },
    handleCurrentChange(value) {
      this.pageNum = value;
      this.getList();
    }
  }
}

</script>
<style lang='less' scoped>
@import '../../../assets/css/form.less';
</style>
