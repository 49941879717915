<template>
    <div class="detail-container">
      <el-card class="card-left">
        <div slot="header" class="clearfix">
          <span>{{ personId ? '编辑人员' : '添加人员' }}</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="back">返回</el-button>
        </div>
        <el-form v-if="!loading" :inline="true" :model="form" class="form-inline" label-position="right" :rules="rules" ref="ruleForm">
          <el-row>
              <el-col :span="24">
                  <el-form-item label="头像">
                      <el-upload
                          class="avatar-uploader"
                          :action="uploadFileUrl"
                          :headers="headers"
                          accept=".jpeg, .png, .jpg"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          :before-upload="beforeAvatarUpload">
                          <img v-if="form.profilePhotoFileUrl" :src="form.profilePhotoFileUrl" class="avatar">
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="用户名(登录名)" prop="userName">
                <el-input v-model="form.userName" placeholder="请输入用户名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="昵称" prop="nickName">
                <el-input v-model="form.nickName" placeholder="请输入昵称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="手机号" prop="phonenumber">
                <el-input v-model="form.phonenumber" placeholder="请输入手机号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="角色" prop="roleType">
                  <el-select v-model="form.roleType" placeholder="请选择角色" clearable>
                    <el-option
                      v-for="(item, index) in dict.type.lbg_store_person_role"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
              </el-form-item>  
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="姓名" prop="personName">
                <el-input v-model="form.personName" placeholder="请输入姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="身份证" prop="idNumber">
                <el-input v-model="form.idNumber" placeholder="请输入身份证"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="从业经验" prop="workExper">
                <el-input placeholder="请输入从业经验" v-model="form.workExper">
                  <template slot="append">年</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="职务" prop="professionalTitleId">
                  <el-select v-model="form.professionalTitleId" placeholder="请选择职务" clearable>
                    <el-option
                      v-for="(item, index) in dict.type.position"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
              </el-form-item>  
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="擅长空间" prop="specialty">
                 <el-checkbox-group v-model="form.specialty">
                      <el-checkbox
                          v-for="(item, index) in dict.type.lbg_specialty"
                          :key="index"
                          :label="item.value"
                          name="type"
                      >{{ item.label }}</el-checkbox>
                  </el-checkbox-group>
              </el-form-item>
            </el-col>
             <el-col :span="12">
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item label="所在城市" prop="provinceId">
                    <el-select v-model="form.provinceId" placeholder="请选择省" clearable @change="changeProvince">
                        <el-option
                          v-for="(item, index) in provinceList"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label=" " prop="cityId">
                    <el-select v-model="form.cityId" placeholder="请选择市" clearable @change="changeCity">
                        <el-option
                          v-for="(item, index) in cityList"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label=" " prop="districtId">
                    <el-select v-model="form.districtId" placeholder="请选择区" clearable>
                      <el-option
                        v-for="(item, index) in areaList"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          
          <el-form-item style="text-align: right;">
            <el-button @click="back">取消</el-button>
            <el-button type="primary"  :disabled="saving" @click="submitForm('ruleForm')">提交审核</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
</template>

<script>
import { getToken } from '@/utils/auth';
import _ from 'lodash';
export default {
    name: 'result-add',
    components: {},
    dicts: [
      'approval_status',
      'firm_trade',
      'store_contract_type',
      'store_result_type',
      'store_case_cate',
      'position',
      'lbg_store_person_role',
      'lbg_specialty',
    ],
    data () {
      return {
        uploadFileUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
        headers: {
          Authorization: "Bearer " + getToken()
        },
        caseList: [],
        provinceList: [],
        cityList: [],
        areaList: [],
        saving: false,
        loading: true,
        personId: '',
        form: {
          profilePhotoFileId: '',
          profilePhotoFileUrl: '',
          userName: '',
          nickName: '',
          phonenumber: '',
          roleType: '',
          personName: '',
          idNumber: '',
          workExper: '',
          professionalTitleId: '',
          specialty: [],
          provinceId: '',
          cityId: '',
          districtId: '',
        },
        files: {
          pic1: [],
          pic2: [],
          pic3: [],
          pic4: [],
        },
        rules: {
          userName: [
            { required: true, message: '请输入用户名', trigger: 'blur' }
          ],
          nickName: [
            { required: true, message: '请输入昵称', trigger: 'blur' }
          ],
          phonenumber: [
            { required: true, message: '请输入手机号', trigger: 'blur' }
          ],
        },
        fileSize: 5,
        fileList: []
      };
    },
    created() {
      this.getProvinceList();
      this.personId = this.$route.query.id || '';
      if(this.personId) {
        this.getDetails();
      } else {
        this.loading = false;
      }
    },
    methods: {
      getDetails() {
        this.$api.get('/merchantApi/storePerson/'+this.personId, {
        }).then(res => {
          if(res.code == 200) {
            this.form = res.data;
            if(this.form.provinceId) {
              this.getCityList(this.form.provinceId);
            }
            if(this.form.cityId) {
              this.getAreaList(this.form.cityId);
            }
            if(this.form.profilePhotoFileUrl) {
              this.form.profilePhotoFileUrl = this.$fileBaseUrl + this.form.profilePhotoFileUrl;
            }
            this.form.specialty = this.form.specialty ? this.form.specialty.split() : [];
            this.loading = false;
          }
        })
      },
      back() {
        this.$router.back();
      },
      submitForm(formName) {
        if(this.saving) {
          return false;
        }
        let form = _.cloneDeep(this.form);
        form.specialty = form.specialty.join();
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saving = true;
            if(this.personId) {
              this.$api.put('/merchantApi/storePerson', form)
              .finally(() => {
                this.saving = false;
              })
              .then(res => {
                if(res.code == 200) {
                  this.$message.success(res.msg);
                  this.back();
                } else {
                  this.$message.error(res.msg);
                }
              })
            } else {
              this.$api.post('/merchantApi/storePerson', form)
              .finally(() => {
                this.saving = false;
              })
              .then(res => {
                if(res.code == 200) {
                  this.$message.success(res.msg);
                  this.back();
                } else {
                  this.$message.error(res.msg);
                }
              })
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      getProvinceList() {
        this.$api.get('/lbg/province/list')
        .then(res => {
          if(res.code == 200) {
            this.provinceList = res.rows;
          }
        })
      },
      getCityList(id) {
        this.$api.get('/lbg/city/list', {
          provinceId: this.form.provinceId
        })
        .then(res => {
          if(res.code == 200) {
            this.cityList = res.rows;
          }
        })
      },
      getAreaList(id) {
        this.$api.get('/lbg/district/list', {
          cityId: id
        })
        .then(res => {
          if(res.code == 200) {
            this.areaList = res.rows;
          }
        })
      },
      changeProvince(id) {
        this.getCityList(id);
        this.form.cityId = '';
        this.form.districtId = '';
      },
      changeCity(id) {
        this.getAreaList(id);
        this.form.districtId = '';
      },


      // 公司logo
      handleAvatarSuccess(res) {
          if(res.code == 200) {
              this.form.profilePhotoFileId = res.data.fileId;
              this.form.profilePhotoFileUrl = res.data.url;
              this.$message.success("上传成功");
          } else {
              this.form.profilePhotoFileId = '';
              this.form.profilePhotoFileUrl = '';
              this.$message.error(res.msg);
          }
      },
      beforeAvatarUpload(file) {
          // 校检文件大小
          const isLt = file.size / 1024 / 1024 < 5;
          if (!isLt) {
              this.$message.error(`上传文件大小不能超过5MB!`);
              return false;
          }
      },
    }
}

</script>
<style lang='less' scoped>
::v-deep .el-card {
  display: flex;
  flex-direction: column;

  .el-card__body {
    flex: 1;
    overflow-y: auto;
  }
}
::v-deep .form-inline {
  // padding-left: 10px;
  .el-form-item {
    display: block !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
    .el-form-item__label {
      height: 40px;
      font-weight: bold;
    }
    .el-form-item__content {
      display: block !important;
    }
    .el-select {
      width: 100%;
    }
    .el-textarea__inner {
      height: 100px;
    }
  }
}
.case-prompt {
  .prompt-content {
    margin-top: 20px;
    font-size: 14px;
    color: #303133;

    &:first-child {
      margin: 0;
    }
  }
  .prompt-title {
    font-weight: bolder;
    font-size: medium;
  }
  .prompt-item {
    margin: 5px 0;
    color: #606266;
  }
}
.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 60px;
    height: 60px;

    .avatar {
      width: 60px;
      height: 60px;
      display: block;
  }
}
.avatar-uploader:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}
</style>