<template>
    <el-container id="app">
        <div class="content">
            <div class="header">
                <div class="logo" ></div>
                <p class="slogan">装饰设计公司业绩增长一站式服务平台</p>
                <p class="title2 description" style="display: none;" > 狸办公拥有国内办公领域丰富的用户资源池及精细化运营，实现高效转化。为服务商赋能，达到业绩增速品牌沉淀，助力服务商成为细分领域头部企业</p>
               
            </div>
            <div class="center">
                <div class="slide">
                    <img :src="$fileBaseUrl+'/files/20210404/businessad/1.png/loginad'" alt="">
                </div>
                <div class="sign-view" v-if="current == 1">
                    <div class="view-top">
                        <div class="tabs">
                            <div class="tab-item" :class="active == 1 ? 'active' : ''"  @click="handleClick(1)">免密码登录</div>
                            <div class="tab-item" :class="active == 2 ? 'active' : ''"  @click="handleClick(2)">密码登录</div>
                        </div>

                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                            <template v-if="active == 2">
                                <el-form-item label="" prop="username">
                                    <el-input v-model="ruleForm.username" placeholder="请输入账号"></el-input>
                                </el-form-item>

                                <el-form-item label="" prop="password">
                                    <el-input type="password" v-model="ruleForm.password" autocomplete="off" placeholder="请输入密码"></el-input>
                                </el-form-item>
                            </template>

                            <template v-if="active == 1">
                                <el-form-item label="" prop="mobile">
                                    <el-input v-model.number="ruleForm.mobile" placeholder="请输入手机号"></el-input>
                                </el-form-item>

                                <el-form-item label="" prop="smsCode" class="coed-form">
                                    <el-input v-model="ruleForm.smsCode" placeholder="请输入验证码"></el-input>
                                    <el-button type="primary" @click="getCode('ruleForm')" :disabled="verifyBtnDisabled">{{ verifyBtnText }}</el-button>
                                </el-form-item>
                            </template>

                            <div class="handle">
                                <div></div>
                                <a href="javascript:void(0)" @click="dialogFormVisible = true">忘记密码？</a>
                            </div>

                            <el-form-item>
                                <el-button type="primary" @click="submitForm('ruleForm')" class="login-btn" :disabled="saving">登录</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="view-bottom">
                        <!-- <span>没有账号？</span><a href="javascript:void(0)" @click="pageChange(2)">立即注册</a> -->
                    </div>

                </div>
                <div class="sign-view" v-if="current == 2">
                     <div class="view-top">
                        <div class="tabs">
                            <div class="tab-item" :class="active == 1 ? 'active' : ''">手机号注册</div>
                        </div>

                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                            <el-form-item label="" prop="mobile">
                                <el-input v-model.number="ruleForm.mobile" placeholder="请输入手机号"></el-input>
                            </el-form-item>

                            <el-form-item label="" prop="smsCode" class="coed-form">
                                <el-input type="text" v-model="ruleForm.smsCode" placeholder="请输入验证码"></el-input>
                                <el-button type="primary" :disabled="verifyBtnDisabled">获取短信验证码</el-button>
                            </el-form-item>

                            <div class="handle">
                                <div>
                                    <el-checkbox v-model="checked">我已阅读并同意《xxx注册协议》</el-checkbox>
                                </div>
                            </div>


                            <el-form-item>
                                <el-button type="primary" @click="submitForm('ruleForm')" class="login-btn" :disabled="saving">注册</el-button>
                            </el-form-item>
                        </el-form>
                     </div>
                    <div class="view-bottom">
                        <span>已有账号？</span><a href="javascript:void(0)" @click="pageChange(1)">去登录</a>
                    </div>
                </div>
            </div>
            <div class="footer">沪公网安备 33010802007629号/Powered by © 2019-2022 libangong.com</div>
        </div>

        <el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="450px" :append-to-body="true" :destroy-on-close="true">
            <el-form :model="form" :rules="formRules" ref="form" label-width="80px" class="demo-ruleForm">

                <el-form-item label="手机号：" prop="mobile" required>
                    <el-input v-model.number="form.mobile" placeholder="请输入手机号"></el-input>
                </el-form-item>

                <el-form-item label="验证码：" prop="code" class="coed-form" required>
                    <el-input v-model.number="form.code" placeholder="请输入验证码"></el-input>
                    <el-button type="primary" :disabled="verifyBtnDisabled">获取验证码</el-button>
                </el-form-item>

                <el-form-item label="新密码" prop="password" required>
                    <el-input type="password" v-model="form.password" autocomplete="off" placeholder="请输入密码"></el-input>
                </el-form-item>

                <div style="margin-top: 40px">
                    <el-button type="primary" @click="confirm('form')" class="login-btn" :disabled="confirming">确认</el-button>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">

            </div>
        </el-dialog>
        <el-dialog :visible.sync="isVcodeShow" width="450px" :append-to-body="true" :destroy-on-close="true">
            <slide-verify :l="42"
                :r="10"
                :w="410"
                :h="200"
                :imgs="[imgUrl]"
                slider-text="向右滑动"
                @success="successVcode"
                @fail="onFail"
                @refresh="onRefresh"
            ></slide-verify>
        </el-dialog>
    </el-container>
</template>

<script>
import { strTomd5 } from '@/utils/md5'
import _ from 'lodash';
import imgUrl from "@/assets/images/jianjie.png"

export default {
    name: '',
    components: {
    },
    data() {
        return {
            imgUrl: imgUrl,
            current: '1',
            active: '1',
            rules: {
                username: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { type: 'number', message: '请输入正确格式的手机号'}
                ],
                smsCode: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { message: '请输入正确格式的验证码'}
                ],
            },
            formRules: {
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { type: 'number', message: '请输入正确格式的手机号'}
                ],
                smsCode: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { message: '请输入正确格式的验证码'}
                ],
                password: [
                    { required: true, message: '请输入新密码', trigger: 'blur' }
                ],
            },
            ruleForm: {
                username: '',
                password: '',
                mobile: '',
                smsCode: ''
            },
            form: {
                mobile: '',
                code: '',
                password: ''
            },
            checked: true,
            saving: false,
            verifyTimer: null,
            verifyBtnText: '获取短信验证码',
            verifyBtnDisabled: false,
            confirming: false,
            dialogFormVisible: false,
            isVcodeShow: false,
            redirect: undefined
        }
    },
    created() {
        // this.$api.get('/merchantApi/merchant/list')
        // .then(res => {

        // });
    },
    watch: {
        $route: {
        handler: function(route) {
            this.redirect = route.query && route.query.redirect;
        },
        immediate: true
        }
    },
    methods: {
        pageChange(value) {
            this.current = value;
            this.active = 1;
            this.$refs['ruleForm'].resetFields();
            clearInterval(this.verifyTimer);
            this.verifyBtnText = '获取验证码';
            this.verifyBtnDisabled = false;
        },
        handleClick(value) {
            this.active = value;
            this.$refs['ruleForm'].resetFields();
        },
        submitForm() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.isVcodeShow = true;
                    // this.$router.push({path: '/index/index'});
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        confirm() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.dialogFormVisible = false;
                    this.$refs['form'].resetFields();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        closeVcode() {
            this.isVcodeShow = false;
        },
        async successVcode() {
            let api = '';
            let form;
            if(this.active == 1) {
                // 验证码登录
                form = _.pick(this.ruleForm, ['mobile', 'smsCode'])
                form.mobile = String(form.mobile);
                form.smsCode = String(form.smsCode);
                api = '/merchantApi/sms/login';
            } else {
                // 密码登录
                form = _.pick(this.ruleForm, ['username', 'password'])
                form.password = strTomd5(form.password);
                api = '/merchantApi/login';
            }
            this.$api.post(api, form)
            .then(res => {
                if(res.code == '200') {
                    this.$store.commit('SET_TOKEN', res.data.token);
                    this.$store.dispatch('GetInfo').then(res => {
                        this.$message.success("登录成功");
                        this.closeVcode();
                        this.$router.push({ path: this.redirect || "/" }).catch(()=>{});
                    });
                } else {
                    this.closeVcode();
                }
            })
        },
        // 获取验证码
        getCode(formName) {
            this.$refs[formName].validateField('mobile', valid => {
                if(!valid){
                    // console.log(this.costumerForm);
                    this.tackCode(); //验证码倒数60秒
                    this.$api.get('/merchantApi/merchant/getVerificationCode', {
                        phone: this.ruleForm.mobile
                    }).finally(() => {
                    }).then(res => {
                        if(res.code == 200) {

                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }else{
                    console.log('未通过')
                    return;
                }
            })
        },
        tackCode(){       //验证码倒数60秒
            let time = 60;
            this.verifyTimer = setInterval(() => {
                if(time == 0){
                    clearInterval(this.verifyTimer);
                    this.verifyBtnText = '获取验证码';
                    this.verifyBtnDisabled = false;
                }else {
                    this.verifyBtnDisabled = true;
                    this.verifyBtnText = time + '秒后重试';
                    time--;
                }
            }, 1000);
        },
        onSuccess(){
            this.msg = 'login success'
        },
        onFail(){
            this.msg = ''
        },
        onRefresh(){
            this.msg = ''
        }
    }
}
</script>

<style lang="less" scoped>
@import './login.less';
</style>
