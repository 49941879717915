<template>
    <div class="flex-container content" id="content">
      <div class="content" v-if="flag">
        <div>
          <div class="account-balance">
            <h2 style="font-size: 1.5em;">商机宝余额</h2>
            <div class="top">
              <h1 style="font-size: 2em;">{{ walletData.balance ? walletData.balance : '-' }}</h1>
              <span style="font-size: 18px; margin-left: 10px;">元</span>
              <!-- <span  style="margin: 0px 15px;">上次充值 5,000元</span> -->
              <!-- <span  style="color: rgb(139, 158, 167);">2021-08-19  15:45:11</span> -->
            </div>
            <div class="center">
              <div class="item">
                <p  style="margin-bottom: 10px;">累计充值</p>
                <div>
                  <span style="font-size: 30px; font-weight: bold; margin-right: 5px; color: rgb(0, 0, 0);">
                    {{ walletData.cumulativeRecharge ? walletData.cumulativeRecharge : '-' }}
                  </span>
                  <span >元</span>
                </div>
              </div>
              <div class="item">
                <p  style="margin-bottom: 10px;">累计消费</p>
                <div>
                  <span style="font-size: 30px; font-weight: bold; margin-right: 5px; color: rgb(0, 0, 0);">
                    {{ walletData.cumulativeConsumption ? walletData.cumulativeConsumption : '-' }}
                  </span>
                  <span >元</span>
                </div>
              </div>
              <div class="item">
                <p  style="margin-bottom: 10px;">手工扣款</p>
                <div>
                  <span style="font-size: 30px; font-weight: bold; margin-right: 5px; color: rgb(0, 0, 0);">
                    {{ walletData.manualeduction ? walletData.manualeduction : '-' }}
                  </span>
                  <span >元</span>
                </div>
              </div>
              <div class="item">
                <p  style="margin-bottom: 10px;">商机退款金额（不包含退款中）</p>
                <div>
                  <span style="font-size: 30px; font-weight: bold; margin-right: 5px; color: rgb(0, 0, 0);">
                    {{ walletData.opportunityRefund ? walletData.opportunityRefund : '-' }}
                  </span>
                  <span >元</span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="online-recharge">
            <h2 style="font-size: 1.5em;">在线充值</h2>
            <p>充值类型：</p>
            <el-radio v-model="radio" label="1">商机宝充值</el-radio>
            <p>充值金额：</p>
            <el-input style="width:300px;" v-model="input" placeholder="商机宝最少充值额为5000"></el-input>
            <br>
            <el-button style="margin-top: 20px;" type="primary" @click="flag=false">去支付</el-button>
          </div> -->
        </div>
      </div>

      <!--支付-->
      <div class="account-balance" v-if="!flag">
        <div class="warp">
          <div>
            <div class="account-balance">
              <div  class="pay-header">
                <div  class="logo"></div>
                <div class="title">
                  <p style="font-size: 18px; font-weight: bold; margin-bottom: 5px;">上海梁信装饰设计工程有限公司</p>
                  <p>付款单位</p>
                </div>
              </div>
              <div class="footer">
                <p ><span  style="font-weight: bolder;">订单号：</span><span >FD20210910009898</span></p>
                <p ><span  style="font-weight: bolder;">商家ID：</span><span >8845122551</span></p>
                <p ><span  style="font-weight: bolder;">款项类型：</span><span >商机宝充值</span></p>
                <p ><span  style="font-weight: bolder;">充值金额：</span><span style="color: rgb(54, 93, 255); font-weight: bolder;">50000.00</span><span >元</span></p>
              </div>
            </div>
            <div class="online-recharge">
              <el-button v-for="(item,index) in payment" :key="index" style="margin-right: 55px;margin-bottom: 50px;">{{item}}</el-button>
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="企业网银" name="first"></el-tab-pane>
                <el-tab-pane label="个人网银" name="second"></el-tab-pane>
              </el-tabs>
              <div v-for="(item,index) in bank" :key="index" class="bank">{{item}}</div>
              <div class="clear"></div>
              <el-button type="primary" style=" margin-top: 100px;">确认付款</el-button>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
// 狸钱包
export default {
    name: 'order-wallet',
    data() {
        return {
          radio:'',
          input:'',
          payment:['网上支付','支付宝支付','微信支付'],
          bank:['中国工商银行','中国建设银行','交通银行','中国光大银行','招商银行'],
          flag: true,
          activeName: 'first',
          loading: true,
          walletData: {}
        }
    },
    created() {
      this.getList();
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      getList() {
        this.loading = true;
        this.$api.get('/merchantApi/power', {})
        .finally(() => {
          this.loading = false;
        })
        .then(res => {
          if(res.code == 200) {
            this.walletData = res.data;
          }
        });
      },
    }
}
</script>

<style lang="less" scoped>
  .clear{
    clear: both;
  }
  #content{
    padding: 0;
  }
  .warp{
    width: 100%;
    height: 100%;
  }
.warp>div>div{
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 15px;
  padding: 20px;
}
  .account-balance{
    display: flex;
    flex-direction: column;
    .top{
      display: flex;
      align-items: center;
      margin-top: 15px;
    }
    .center{
      display: flex;
      margin-top: 50px;
      color: #566267;
      .item{
        padding: 0 50px;
        border-right: 1px solid #d7d7d7;

        &:last-child {
          border: 0;
        }
      }
      .item:first-child{
        padding-left: 0;
      }
    }
    .bottom{
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
  .online-recharge{
    overflow: hidden;
    p{
      color: #80939a;
      margin: 20px 0 10px 0;
    }
  }
  .logo {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #639;
    float: left;
  }
 .title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
    float: left;
    margin-top: 10px;
  }
  .footer p{
    margin-right: 80px;
    float: left;
    margin-top: 20px;
  }
  .bank{
    float: left;
    width: 180px;
    padding: 15px 0;
    margin: 20px 20px 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
    border-radius: 5px;
  }
</style>
