<template>
    <div class="index-container">
        <el-row :gutter="20">
            <el-col :span="16">
                <el-card shadow="never" style="height: 180px">
                    <div slot="header" class="clearfix">
                        <span>资金管理</span>
                    </div>
                    <ul class="manage-ul" v-loading="loading">
                        <li>
                            <div class="icon icon-1"></div>
                            <div class="info">
                                <p class="title">商机宝余额</p>
                                <p class="number">{{ capital.balanceMoney }}元</p>
                                <p class="handle">
                                    <router-link :to="'/order/wallet'" class="orange">充值</router-link>
                                    <router-link :to="'/order/trade'" class="btn">消费明细 ></router-link>
                                </p>
                            </div>
                        </li>
                        <li>
                            <div class="icon icon-2"></div>
                            <div class="info">
                                <p class="title">承接商机数</p>
                                <p class="number">{{ capital.undertakenBoNumber }}单</p>
                                <p class="handle">
                                    <router-link :to="'/business/platform/list'" class="btn">查看明细 ></router-link>
                                </p>
                            </div>
                        </li>
                        <li>
                            <div class="icon icon-3"></div>
                            <div class="info">
                                <p class="title">签约金额</p>
                                <p class="number">{{ capital.signContractMoney }}元</p>
                                <p class="handle">
                                    <router-link :to="'/order/contract'" class="btn">签约记录 ></router-link>
                                </p>
                            </div>
                        </li>
                    </ul>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card shadow="never" style="height: 180px">
                    <div slot="header" class="clearfix">
                        <span>快速入口</span>
                    </div>
                    <ul class="entry-ul">
                        <li >
                            <router-link :to="'/store/case/add'">
                                <div class="icon icon-1"></div>
                                <p>发布案例</p>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="'/business/platform/list'">
                                <div class="icon icon-2"></div>
                                <p>查看商机</p>
                            </router-link>
                        </li>
                        <li>
                            <div class="icon icon-3"></div>
                            <p>点评管理</p>
                        </li>
                    </ul>
                </el-card>
            </el-col>
        </el-row>

        <div class="divider"></div>

        <el-card shadow="never">
            <div slot="header" class="clearfix">
                <span>快速抢单</span>
                <router-link :to="'/business/order/list'" class="more">查看全部 ></router-link>
            </div>
            <el-table
                v-if="!loading"
                :data="tableData"
                style="width: 100%"
                @row-click="rowClick"
            >
                <el-table-column
                    label="商机编号"
                    prop="businessCode"
                    width="80"
                    show-overflow-tooltip
                >
                </el-table-column>
                
                <!-- <el-table-column
                    prop="coustomerFirmName"
                    label="客户"
                    width="120"
                    show-overflow-tooltip
                >
                </el-table-column> -->
                
                <el-table-column
                    prop="projectName"
                    label="项目名称"
                    width="320"
                    show-overflow-tooltip
                >
                </el-table-column>

                <el-table-column
                    prop="projectType"
                    label="项目类型"
                >
                    <template slot-scope="scope">
                        <dict-tag :options="dict.type.lbg_project_type" :value="scope.row.projectType"/>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="projectNature"
                    label="项目性质"
                >
                    <template slot-scope="scope">
                        <dict-tag :options="dict.type.project_nature" :value="scope.row.projectNature"/>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="demandType"
                    label="需求类型"
                >
                    <template slot-scope="scope">
                        <dict-tag :options="dict.type.lbg_request_type" :value="scope.row.demandType"/>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="measureArea"
                    label="项目面积"
                >
                </el-table-column>
                <el-table-column
                    prop="haveReceiveNum"
                    label="应标进度"
                >
                    <template slot-scope="scope">
                        {{scope.row.haveReceiveNum ? scope.row.haveReceiveNum : 0 }}/{{scope.row.bidMax}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="bidDistributionTime"
                    label="发布时间"
                    width="120">
                </el-table-column>
                <!-- <el-table-column
                    prop="dockType"
                    label="对接方式"
                >
                    <template slot-scope="scope">
                        <dict-tag :options="dict.type.lbg_docking_type" :value="scope.row.dockType"/>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="projectBudget"
                    label="项目预算"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="surveyTime"
                    label="勘测时间"
                    width="150">
                </el-table-column>
                <el-table-column
                    prop="planTime"
                    label="预计装修时间"
                    width="150">
                </el-table-column> -->
                
                <el-table-column
                    prop="status"
                    label="操作"
                    width="200"
                    fixed="right"
                    align="center">
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.isrob == 0"
                            type="text"
                            @click.native.stop="handleOrder(scope.row.id)">一键抢单</el-button>
                        <el-button
                            v-else
                            disabled
                            type="text"
                        >已抢单</el-button>
                        <el-button
                            type="text"
                            class="text-gray"
                            @click="handleDetail(scope.row.id)"
                        >详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <div class="divider"></div>

        <el-card shadow="never">
            <div slot="header" class="clearfix">
                <span>商机情况</span>
            </div>
            <div class="data-content">
                <div class="form">
                    <!-- <el-select v-model="stage" placeholder="请选择" @change="stageChange">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select> -->

                    <el-date-picker
                        v-model="date"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        @change="changeDate">
                    </el-date-picker>
                </div>

                <div class="divider"></div>

                <el-row :gutter="10" class="row-list">
                    <el-col :span="4">
                        <div class="list">
                            <span class="label label-1"></span>
                            <div class="number"><i>{{ business.boNumberAll }}</i>个</div>
                            <p>商机总数</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="list">
                            <span class="label label-1"></span>
                            <div class="number"><i>{{ business.boNumberTrue }}</i>个</div>
                            <p>有效商机数</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="list">
                            <span class="label label-2"></span>
                            <div class="number"><i>{{ business.sjqyNumber }}</i>个</div>
                            <p>设计签约数</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="list">
                            <span class="label label-3"></span>
                            <div class="number"><i>{{ business.sgqyNumber }}</i>个</div>
                            <p>施工签约数</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="list">
                            <span class="label label-1"></span>
                            <div class="number"><i>{{ business.sdNumber }}</i>个</div>
                            <p>输单</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="list">
                            <span class="label label-1"></span>
                            <div class="number"><i>{{ business.tdNumber }}</i>个</div>
                            <p>无效商机</p>
                        </div>
                    </el-col>
                </el-row>

                <div class="divider"></div>

                <el-row :gutter="50">
                    <el-col :span="12">
                        <div class="progress-title">商机阶段</div>
                        <div class="progress-list">
                            <div class="progress" v-for="(step, index) in steps" :key="index">
                                <span class="label">{{ step.value }}</span>
                                <el-progress :percentage="step.percentage" :color="step.color"></el-progress>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="progress-title">输单原因统计</div>

                        <div id="main" style="width: 480px;height:300px;"></div>
                    </el-col>
                </el-row>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: '',
    dicts: [
      'lbg_docking_type',
      'lbg_project_type',
      'lbg_request_type',
      'project_nature',
    ],
    data() {
        return {
            loading: true,
            capital: {},
            tableData: [],
            business: {},
            formInline: {
                pageNum: 1,
                pageSize: 3,
            },
            page: 1,
            size: 20,
            total: 0,
            tableHeight: '',
            stage: 7,
            date: '',
            options: [
                {
                    value: 7,
                    label: '最近7天'
                },
                {
                    value: 30,
                    label: '最近一个月'
                },
                {
                    value: 90,
                    label: '最近三个月'
                },
            ],
            steps: [
                {
                    value: '需求调研',
                    color: '#FFE700',
                },
                {
                    value: '已勘测',
                    color: '#2D99FF'
                },
                {
                    value: '已看方案',
                    color: '#826AF9'
                },
                {
                    value: '已签设计',
                    color: '#46CD71'
                },
                {
                    value: '已签施工',
                    color: '#46CD71'
                },
                {
                    value: '输单',
                    color: '#FF4837'
                },
            ]
        }
    },
    mounted() {
    //   let searchFormHeight = this.$refs['header'].clientHeight + 20 // 可变的查询高度
      let pageHeight = document.documentElement.clientHeight // 可用窗口的高度
      this.tableHeight = Number(pageHeight - 240);
    },
    created() {
        // this.initDate(this.stage);
        this.getCapitalManage();
        this.getList();
        this.getBusiness();
    },
    methods: {
        // 资金管理
        getCapitalManage() {
            this.$api.get('/merchantApi/home/capitalManage', {})
            .finally(() => {
                // this.loading = false;
            })
            .then(res => {
                if(res.code == 200) {
                    this.capital = res.data;
                }
            });
        },
        // 快速抢单
        getList() {
            this.loading = true;
            this.$api.get('/merchantApi/businessOpportunity/hall/list', {
                ...this.formInline
            })
            .finally(() => {
                this.loading = false;
            })
            .then(res => {
            if(res.code == 200) {
                this.tableData = res.rows;
                // this.total = res.total;
            }
            });
        },
        // 商机情况
        getBusiness() {
            this.$api.get('/merchantApi/home/businessManage', {
                startTime: this.date ? this.date[0] : '',
                endTime: this.date ? this.date[1] : ''
            })
            .finally(() => {
                // this.loading = false;
            })
            .then(res => {
                if(res.code == 200) {
                    let data = res.data;
                    this.business = data;
                    this.steps = [
                        {
                            value: '需求调研',
                            color: '#FFE700',
                            percentage: data.xqdy || 0
                        },
                        {
                            value: '已勘测',
                            color: '#2D99FF',
                            percentage: data.ykc || 0
                        },
                        {
                            value: '已看方案',
                            color: '#826AF9',
                            percentage: data.ykfa || 0
                        },
                        {
                            value: '已签设计',
                            color: '#46CD71',
                            percentage: data.yqsj || 0
                        },
                        {
                            value: '已签施工',
                            color: '#46CD71',
                            percentage: data.yqsg || 0
                        },
                        {
                            value: '输单',
                            color: '#FF4837',
                            percentage: data.sd || 0
                        },
                    ];
                    this.myEcharts(data)
                }
            });
        },
        handleOrder(id) {
            this.$confirm('您确定需要抢此商机吗？操作后，将直接从您商机宝余额进行扣费！')
            .then( res => {
                this.$api.post('/merchantApi/businessOpportunity/grabbingOrder',
                    { 'opportunityId': id }
                )
                .finally(() => {
                })
                .then(res => {
                    if(res.code == 200) {
                        this.$message.success(res.msg);
                        this.getList();
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                done();
            })
            .catch( res => {});
        },
        rowClick(row) {
            this.$router.push({path: '/business/order/detail', query: { id: row.id }});
        },
        jump() {
            this.$router.push('/business/order');
        },
        handleSizeChange() {},
        handleCurrentChange() {},
        myEcharts(params) {
            let myChart = this.$echarts.init(document.getElementById('main'));
            let data = [
                { value: params.sjyy || 0, name: '设计原因' },
                { value: params.khyy || 0, name: '客户原因' },
                { value: params.jgyy || 0, name: '价格原因' },
                { value: params.qtyy || 0, name: '其他原因' },
            ];
            let option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    right: 10,
                    top: '30%',
                    bottom: 20,
                    itemGap: 20, 
                    textStyle: {            //图例文字属性设置
                        fontFamily: 'Microsoft YaHei',
                        fontSize: '16'
                    },
                    formatter: (name) => {
                        let value = 0;
                        data.forEach(item => {
                            if(item.name == name) {
                                value = item.value;
                            }
                        })
                        return name + '  ' + value + '%'
                    },
                    data: ['设计原因', '客户原因', '价格原因', '其他原因']
                },
                color: ['#2D99FF', '#FFE700', '#826AF9', '#E94C21'],
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['30%', '60%'],
                        center: ['30%', '50%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: '40',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: data.sort(function (a, b) {
                            return a.value - b.value;
                        }),
                        roseType: 'radius'
                    }
                ]
            };
            myChart.setOption(option);
        },
        stageChange() {
            // console.log(this.stage);
            this.initDate(this.stage);
        },
        initDate(value) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * value);
            this.date = [start, end];
        },
        changeDate() {
            console.log(this.date);
            this.getBusiness();
        }
    }
}
</script>

<style lang="less" scope>
.index-container {
    overflow-y: auto;
    overflow-x: hidden;;
    display: block;
    width: 100%;
    height: 100%;
    font-family: Microsoft YaHei;

    a {
        color: #333333;
    }
}
.divider {
    height: 20px;
    width: 100%;
}
.el-card {
    .el-card__header {
        border-bottom: 0;
        span {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
        }
        .more {
            font-size: 14px;
            color: #999999;
            float: right;
        }
    }
    .el-card__body {
        padding-top: 0;
    }
    .manage-ul {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;

        li {
            height: 68px;
            display: flex;

            .icon {
                height: 68px;
                width: 68px;
                &-1 {
                    background: url('~@/assets/images/index/icon-1.png') no-repeat;
                }
                &-2 {
                    background: url('~@/assets/images/index/icon-2.png') no-repeat;
                }
                &-3 {
                    background: url('~@/assets/images/index/icon-3.png') no-repeat;
                }
            }
            .info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-left: 10px;

                p {
                    line-height: 14px;
                }
            }
            .title {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
            }
            .number {
                margin: 10px 0;
                line-height: 0;
                font-size: 24px;
                font-weight: bold;
                color: #333333;
            }
            .handle {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .orange {
                    margin-right: 20px;
                    color: #FF8048;
                }
                .btn {
                    font-size: 14px;
                    color: #999999; 
                }
            }
        }
    }
    .entry-ul {
        display: flex;
        justify-content: space-between;

        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 86px;
            height: 100px;
            border-radius: 6px;
            cursor: pointer;

            &:hover {
                background: #FAF8F7;

                p {
                    color: #FCB532;
                }
            }
            p {
                margin-top: 10px;
            }
            .icon {
                margin: 0 auto;
                width: 50px;
                height: 50px;

                &-1 {
                    background: url('~@/assets/images/index/icon-4.png') no-repeat;
                }
                &-2 {
                    background: url('~@/assets/images/index/icon-5.png') no-repeat;
                }
                &-3 {
                    background: url('~@/assets/images/index/icon-6.png') no-repeat;
                }

            }
        }
    }
    .data-content {
        height: 500px;
    }
    .form {
        .el-select {
            margin-right: 40px;
        }
        .el-date-editor {
            // margin-left: 40px;
            .el-range-separator {
                width: 30px;
            }
        }
    }
    .row-list {
        .el-col-5 {
            width: 20% !important;
        }
        .list {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100px;
            background: #FAF8F7;
            border-radius: 12px;

            .number {
                font-weight: bold;
                color: #333333;

                i {
                    font-size: 24px;
                    font-style: normal;
                }
            }
            p {
                color: #999999;
            }
            .label {
                position: absolute;
                top: 0;
                left: 20px;
                width: 24px;
                height: 24px;

                &-1{
                    background: url(~@/assets/images/index/label-1.png) no-repeat;
                }
                &-2{
                    background: url(~@/assets/images/index/label-2.png) no-repeat;
                }
                &-3{
                    background: url(~@/assets/images/index/label-3.png) no-repeat;
                }
            }
        }
    }
    .progress-title {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
    }
    .progress-list {
        .progress {
            margin-top: 20px;
            display: flex;
            align-items: center;

            .label {
                margin-right: 20px;
                width: 70px;
                color: #999999;
                text-align: right;
            }
            .el-progress {
                flex: 1;
            }
        }
    }
}

</style>