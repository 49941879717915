<template>
  <div class="container" style="overflow: auto">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="资质与证书" name="1">
        <qualificationCertificate></qualificationCertificate>
      </el-tab-pane>
      <el-tab-pane label="管理体系认证认可" name="2">
        <management></management>
      </el-tab-pane>
      <el-tab-pane label="重要客户" name="3">
        <customer></customer>
      </el-tab-pane>
      <el-tab-pane label="报建项目业绩（四库）" name="4">
        <reportProject></reportProject>
      </el-tab-pane>
      <el-tab-pane label="非报建项目业绩" name="5">
        <notReportProject></notReportProject>
      </el-tab-pane>
      <el-tab-pane label="荣誉奖项" name="6">
        <honor></honor>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import qualificationCertificate from "./components/qualificationCertificate.vue";
import management from "./components/management.vue";
import customer from "./components/customer.vue";
import honor from "./components/honor.vue";
import reportProject from "./components/reportProject.vue";
import notReportProject from "./components/notReportProject.vue";
export default {
  components:{qualificationCertificate,management,customer,honor,reportProject,notReportProject},
  data(){
    return{
      activeName:'1',

    }
  },
  methods:{
    handleClick(){},
  },//methods
}
</script>

<style scoped>

</style>
