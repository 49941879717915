<template>
    <div class="detail-container">
      <el-card class="card-left" v-loading="loading">
        <div slot="header" class="clearfix">
          <span>商机详情</span>
          <el-button v-if="details.isrob == 0" type="primary" :disabled="saving" size="mini" @click="handleOrder()" class="btn ml10">抢单</el-button>
          <el-button v-else type="info" disabled size="mini"  class="btn ml10">已抢单</el-button>
          <el-button style="float: right; padding: 3px 0" type="text" @click="back">返回</el-button>
        </div>

        <el-descriptions title="" direction="vertical" :column="4" border size="">
          <el-descriptions-item label="项目名称" :span="2">{{ details.projectName }}</el-descriptions-item>
          <el-descriptions-item label="商机编码">{{ details.businessCode }}</el-descriptions-item>
          <el-descriptions-item label="分标时间">{{ details.bidDistributionTime }}</el-descriptions-item>
          <el-descriptions-item label="商机备注">
            {{ details.remark || '无'}}
          </el-descriptions-item>
          <el-descriptions-item label="招标需求附件">
            <a v-if="fileList[0]" href="javascript:void(0)" @click="download">附件</a>
              <!-- <el-upload
                class="upload-col"
                :action="uploadFileUrl"
                disabled
                :file-list="fileList">
              </el-upload> -->
          </el-descriptions-item>
        </el-descriptions>

        <el-row :gutter="20" style="padding: 20px 0;" class="card-row">
          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>客户信息</span>
              </div>
              <div class="col-list">
                <el-descriptions title="" :column="1">
                    <el-descriptions-item label="公司名称">{{ details.firmName }}</el-descriptions-item>
                    <el-descriptions-item label="所属行业">
                      <dict-tag :options="dict.type.firm_trade" :value="details.firmTrade"/>
                    </el-descriptions-item>
                    <el-descriptions-item label="企业类型">
                      <dict-tag :options="dict.type.firm_type" :value="details.firmType"/>
                    </el-descriptions-item>
                    <!-- <el-descriptions-item label="联系人">{{ details.firmUserName }}</el-descriptions-item>
                    <el-descriptions-item label="联系方式">{{ details.firmUserPhone }}</el-descriptions-item> -->
                    <el-descriptions-item label="是否上市">
                      <dict-tag :options="dict.type.is_list" :value="details.isList"/>
                    </el-descriptions-item>
                </el-descriptions>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>项目信息</span>
              </div>
              <div class="col-list">
                <el-descriptions title="" :column="1">
                    <el-descriptions-item label="项目地址">{{ details.addressAll }}</el-descriptions-item>
                    <el-descriptions-item label="楼宇园区">{{ details.park }}</el-descriptions-item>
                    <el-descriptions-item label="项目面积">{{ details.measureArea }}</el-descriptions-item>
                    <el-descriptions-item label="需求类型">
                      <dict-tag :options="dict.type.lbg_request_type" :value="details.demandType"/>
                    </el-descriptions-item>
                    <el-descriptions-item label="场地类型">
                      <dict-tag :options="dict.type.lbg_site_type" :value="details.siteProperties"/>
                    </el-descriptions-item>
                    <el-descriptions-item label="装修类型">
                      <dict-tag :options="dict.type.lbg_request_type" :value="details.demandType"/>
                    </el-descriptions-item>
                    <el-descriptions-item label="免租期">{{ details.rentFreePeriod }}</el-descriptions-item>
                </el-descriptions>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>项目计划</span>
              </div>
              <div class="col-list">
                <el-descriptions title="" :column="1">
                    <el-descriptions-item label="计划时间">{{ details.planTime }}</el-descriptions-item>
                    <el-descriptions-item label="项目预算">{{ details.projectBudget }}</el-descriptions-item>
                    <el-descriptions-item label="预计勘测">{{ details.surveyTime }}</el-descriptions-item>
                    <el-descriptions-item label="对接方式">
                      <dict-tag :options="dict.type.lbg_docking_type" :value="details.dockType"/>
                    </el-descriptions-item>
                </el-descriptions>
              </div>
            </el-card>
          </el-col>
        </el-row>

        <div style="text-align: right;" v-if="details.isrob == 0">
          <el-button type="primary" :disabled="saving" @click="handleOrder()">一键抢单</el-button>
        </div>
      </el-card>
    </div>
</template>

<script>
export default {
    name: 'order-detail',
    components: {},
    dicts: [
      'business_platform_status',
      'lbg_business_source',
      'lbg_project_type',
      'lbg_docking_type',
      'lbg_site_type',
      'firm_trade',
      'firm_type',
      'is_list',
      'lbg_request_type',
    ],
    data () {
      return {
        uploadFileUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
        details: {},
        orderId: '',
        loading: true,
        saving: false,
        fileList: []
      };
    },
    created() {
      this.orderId = this.$route.query.id;
      this.getDetails();
    },
    methods: {
      back() {
        this.$router.back();
      },
      getDetails() {
        this.loading = true;
        this.$api.get('/merchantApi/businessOpportunity/getBusinessOpportunity/'+this.orderId, {
        }).then(res => {
          if(res.code == 200) {
            this.details = res.data;
            if(this.details.isrob == 0) {
              // 未抢单状态下 隐藏信息
              this.details.park = this.details.park.replace(/[0-9]/g, "*");
              let index = this.details.firmName.indexOf('有限公司');
              this.details.firmName = '****' + this.details.firmName.substr(index);
            }
            if(this.details.fileId) {
              this.$api.post('/file/selectFileById/'+this.details.fileId, {})
              .then(res => {
                this.fileList = [{
                  fileId: res.data.fileId,
                  name: '附件',
                  url: this.$fileBaseUrl+res.data.url
                }];
              })
            }
            this.loading = false;
          }
        })
      },
      handleOrder() {
          this.$confirm('您确定需要抢此商机吗？操作后，将直接从您商机宝余额进行扣费！')
          .then( res => {
              this.$api.post('/merchantApi/businessOpportunity/grabbingOrder',
                  { 'opportunityId': this.details.id }
              )
              .finally(() => {
              })
              .then(res => {
                  if(res.code == 200) {
                      this.$message.success(res.msg);
                      this.getDetails();
                  } else {
                      this.$message.error(res.msg);
                  }
              })
              done();
          })
          .catch( res => {});
      },
      download() {
        window.open(this.fileList[0].url, '_target');
      }
    }
}

</script>
<style lang='less' scoped>
::v-deep .el-card {
  display: flex;
  flex-direction: column;

  .el-card__body {
    flex: 1;
    overflow-y: auto;

    .el-descriptions-item__cell {
      font-weight: bold;
    }
  }
  .el-card__header {
    .btn {
      span {
        color: #fff;
      }
    }
  }
  .upload-col {
    width: 100px;
    .el-upload {
      position: absolute;
    }
  }
}
::v-deep .card-row {
  .col-list {
    height: 300px;
    max-height: 300px;
    overflow-y: auto;
    .el-descriptions-item__container {
      align-items: center;
    }
  }
}
</style>