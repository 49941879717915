<template>
  <div>
    <div class="top-btn-box">
      <div style="font-weight: 500">非报建项目业绩</div>
      <div>
        <div class="btn" @click="handleBatchSubmit">提交审核</div>
        <div class="btn" style="background-color: #E94B22;color: #ffffff;margin-left: 8px" @click="openNotReportProjectModal">新增</div>
      </div>
    </div>
    <div>
      <el-table
        ref="multipleTable"
        :border="true"
        :header-cell-style="{background:'#F5F5F6',color:'#999999'}"
        :data="reportProjectList"
        @selection-change="handleSelectionChange"
        style="width: 100%">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="60">
        </el-table-column>
        <el-table-column
          prop="name"
          label="项目名称"
          width="180">
        </el-table-column>
        <el-table-column
          prop="addressAll"
          label="项目属地">
        </el-table-column>
        <el-table-column
          prop="bidWinningTime"
          label="中标时间">
        </el-table-column>
        <el-table-column
          prop="constructionUnit"
          label="建设单位">
        </el-table-column>
        <el-table-column
          prop="projectMoney"
          label="项目总投资(万元)">
        </el-table-column>
        <el-table-column
          prop="approvalStatus"
          label="状态">
          <template slot-scope="scope">
            <span :style="{'color':productionStatus[scope.row.approvalStatus || 9999].color}">{{productionStatus[scope.row.approvalStatus || 9999].name}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" style="color: #07A2FF" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" style="color: #07A2FF" @click="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="queryList"
      />
    </div>
    <notReportProjectModal ref="notReportProjectModal" @ok="queryList"></notReportProjectModal>
  </div>
</template>

<script>
import notReportProjectModal from "./modal/notReportProjectModal.vue";
export default {
  components:{notReportProjectModal},
  data(){
    return{
      //table数据源
      reportProjectList:[],
      productionStatus:{
        0:{name:'未审核',color:'#FF6D72'},
        1:{name:'审核中',color:'#666666'},
        2:{name:'已审核',color:'#FAAD15'},
        3:{name:'已驳回',color:'#333333'},
        9999:{name:'未审核',color:'#333333'}
      },
      //选中的行
      multipleSelection: [],
      queryParams:{
        pageNum:1,
        pageSize:10,
      },
      total:0,
    }
  },//data
  created() {
    this.queryList()
  },//created
  methods:{
    /**
     * 点击复选框回调
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 批量提交
     */
    handleBatchSubmit(){
      if(this.multipleSelection.length == 0){
        this.$message.warning('请至少选择一行')
      }else{
        let ids = this.multipleSelection.map(e=> e.id)
        this.$api.post(`/merchantApi/enterpriseAtteFbjxm/submitApproval/${ids}`).then(res=>{
          if(res.code == 200){
            this.$message.success(res.msg)
            this.queryList()
          }else{
            this.$message.error(res.msg)
          }
        })
      }
    },
    handleEdit(row){
      this.$refs.notReportProjectModal.edit(row.id)
    },
    handleDel(row){
      this.$confirm('是否确认删除？').then(() => {
        this.$api.delete(`/merchantApi/enterpriseAtteBjxm/${row.id}`).then(res=>{
          if(res.code == 200){
            this.$message.success(res.msg)
            this.queryList()
          }else{
            this.$message.error(res.msg)
          }
        })
      })
    },
    /**
     * 打开新增
     */
    openNotReportProjectModal(){
      this.$refs.notReportProjectModal.visible = true
    },
    queryList(){
      this.$api.get('/merchantApi/enterpriseAtteFbjxm/list',{
        ...this.queryParams
      }).then(res=>{
        this.reportProjectList = res.rows
        this.total = res.total
      })
    },
  },//methods
}
</script>

<style scoped lang="less">
* {
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 14px;
}
.top-btn-box{
  width: 100%;
  margin-top: 17px;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &>div:nth-child(2){
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
.btn {
  width: 80px;
  height: 35px;
  background: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #E94B22;
  line-height: 35px;
  text-align: center;
  color: #E94B22;
  font-weight: 500;
  font-size: 14px;
  &:hover{
    cursor: pointer;
  }
}
</style>
