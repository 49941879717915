<template>
  <div>
    <el-row>
      <el-col :span="12">
        <div style="font-weight: 500;padding-bottom: 16px">重要客户（{{tableData.length}}）</div>
        <el-table
          :border="true"
          :header-cell-style="{background:'#F5F5F6',color:'#999999'}"
          :data="tableData"
          style="width: 100%">
          <el-table-column
            prop="id"
            label="序号"
            width="180">
          </el-table-column>
          <el-table-column
            prop="name"
            label="业主单位"
            width="180">
            <template slot-scope="scope">
              <a href="#" style="color: #07A2FF" @click="handleGetCount(scope.row.enterpriseId)">{{scope.row.name}}</a>
            </template>
          </el-table-column>
          <el-table-column
            prop="projectNum"
            label="合作项目数量（个）">
          </el-table-column>
          <el-table-column
            prop="projectMoney"
            label="合作项目（万元）">
          </el-table-column>
        </el-table>
        <pagination
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getCustomerList"
        />
      </el-col>
      <el-col :span="12" style="padding-left: 48px">
        <div style="font-weight: 500;padding-bottom: 16px">项目业绩统计
          <span style="color: #999999;font-size: 12px">（审核通过的 报建项目及非报建项目数据）</span>
        </div>
        <div class="count-box">
          <div v-for="(item,i) in countInfo.detailList" :key="i">{{item.region}} {{item.proportion}}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import * as echarts from 'echarts';

export default {
  data(){
    return{
      tableData:[],
      defaultColors:['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
      queryParams:{
        pageNum:1,
        pageSize:10,
      },
      total:0,
      countInfo:{},
    }
  },//data
  created() {},//created
  mounted() {
    // this.initEcharts()
    this.getCustomerList()
  },//created
  methods:{
    getCustomerList(){
      this.$api.get('/merchantApi/enterpriseAtteZykh/list',{
        ...this.queryParams
      }).then(res=>{
        this.tableData = res.rows
        this.total = res.total
        this.handleGetCount(res.rows[0].enterpriseId)
      })
    },
    /**
     * 点击左侧table 查询count数据
     */
    handleGetCount(enterpriseId){
      this.$api.get(`/merchantApi/enterpriseAtteZykh/projectPerfor/${enterpriseId}`).then(res=>{
        this.countInfo = res.data
      })
    },
    initEcharts(){
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(this.$refs.echarts);
      // 绘制图表
      //['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
      myChart.setOption({
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            data: [
              { value: 1, name: '1' },
              { value: 2, name: '2' },
              { value: 3, name: '3' },
              { value: 4, name: '4' },
              { value: 5, name: '5' },
              { value: 6, name: '6' },
              { value: 7, name: '7' }
            ]
          }
        ]
      })
    },
  },//methods
}
</script>

<style scoped lang="less">
* {
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 14px;
}
.echarts-box{
  display: flex;
  align-items: flex-start;
}
.e-text-title{
  display: flex;
  align-items: center;
  color: #999999;
  margin-bottom: 13px;
  &>div{
    width: 100px;
  }
}
.echarts-i{
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #91cc75;
  border-radius: 2px;
  position: absolute;
  left: -17px;
}
.echarts-item{
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  position: relative;
  &>div{
    width: 100px;
  }
}
.echarts{
  width: 200px;
  height: 200px;
}
.count-box{
  width: 100%;
  background-color: #F6F6F6;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-wrap: wrap;
  &>div{
    width: 100px;
    height: 30px;
    background: rgba(#FF6E48,0.05);
    border-radius: 18px;
    font-weight: 500;
    color: #FF6E48;
    line-height: 30px;
    text-align: center;
    margin-right: 16px;
    margin-bottom: 13px;
  }
}
</style>
