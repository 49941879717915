<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'App',
	components: {
	},
	data() {
		return {
			
		};
    },
	created() {
	},
    methods: {
		
    }
}
</script>

<style lang="less">
@import './assets/css/reset.less';
@import './assets/css/common.less';
#app {
	height: 100%;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #333;
	background: #f5f5f5;
}
.container {
	background: #fff;
    overflow: hidden;
    display: block;
    width: 100%;
    height: 100%;
	padding: 20px;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
	border-radius: 4px;
}
.flex-container {
	overflow: hidden;
    display: flex;
	flex-direction: column;
    width: 100%;
    height: 100%;
	
	.header {
		margin-bottom: 20px;
		padding: 20px 10px 10px 20px;
		background: #fff;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
		border-radius: 4px;
	}
	.content {
		 overflow-y: auto;
		flex: 1;
		padding: 10px;
		background: #fff;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
		border-radius: 4px;
	}
	.table-handle {
		padding-bottom: 10px;
		text-align: right;
	}
}
.detail-container {
	overflow: hidden;
    display: flex;
	justify-content: space-between;
	width: 100%;
    height: 100%;

	.card-left {
		flex: 1;
		background: #fff;
	}
	.card-right {
		margin-left: 20px;
		width: 300px;
		background: #fff;
	}
}
.page-title {
	height: 30px;
	line-height: 30px;

	.title {
		font-size: 20px;
		font-weight: 600;
	}
	.total {
		font-size: 14px;
		.num {
			color: #365dff;
			font-style: normal;
		}
	}
}
.el-pagination {
	margin-top: 20px;
}
.text-gray {
	color: #606266 !important;
}
</style>
