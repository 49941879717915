<template>
  <div class="flex-container">
    <div class="header" ref="header">
      <el-form :inline="true" :model="formInline" class="form-inline" ref="formInline">
        <el-row>
          <el-col :span="4">
             <el-form-item label-width="0" prop="personName">
              <el-input v-model="formInline.personName" placeholder="请输入关键词"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0" prop="professionalTitleId">
              <el-select v-model="formInline.professionalTitleId" placeholder="职称" clearable>
                <el-option
                  v-for="(item, index) in dict.type.lbg_professional_title"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0" prop="status">
              <el-select v-model="formInline.status" placeholder="状态" clearable>
                <el-option
                  v-for="(item, index) in dict.type.lbg_leave_office_status"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0">
              <el-button type="primary" @click="onSubmit">查询</el-button>
              <el-button @click="reset('formInline')">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="content">
      <div class="table-handle">
        <el-button size="small" type="primary" @click="add">添加人员</el-button>
        <el-button size="small" @click="remove()">删除</el-button>
      </div>
      <el-table
        v-if="tableHeight"
        :data="tableData"
        style="width: 100%"
        :max-height="tableHeight"
        v-loading="loading"
        ref="multipleTable"
      >
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
              prop="url"
              label="头像"
              width="150"
          >
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.profilePhotoFileUrl"
                style="width: 50px; height: 50px"
                :src="$fileBaseUrl + scope.row.profilePhotoFileUrl"
                fit="contain">
              </el-image>
            </template>
          </el-table-column>

          <el-table-column
              prop="personName"
              label="姓名"
              width="100">
          </el-table-column>
          <el-table-column
              prop="userName"
              label="用户名"
              width="100">
          </el-table-column>
          <el-table-column
              prop="idNumber"
              label="手机号"
              width="120">
          </el-table-column>
          <el-table-column
              prop="recommend"
              label="职务"
              width="100"
            >
              <template slot-scope="scope">
                <dict-tag :options="dict.type.professionalTitleId" :value="scope.row.professionalTitleId"/>
              </template>
          </el-table-column>
          <el-table-column
              prop="workExper"
              label="从业年限"
              width="100">
          </el-table-column>
          <el-table-column
              prop="roleType"
              label="角色"
              width="100"
          >
            <template slot-scope="scope">
              <dict-tag :options="dict.type.lbg_store_person_role" :value="scope.row.roleType"/>
            </template>
          </el-table-column>
          <el-table-column
              prop="recommend"
              label="创建时间"
              width="180">
          </el-table-column>
          <el-table-column
              prop="status"
              label="在职状态"
              width="100"
          >
            <template slot-scope="scope">
              <dict-tag :options="dict.type.lbg_leave_office_status" :value="scope.row.status"/>
            </template>
          </el-table-column>
          <el-table-column
              prop="profilePhotoCheckStatus"
              label="头像审核状态"
              width="150"
           >
            <template slot-scope="scope">
              <dict-tag :options="dict.type.lbg_profile_photo_check_status" :value="scope.row.profilePhotoCheckStatus"/>
            </template>
          </el-table-column>

          <el-table-column
              prop="status"
              label="操作"
              width="120"
              fixed="right"
              align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
                  <el-button type="text" @click="remove(scope.row.id)" class="text-gray">删除</el-button>
                </template>
          </el-table-column>
      </el-table>

      <pagination
          v-show="total>0"
          :total="total"
          :page.sync="formInline.pageNum"
          :limit.sync="formInline.pageSize"
          @pagination="getList"
      />
    </div>
  </div>
</template>

<script>
import { industryList, areaList, statusList } from '@/utils/variable';
export default {
    name: 'company-account',
    components: {},
    dicts: [
      'lbg_professional_title',
      'approval_status',
      'lbg_leave_office_status',
      'position',
      'lbg_store_person_role',
      'lbg_specialty',
      'lbg_profile_photo_check_status',
    ],
    data () {
      return {
        total: 0,
        loading: true,
        formInline: {
          pageNum: 1,
          pageSize: 10,
          // professionalTitleId: '',
          // status: '',
          // personName: '',
        },
        tableData: [],
        tableHeight: ''
      };
    },
    created() {
      this.getList();
    },
    mounted() {
      let searchFormHeight = this.$refs['header'].clientHeight + 20 // 可变的查询高度
      let pageHeight = document.documentElement.clientHeight // 可用窗口的高度
      this.tableHeight = Number(pageHeight - (searchFormHeight + 100 + 130));
    },
    methods: {
      getList() {
        this.loading = true;
        this.$api.get('/merchantApi/storePerson/list', {
          ...this.formInline
        })
        .finally(() => {
          this.loading = false;
        })
        .then(res => {
          if(res.code == 200) {
            this.tableData = res.rows;
            this.total = res.total;
          }
        });
      },
      add() {
        this.$router.push({
          path: '/company/account/add',
        });
      },
      edit(id) {
        this.$router.push({
          path: '/company/account/add',
          query: {id: id}
        });
      },
      remove(id) {
        let params = [];
        if(id) {
          // 单个删除
          params.push(id);
        } else {
          // 多个删除
          let ref = this.$refs.multipleTable;
          if(ref.selection && ref.selection.length) {
            ref.selection.map(item => {
              params.push(item.id);
            })
          } else {
            this.$message({
              message: '没有选中数据',
              type: 'warning'
            });
            return false;
          }
        }
        this.$confirm('是否确认删除？')
        .then( res => {
          this.$api.delete('/merchantApi/storePerson/'+params.join()).then(res => {
            this.getList();
          })
          done();
        })
        .catch( res => {});
      },
      onSubmit() {
        this.formInline.pageNum = 1;
        this.getList();
      },
      reset(formName) {
        this.$refs[formName].resetFields();
        this.onSubmit();
      },
      handleSizeChange() {},
      handleCurrentChange() {}
    }
}

</script>
<style lang='less' scoped>
::v-deep .form-inline {
  .el-form-item {
    display: block !important;
    margin-bottom: 10px !important;
    .el-form-item__content {
      display: block !important;
    }
    .el-select {
      width: 100%;
    }
  }
}
</style>