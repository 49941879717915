<template>
  <div>
    <div class="top-btn-box">
      <el-dropdown @command="handleCommand">
        <el-button type="primary" size="small">
          新增<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="design">设计资质</el-dropdown-item>
          <el-dropdown-item command="construction">施工资质</el-dropdown-item>
          <el-dropdown-item command="productionModal">安全生产许可证</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div>
      <div style="font-weight: 500;padding-bottom: 16px">设计资质</div>
      <el-table
        :border="true"
        :header-cell-style="{background:'#F5F5F6',color:'#999999'}"
        :data="designList"
        style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="60">
        </el-table-column>
        <el-table-column
          prop="num"
          label="证书编号"
          width="180">
        </el-table-column>
        <el-table-column
          prop="name"
          label="资质名称">
          <template slot-scope="scope">
            <div>
              <div v-for="(item,i) in scope.row.name" :key="i" class="table-text-box">
                {{item}}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="startTime"
          label="发证日期">
        </el-table-column>
        <el-table-column
          prop="endTime"
          label="有效期">
        </el-table-column>
        <el-table-column
          prop="issuingAuthority"
          label="发证机关">
        </el-table-column>
        <el-table-column
          prop="approvalStatus"
          label="状态">
          <template slot-scope="scope">
            <span :style="{'color':productionStatus[scope.row.approvalStatus || 9999].color}">{{productionStatus[scope.row.approvalStatus || 9999].name}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" style="color: #07A2FF" @click="handleEditDesign(scope.row)">修改</el-button>
            <el-button type="text" style="color: #07A2FF" :disabled="saving" @click="handleSubmitAudit(scope.row)">提交审核</el-button>
            <el-button type="text" style="color: #07A2FF" @click="handleDelDesign(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="designTotal"
        :page.sync="designQueryParams.pageNum"
        :limit.sync="designQueryParams.pageSize"
        @pagination="getDesignList"
      />
      <div style="font-weight: 500;padding-bottom: 16px;padding-top: 30px">施工资质</div>
      <el-table
        :border="true"
        :header-cell-style="{background:'#F5F5F6',color:'#999999'}"
        :data="constructionList"
        style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="60">
        </el-table-column>
        <el-table-column
          prop="num"
          label="证书编号"
          width="180">
        </el-table-column>
        <el-table-column
          prop="name"
          label="资质名称">
          <template slot-scope="scope">
            <div>
              <div v-for="(item,i) in scope.row.name" :key="i" class="table-text-box">
                {{item}}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="startTime"
          label="发证日期">
        </el-table-column>
        <el-table-column
          prop="endTime"
          label="有效期">
        </el-table-column>
        <el-table-column
          prop="issuingAuthority"
          label="发证机关">
        </el-table-column>
        <el-table-column
          prop="approvalStatus"
          label="状态">
          <template slot-scope="scope">
            <span :style="{'color':productionStatus[scope.row.approvalStatus || 9999].color}">{{productionStatus[scope.row.approvalStatus || 9999].name}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" style="color: #07A2FF" @click="handleEditConstruction(scope.row)">修改</el-button>
            <el-button type="text" style="color: #07A2FF" @click="handleSubmitAudit1(scope.row.id)">提交审核</el-button>
            <el-button type="text" style="color: #07A2FF" @click="handleDelConstruction(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="constructionTotal"
        :page.sync="constructionQueryParams.pageNum"
        :limit.sync="constructionQueryParams.pageSize"
        @pagination="getConstructionList"
      />

      <div style="font-weight: 500;padding-bottom: 16px;padding-top: 30px">安全生产许可证</div>
      <div>
        <div class="card-line-box">
          <div>安全生产许可证编号</div>
          <div>{{productionInfo?productionInfo.num:""}}</div>
          <div>主要负责人</div>
          <div>{{productionInfo?productionInfo.personCharge:""}}</div>
          <div>状态</div>
          <span v-if="productionInfo" style="padding-left: 10px" :style="{'color':productionStatus[productionInfo.approvalStatus || 9999].color}">{{productionStatus[productionInfo.approvalStatus|| 9999].name}}</span>
          <span v-else></span>
        </div>
        <div class="card-line-box">
          <div>发证日期</div>
          <div>{{productionInfo?productionInfo.startTime:""}}</div>
          <div>有效期</div>
          <div>{{productionInfo?productionInfo.endTime:""}}</div>
          <div>操作</div>
          <div>
            <a v-if="productionInfo" href="#" style="color: #07A2FF;" @click="handleSubmitAudit(productionInfo)">提交审核</a>
            <a v-if="productionInfo" href="#" style="color: #07A2FF;padding-left: 10px" @click="handleRevocation(productionInfo.id)">撤回</a>
            <a v-if="productionInfo" href="#" style="color: #07A2FF;padding-left: 10px" @click="handleDelProduction(productionInfo.id)">删除</a>
          </div>
        </div>
        <div class="card-line-box">
          <div>许可内容</div>
          <div>{{productionInfo?productionInfo.licenseContent:""}}</div>
        </div>
      </div>
    </div>
    <designModal ref="designModal" @ok="getDesignList"></designModal>
    <productionModal ref="productionModal"></productionModal>
    <constructionModal ref="constructionModal" @ok="getConstructionList"></constructionModal>
  </div>
</template>

<script>
import designModal from "./modal/designModal.vue";
import productionModal from "./modal/productionModal.vue";
import constructionModal from './modal/constructionModal.vue'
export default {
  components:{designModal,productionModal,constructionModal},
  data(){
    return{
      tableData:[{id:1}],
      //生产许可证
      productionInfo:{},
      productionStatus:{
        0:{name:'未审核',color:'#FF6D72'},
        1:{name:'审核中',color:'#666666'},
        2:{name:'已审核',color:'#FAAD15'},
        3:{name:'已拒绝',color:'#333333'},
        9999:{name:'未审核',color:'#333333'}
      },
      //设计资质
      designList:[],
      //施工资质
      constructionList:[],
      designQueryParams:{
        pageNum:1,
        pageSize:10,
      },
      designTotal:0,
      constructionQueryParams:{
        pageNum:1,
        pageSize:10,
      },
      constructionTotal:0,
      saving: false
    }
  },//data
  created() {
    this.getProductionList()
    this.getDesignList()
    this.getConstructionList()
  },//created
  methods:{
    /**
     * 提交审核
     */
    handleSubmitAudit(row){
      if(this.saving) {
        return false;
      }
      this.saving = true;
      this.$api.post(`/merchantApi/enterpriseAtteZz/submitApproval/${row.id}`).then(res=>{
        if(res.code == 200){
          this.$message.success(res.msg)
          this.getProductionList()
        }else{
          this.$message.error(res.msg)
        }
        this.saving = false;
      })
    },
    /**
     * 安全生产许可证提审
     */
    handleSubmitAudit1(id){
      if(this.saving) {
        return false;
      }
      this.saving = true;
      this.$api.post(`/merchantApi/enterpriseAtteAqxk/submitApproval/${id}`).then(res=>{
        if(res.code == 200){
          this.$message.success(res.msg)
          this.getDesignList()
          this.getProductionList()
          this.getConstructionList()
        }else{
          this.$message.error(res.msg)
        }
        this.saving = false;
      })
    },
    /**
     * 打开修改施工资质
     */
    handleEditConstruction(row){
      this.$refs.constructionModal.edit(row.id)
    },
    /**
     * 删除施工资质
     */
    handleDelConstruction(row){
      this.$confirm('是否确认删除？').then(() => {
        this.$api.delete(`/merchantApi/enterpriseAtteZz/${row.id}`).then(res=>{
          if(res.code == 200){
            this.$message.success(res.msg)
            this.getConstructionList()
          }else{
            this.$message.error(res.msg)
          }
        })
      })
    },
    /**
     * 撤回
     */
    handleRevocation(id){
      this.$api.post(`/merchantApi/enterpriseAtteAqxk/withdraw/${id}`).then(res=>{
        if(res.code == 200){
          this.$message.success(res.msg)
          this.getProductionList()
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * 删除设计资质
     */
    handleDelDesign(row){
      this.$confirm('是否确认删除？').then(() => {
        this.$api.delete(`/merchantApi/enterpriseAtteZz/${row.id}`).then(res=>{
          if(res.code == 200){
            this.$message.success(res.msg)
            this.getDesignList()
          }else{
            this.$message.error(res.msg)
          }
        })
      })
    },
    /**
     * 点击修改回调
     */
    handleEditDesign(row){
      this.$refs.designModal.edit(row.id)
    },
    /**
     * 打开设计资质
     */
    handleCommand(command){
      if(command == 'design'){
        this.$refs.designModal.visible = true
      }else if(command == 'productionModal'){
        this.$refs.productionModal.visible = true
      }else if(command == 'construction'){
        this.$refs.constructionModal.visible = true
      }
    },
    /**
     * 查询商家-深度认证-安全生产许可证列表接口
     */
    getProductionList(){
      this.$api.get('/merchantApi/enterpriseAtteAqxk/list').then(res=>{
        this.productionInfo = res.rows[0];
      })
    },
    /**
     * 删除商家-深度认证-安全生产许可证接口
     */
    handleDelProduction(id){
      this.$confirm('是否确认删除？').then(() => {
        this.$api.delete(`/merchantApi/enterpriseAtteAqxk/${id}`).then(res=>{
          if(res.code == 200){
            this.$message.success(res.msg)
            this.getProductionList()
          }else{
            this.$message.error(res.msg)
          }
        })
      })
    },
    /**
     * 查询商家-深度认证-设计资质
     */
    getDesignList(){
      this.$api.get('/merchantApi/enterpriseAtteZz/list',{
        type:'sjzz',
        ...this.designQueryParams
      }).then(res=>{
        this.designList = res.rows
        this.designTotal = res.total
        this.designList.forEach(e=>{
          e.name = e.name?.split(',')
        })
      })
    },
    /**
     * 查询商家-深度认证-施工资质
     */
    getConstructionList(){
      this.$api.get('/merchantApi/enterpriseAtteZz/list',{
        type:'sgzz',
        ...this.constructionQueryParams
      }).then(res=>{
        this.constructionList = res.rows
        this.constructionTotal = res.total
        this.constructionList.forEach(e=>{
          e.name = e.name?.split(',')
        })
      })
    },
  },//methods
}
</script>

<style scoped lang="less">
* {
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 14px;
}
.top-btn-box{
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 17px;
  margin-bottom: 22px;

}
.btn {
  width: 80px;
  height: 35px;
  background: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #E94B22;
  line-height: 35px;
  text-align: center;
  color: #E94B22;
  font-weight: 500;
  font-size: 14px;
}
.card-line-box{
  display: flex;
  align-items: center;
  border: 1px solid #EFEFEF;
  &>div{
    height: 44px;
    line-height: 44px;
  }
  &>div:nth-child(odd){
    background-color: #f5f5f6;
    color: #999999;
    width: 140px;
    padding: 0 8px;
  }
  &>div:nth-child(even){
    color: #666666;
    width: 300px;
    padding: 0 8px;
  }

}
/deep/.cell{
  padding: 0px;
  text-align: center;
}
/deep/.el-table__cell{
  padding: 0px;
  height: 65px;
}
.table-text-box{
  border-bottom: 1px solid #EBEEF5;
  height: 65px;
  line-height: 65px;
}
</style>
