<template>
    <div class="detail-container">
      <el-card class="card-left" v-loading="loading">
        <div slot="header" class="clearfix">
          <span>商机详情</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="back">返回</el-button>
        </div>

        <el-descriptions title="" direction="vertical" :column="4" border size="">
          <el-descriptions-item label="项目名称">{{ details.projectName }}</el-descriptions-item>
          <el-descriptions-item label="商机编码">{{ details.businessCode }}</el-descriptions-item>
          <el-descriptions-item label="分标时间">{{ details.bidDistributionTime }}</el-descriptions-item>
          <el-descriptions-item label="需求类型">
            <dict-tag :options="dict.type.lbg_request_type" :value="details.demandType"/>
          </el-descriptions-item>
          <!-- <el-descriptions-item label="商机最新进展">
            <dict-tag :options="dict.type.business_platform_status" :value="details.businessStatus"/>
          </el-descriptions-item> -->
          <el-descriptions-item label="对接方式">
            <dict-tag :options="dict.type.lbg_docking_type" :value="details.dockType"/>
          </el-descriptions-item>
          <el-descriptions-item label="竞标状态">
            <dict-tag :options="dict.type.bidding_status" :value="details.biddingStatusSh"/>
          </el-descriptions-item>
          <!-- <el-descriptions-item label="设计状态">
            <dict-tag :options="dict.type.bidding_status" :value="details.sjStatus"/>
          </el-descriptions-item>
          <el-descriptions-item label="施工状态">
            <dict-tag :options="dict.type.bidding_status" :value="details.sgStatus"/>
          </el-descriptions-item> -->
          <el-descriptions-item label="商机备注">
            {{ details.remark || '无'}}
          </el-descriptions-item>
        </el-descriptions>

        <el-divider content-position="left">商机进展</el-divider>
        <div class="step-view" v-if="!loading">
          <div class="el-steps el-steps--horizontal">
            <div
              v-for="(step, index) in businessStep"
              :key="index"
              class="el-step is-horizontal is-center"
              style="flex-basis: 50%;margin-right: 0px;"
            >
              <template>
                <div class="el-step__head is-process">
                  <div class="el-step__line" style="margin-right: 0px;">
                    <i class="el-step__line-inner"></i>
                  </div>
                  <div
                    v-if="details[step.key] == 1"
                    class="el-step__icon is-text step-active"
                  >
                    <i class="el-step__icon-inner is-status el-icon-check"></i>
                  </div>
                  <div v-else class="el-step__icon is-text"></div>
                </div>
                <div class="el-step__main">
                  <div class="el-step__title is-process">
                    {{ step.label }}
                  </div>
                  <template v-if="details.bidStatus == 2 || details.bidStatus == 5">
                    <el-button
                        v-if="details[step.key] == 0 && details.sdStatusSh != 1 && details.zbjgStatusSh != 1"
                        @click="handleConfirm(step)"
                        :disable="saving"
                        type="primary"
                        plain
                        size="mini">确认
                      </el-button>
                  </template>
                  <!-- 显示输单原因 -->
                  <template v-if="step.key == 'sdStatusSh' && details.lostReason">
                    <dict-tag :options="dict.type.lost_reason" :value="details.lostReason"/>
                  </template>
                  <!-- 显示退单原因 -->
                  <template v-if="step.key == 'tdStatusSh' && details.invalidReason">
                    <dict-tag :options="dict.type.invalid_reason" :value="details.invalidReason"/>
                  </template>
                  <!-- 显示中标结果 -->
                  <template v-if="step.key == 'zbjgStatusSh' && details.zbjgEnterpriseName">
                    {{ details.zbjgEnterpriseName }}
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>

        <el-row :gutter="20" style="padding: 20px 0;" class="card-row">
          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>客户信息</span>
              </div>
              <div class="col-list">
                <el-descriptions title="" :column="1">
                    <el-descriptions-item label="公司名称">{{ details.firmName }}</el-descriptions-item>
                    <el-descriptions-item label="所属行业">
                      <dict-tag :options="dict.type.firm_trade" :value="details.firmTrade"/>
                    </el-descriptions-item>
                    <el-descriptions-item label="企业类型">
                      <dict-tag :options="dict.type.firm_type" :value="details.firmType"/>
                    </el-descriptions-item>
                    <el-descriptions-item label="联系人">{{ details.contactName }}</el-descriptions-item>
                    <el-descriptions-item label="联系方式">{{ details.firmUserPhone }}</el-descriptions-item>
                    <el-descriptions-item label="是否上市">
                      <dict-tag :options="dict.type.is_list" :value="details.isList"/>
                    </el-descriptions-item>
                </el-descriptions>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>项目信息</span>
              </div>
              <div class="col-list">
                <el-descriptions title="" :column="1">
                    <el-descriptions-item label="项目地址">{{ details.addressAll }}</el-descriptions-item>
                    <el-descriptions-item label="楼宇园区">{{ details.park }}</el-descriptions-item>
                    <el-descriptions-item label="项目面积">{{ details.measureArea ? `${details.measureArea}m²`:''}}</el-descriptions-item>
                    <el-descriptions-item label="需求类型">
                      <dict-tag :options="dict.type.lbg_request_type" :value="details.demandType"/>
                    </el-descriptions-item>
                    <el-descriptions-item label="场地类型">
                      <dict-tag :options="dict.type.lbg_site_type" :value="details.siteProperties"/>
                    </el-descriptions-item>
                    <el-descriptions-item label="装修类型">
                      <dict-tag :options="dict.type.lbg_request_type" :value="details.demandType"/>
                    </el-descriptions-item>
                    <el-descriptions-item label="免租期">{{ details.rentFreePeriod ? `${details.rentFreePeriod}天` :''}}</el-descriptions-item>
                </el-descriptions>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>项目计划</span>
              </div>
              <div class="col-list">
                <el-descriptions title="" :column="1">
                    <el-descriptions-item label="计划时间">{{ details.planTime }}</el-descriptions-item>
                    <el-descriptions-item label="项目预算">{{ details.projectBudget?`${details.projectBudget}元`:'' }}</el-descriptions-item>
                    <el-descriptions-item label="预计勘测">{{ details.surveyTime }}</el-descriptions-item>
                    <el-descriptions-item label="对接方式">
                      <dict-tag :options="dict.type.lbg_docking_type" :value="details.dockType"/>
                    </el-descriptions-item>
                </el-descriptions>
              </div>
            </el-card>
          </el-col>
        </el-row>

        <el-divider content-position="left">商机跟进</el-divider>
        <div class="tab-view" v-if="!loading">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="跟进记录" name="1"></el-tab-pane>
             <el-tab-pane label="平台跟进记录" name="5"></el-tab-pane>
            <el-tab-pane label="签约信息" name="2"></el-tab-pane>
            <el-tab-pane label="输单记录" name="3">
              <!-- <upLost></upLost> -->
            </el-tab-pane>
            <el-tab-pane label="申请返款记录" name="4"></el-tab-pane>
          </el-tabs>

          <follow :businessId="details.id" :sdStatusSh="details.sdStatusSh" v-if="activeName == 1"></follow>
          <follow-platform :businessId="details.id" :sdStatusSh="details.sdStatusSh" v-if="activeName == 5"></follow-platform>

          <contract :businessId="businessId" v-if="activeName == 2"></contract>
          <el-descriptions title="" border :column="1" v-if="activeName == 3">
            <el-descriptions-item label="输单原因" :labelStyle="{'width': '100px'}">
              <dict-tag
                :options="dict.type.lbg_enterprise_sale_fail_reason"
                :value="details.borLostReason"
              />
            </el-descriptions-item>
            <el-descriptions-item label="输单备注" :labelStyle="{'width': '100px'}">
              {{ details.borLostRemark }}
            </el-descriptions-item>
          </el-descriptions>
          <backOrder :businessId="businessId" v-if="activeName == 4"></backOrder>
        </div>
      </el-card>

      <el-dialog :title="contractDialogTitle" :visible.sync="contractDialogVisible" width="600px" :before-close="closeDialog" destroy-on-close>
        <el-form :model="contractForm" :rules="contractRules" ref="contractForm" label-width="120px" label-position="left">

            <el-form-item label="签约金额" prop="contractAmount">
                <el-input v-model="contractForm.contractAmount" placeholder="请输入签约金额" type="number">
                   <template slot="prepend">¥</template>
                   <template slot="append">元</template>
                </el-input>
            </el-form-item>

            <el-form-item label="合同编号" prop="signNum">
                <el-input v-model="contractForm.signNum" placeholder="请输入合同编号"></el-input>
            </el-form-item>

            <el-form-item label="签约日期" prop="signTime" v-if="contractDialogVisible">
              <el-date-picker type="date" placeholder="选择日期" v-model="contractForm.signTime" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
            </el-form-item>

            <el-form-item label="开工日期" prop="businessStartTime" v-if="details.projectNature == 'sgxm'">
              <el-date-picker type="date" placeholder="选择日期" v-model="contractForm.businessStartTime" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
            </el-form-item>

            <el-form-item label="预计完工日期" prop="businessFinishTime" v-if="details.projectNature == 'sgxm'">
              <el-date-picker type="date" placeholder="选择日期" v-model="contractForm.businessFinishTime" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
            </el-form-item>

            <el-form-item label="设计合同附件" prop="contractFileId">
              <el-upload
                v-model="contractForm.contractFileId"
                class="upload-demo"
                :action="uploadFileUrl"
                :headers="headers"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :before-upload="handleBeforeUpload"
                :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png/pdf文件，且不超过20MB</div>
              </el-upload>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="contractDialogVisible = false">取 消</el-button>
            <el-button type="primary" :disabled="saving" @click="submitContractForm('contractForm')">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="输单" :visible.sync="lostDialogVisible" width="600px" :before-close="closeLostDialog" destroy-on-close>
        <el-form :model="lostForm" :rules="lostRules" ref="lostForm" label-width="100px" label-position="left" class="detail-form">
          <el-form-item label="输单原因" prop="lostReason">
            <el-select v-model="lostForm.lostReason" placeholder="请选择输单原因">
              <el-option
                  v-for="(item, index) in dict.type.lbg_enterprise_sale_fail_reason"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="lostRemark">
            <el-input type="textarea" v-model="lostForm.lostRemark"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeLostDialog">取 消</el-button>
          <el-button type="primary" @click="submitLostForm('lostForm')">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="退单" :visible.sync="backDialogVisible" width="600px" :before-close="closeBackDialog" destroy-on-close>
        <el-form :model="backForm" :rules="backRules" ref="backForm" label-width="100px" label-position="left" class="detail-form">
          <el-form-item label="退单原因" prop="backOrderReason">
            <el-select v-model="backForm.backOrderReason" placeholder="请选择输单原因">
              <el-option
                  v-for="(item, index) in dict.type.order_back_reason"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="backOrderRemark">
            <el-input type="textarea" v-model="backForm.backOrderRemark"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeBackDialog">取 消</el-button>
          <el-button type="primary" @click="submitBackForm('backForm')">确 定</el-button>
        </div>
      </el-dialog>

    </div>
</template>

<script>
const steps = {
  // 设计项目
  'sjxm': [
    {
      label: '需求调研',
      key: 'xqdyStatusSh',
    },
    {
      label: '现场勘察',
      key: 'xckcStatusSh',
    },
    {
      label: '方案汇报',
      key: 'fahbStatusSh',
    },
    {
      label: '价格比选',
      key: 'jgbxStatusSh',
    },
    {
      label: '合同签约',
      key: 'htqyStatusSh',
    },
    {
      label: '中标结果通知',
      key: 'zbjgStatusSh'
    },
    {
      label: '输单',
      key: 'sdStatusSh',
    },
    {
      label: '无效/退单',
      key: 'tdStatusSh',
    },
  ],
  // 施工项目
  'sgxm': [
    {
      label: '现场勘察',
      key: 'xckcStatusSh',
    },
    {
      label: '招标答疑',
      key: 'fahbStatusSh'
    },
    {
      label: '价格比选',
      key: 'jgbxStatusSh'
    },
    {
      label: '合同签约',
      key: 'htqyStatusSh'
    },
    {
      label: '中标结果通知',
      key: 'zbjgStatusSh'
    },
    {
      label: '输单',
      key: 'sdStatusSh'
    },
    {
      label: '无效/退单',
      key: 'tdStatusSh'
    },
  ],
  // epc
  'epcxm': [
    {
      label: '需求调研',
      key: 'xqdyStatusSh'
    },
    {
      label: '现场勘察',
      key: 'xckcStatusSh'
    },
    {
      label: '方案汇报',
      key: 'fahbStatusSh'
    },
    {
      label: '价格比选',
      key: 'jgbxStatusSh'
    },
    {
      label: '合同签约',
      key: 'htqyStatusSh'
    },
    {
      label: '中标结果通知',
      key: 'zbjgStatusSh'
    },
    {
      label: '输单',
      key: 'sdStatusSh'
    },
    {
      label: '无效/退单',
      key: 'tdStatusSh'
    },
  ]
};
import { getToken } from '@/utils/auth'
import _ from 'lodash';
import follow from './details/follow';
import followPlatform from './details/follow-platform';
import contract from './details/contract';
import upLost from './details/upLost';
import backOrder from './details/backOrder';
export default {
    name: 'platform-detail',
    components: {
      follow,
      followPlatform,
      contract,
      // upLost,
      backOrder
    },
    dicts: [
      'business_platform_status',
      'lbg_business_source',
      'lbg_project_type',
      'lbg_docking_type',
      'bidding_status',
      'lbg_site_type',
      'firm_trade',
      'firm_type',
      'is_list',
      'lbg_request_type',
      'order_back_process_result',
      'lbg_enterprise_sale_fail_reason',
      'order_back_reason',
      'lost_reason',
      'invalid_reason',
    ],
    data () {
      return {
        uploadFileUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
        headers: {
          Authorization: "Bearer " + getToken()
        },
        businessStep: [],
        details: {},
        businessId: '',
        loading: true,
        activeStep: 0,
        activeName: '1',
        memberDialogVisible: false,
        searchName: '',
        memberData: [],
        saving: false,
        contractDialogVisible: false,
        contractDialogTitle: '',
        contractStatus: '',
        contractForm: {
          contractAmount: '',
          signNum: '',
          signTime: '',
          contractFileId: '',
          businessStartTime: '',
          businessFinishTime: '',
        },
        contractRules: {
          contractAmount: [
            { required: true, message: '请输入签约金额', trigger: 'blur' }
          ],
          signNum: [
            {required: true, message: '请输入合同编号', trigger: 'blur' }
          ],
          signTime: [
            { required: true, message: '请选择签约日期', trigger: 'change' }
          ],
        },
        fileList: [],
        lostForm: {
          lostRemark: '',
          lostReason: '',
        },
        lostDialogVisible: false,
        lostRules: {
          lostReason: [
            { required: true, message: '请选择输单原因', trigger: 'change' }
          ],
        },
        backForm: {
          backOrderRemark: '',
          backOrderReason: '',
        },
        backDialogVisible: false,
        backRules: {
          backOrderReason: [
            { required: true, message: '请选择退单原因', trigger: 'change' }
          ],
        },
      };
    },
    created() {
      this.businessId = this.$route.query.id;
      this.getDetails();

    },
    methods: {
      back() {
        this.$router.back();
      },
      handleClick() {},
      getDetails() {
        this.loading = true;
        this.$api.get('/merchantApi/businessOpportunity/'+this.businessId, {
        }).then(res => {
          if(res.code == 200) {
            this.details = res.data;

            if(this.details.isrob == 0) {
              // 未抢单状态下 隐藏信息
              this.details.park = this.details.park.replace(/[0-9]/g, "*");
              let index = this.details.firmName.indexOf('有限公司');
              this.details.firmName = '****' + this.details.firmName.substr(index);
            }


            // == 0 会显示确认按钮，== null 不显示确认按钮, == 1 打勾
            // 判断合同状态 控制是否可输单或退单
            if(this.details.htqyStatusSh == 1) {
              // this.details.sdStatusSh = null;
              this.details.tdStatusSh = null;
            }

            // 输单不显示确认
            if(this.details.sdStatusSh == 0) {
              this.details.sdStatusSh = null;
            }

            // 判断竞标状态，不等于2时不可以提交合同
            if(this.details.biddingStatusSh != 2) {
              this.details.htqyStatusSh = null;
            }
            // 判断中标结果
            if(this.details.zbjgStatusSh == 0) {
              this.details.zbjgStatusSh = null;
            }
            this.businessStep = steps[this.details.projectNature];
            this.activeName = '1';
            this.loading = false;
          }
        })
      },
      handleConfirm(step) {
        if(this.details.bidStatus != '2' && this.details.bidStatus != '5') {
          this.$message.warning('当前分标状态不能进行操作！');
          return false;
        }
        switch(step.key) {
          // 合同签约
          case 'htqyStatusSh':
            let nature = this.details.projectNature;
            if(nature == 'sjxm') {
              this.contractDialogTitle = '设计合同提交';
              this.contractStatus = '4';
            } else if(nature == 'sgxm') {
              this.contractDialogTitle = '施工合同提交';
              this.contractStatus = '5';
            } else {
               this.contractDialogTitle = 'EPC合同提交';
               this.contractStatus = '';
            }
            this.contractDialogVisible = true;
            break;
          // 输单
          case 'sdStatusSh':
            this.lostDialogVisible = true;
            break;
          // 退单
          case 'tdStatusSh':
            this.backDialogVisible = true;
            break;
          // 更新状态
          default:
            this.updateStatus(step.key);
            break;
        }
      },

      // 提交更新状态
      updateStatus(value) {
        if(this.saving) {
          return false;
        }
        this.saving = true;
        this.$api.post('/merchantApi/businessOpportunity/manualUpdateXqdyStatus', {
          businessOpportunityRefId: this.businessId,
          type: value
        })
        .finally(() => {
          this.saving = false;
        })
        .then(res => {
          if(res.code == 200) {
            this.getDetails();
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      closeDialog() {
        this.$refs['contractForm'].resetFields();
        this.$refs['contractForm'].clearValidate();
        this.contractStatus = '';
        this.fileList = [];
        this.contractDialogVisible = false;
      },

      // 提交签约45
      submitContractForm(formName) {
        if(this.saving) {
          return false;
        }
        let form = _.cloneDeep(this.contractForm);
        if(this.fileList.length) {
            form.contractFileId = this.fileList[0].fileId;
        }
        // console.log(form);
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saving = true;

            this.$api.post('/merchantApi/businessOpportunity/addContractSignRecord', {
              ...form,
              businessPlatformStatus: this.contractStatus,
              businessOpportunityId: this.details.id
            })
            .finally(() => {
              this.saving = false;
            })
            .then(res => {
              if(res.code == 200) {
                this.$message.success(res.msg);
                this.closeDialog();
                this.getDetails();
              } else {
                this.$message.error(res.msg);
              }
            })

          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 上传合同附件
      handleSuccess(res) {
        this.fileList = [];
        if(res.code == 200) {
            this.fileList.push(res.data);
            this.$message.success("上传成功");
        } else {
            this.fileList = [];
            this.$message.error(res.msg);
        }
      },
      handleBeforeUpload(file) {
        // 校检文件大小
        const isLt = file.size / 1024 / 1024 < 5;
        if (!isLt) {
            this.$message.error(`上传文件大小不能超过5MB!`);
            return false;
        }
      },
      handleRemove() {
        this.fileList = [];
        this.contractForm.contractFileId = '';
      },

      // 提交输单操作3
      submitLostForm(formName) {
        if(this.saving) {
          return false;
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saving = true;
            this.$api.post('/merchantApi/businessOpportunity/upLostOrder', {
              ...this.lostForm,
              businessOpportunityRefId: this.businessId
            })
            .finally(() => {
              this.saving = false;
            })
            .then(res => {
              if(res.code == 200) {
                this.$message.success(res.msg);
                this.closeLostDialog();
                this.getDetails();
              } else {
                this.$message.error(res.msg);
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      closeLostDialog() {
        this.$refs['lostForm'].resetFields();
        this.$refs['lostForm'].clearValidate();
        this.lostDialogVisible = false;
      },
      submitBackForm(formName) {
        if(this.saving) {
          return false;
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saving = true;
            this.$api.post('/merchantApi/businessOpportunity/upBackOreder', {
              ...this.backForm,
              businessOpportunityRefId: this.businessId
            })
            .finally(() => {
              this.saving = false;
            })
            .then(res => {
              if(res.code == 200) {
                this.$message.success(res.msg);
                this.closeBackDialog();
                this.getDetails();
              } else {
                this.$message.error(res.msg);
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      closeBackDialog(done) {
        this.$refs['backForm'].resetFields();
        this.$refs['backForm'].clearValidate();
        this.backDialogVisible = false;
      },
    }
}

</script>
<style lang='less' scoped>

::v-deep .el-card {
  display: flex;
  flex-direction: column;

  .el-card__body {
    flex: 1;
    overflow-y: auto;

    .el-descriptions-item__cell {
      font-weight: bold;
    }
  }
}
::v-deep .card-row {
  .col-list {
    height: 300px;
    max-height: 300px;
    overflow-y: auto;

    .el-descriptions-item__container {
      align-items: center;
    }
  }
}
.step-view {
  padding: 20px 0;

  .step-active {
    background: #E84B22;
    border-color: #fff;
    color: #fff;
  }
  .el-step__line-inner {
    transition-delay: 0ms;
    border-width: 0px;
    width: 0%;
  }
  .el-step__title {
    font-size: 14px;
    // display: flex;
    // align-items: center;
  }
}
::v-deep .tab-view {
  .el-tabs__nav-wrap {
    &::after {
      display: none;
    }
  }
  .el-tab-pane {
    // padding: 20px 0;
  }

  .el-descriptions__title {
    font-size: 14px;
  }
}

.detail-form {
  width: 400px;

  .el-select {
    width: 100%;
  }
}
</style>
