<template>
    <div>
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%">
        <el-table-column
          prop="createTime"
          label="申请时间"
        />
        <el-table-column
          prop="createAccountName"
          label="申请人" 
          width="200px"       
        />
        <el-table-column
          prop="reason"
          label="无效类型"
        >
          <template slot-scope="scope">
            <dict-tag
              :options="dict.type.order_back_reason"
              :value="scope.row.rejectReason"
            />    
          </template>
        </el-table-column>

        <el-table-column
          prop="remarks"
          label="原因描述"
        />
        <el-table-column label="处理状态" width="200">
          <template slot-scope="scope"> 
            <dict-tag
              :options="dict.type.order_back_process_result"
              :value="scope.row.processResult"
            />             
          </template>
        </el-table-column>

        <el-table-column
          prop="processTime"
          label="平台处理时间"
        />
      </el-table>

      <pagination
          v-show="total>0"
          :total="total"
          :page.sync="query.pageNum"
          :limit.sync="query.pageSize"
          @pagination="getList"
      />
    </div>
</template>

<script>
export default {
    name: 'backOrder',
    components: {},
    props: ['businessId'],
    dicts: [
      'order_back_process_result',
      'order_back_reason',
    ],
    
    data () {
      return {
        tableData: [],
        total: 0,
        query: {
          pageNum: 10,
          pageSize: 1
        },
        loading: true,
      };
    },
    created() {
      this.getList();
    },
    methods: {
      getList() {
        this.loading = true;
        this.$api.get('/merchantApi/businessOpportunity/getBackOrdertList', {
          businessOpportunityRefId: this.businessId
        })
        .finally(() => {
          this.loading = false;
        })
        .then(res => {
          if(res.code = 200) {
            this.tableData = res.rows;
            this.total = res.total;
          }
        })
      }
    }
}

</script>
<style lang='less' scoped>
</style>