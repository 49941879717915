<template>
    <div class="detail-container">
        <el-card class="card-left">
            <div slot="header" class="clearfix">
                <span>品宣管理</span>
            </div>
            <el-form v-if="!loading" :inline="true" :model="form" class="form-inline" label-position="right" ref="ruleForm">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="banner图片">
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadFileUrl"
                                :headers="headers"
                                accept=".jpeg, .png, .jpg"
                                :file-list="picFileList"
                                :on-preview="picPreview"
                                :on-success="picUploadSuccess"
                                :on-remove="picUploadRemove"
                                :before-upload="picBeforeUpload"
                            >
                                <img v-if="picFileList[0] && picFileList[0].url" :src="picFileList[0].url" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                <div slot="tip" class="el-upload__tip">焦点图尺寸：建议宽高比4:1,仅支持jpg,PNG.jpeg图片文件,文件小于等于10M</div>
                            </el-upload>
                            <div class="upload-info">
                                <span class="time" v-if="form.checkTime">{{ form.checkTime }}</span>
                                <dict-tag :options="dict.type.lbg_profile_photo_check_status" :value="form.checkStatus"/>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24"></el-col>

                    <el-col :span="12" :xs="0" :sm="0" >
                        <el-form-item label="上传视频封面" >
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadFileUrl"
                                :headers="headers"
                                accept=".jpeg, .png, .jpg"
                                :file-list="coverFileList"
                                :on-preview="filePreview"
                                :on-success="fileUploadSuccess"
                                :on-remove="fileUploadRemove"
                                :before-upload="fileBeforeUpload"
                            >
                                <img v-if="coverFileList[0] && coverFileList[0].url" :src="coverFileList[0].url" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                <div slot="tip" class="el-upload__tip">视频封面尺寸：280×160像素,或者是 560×320像素仅支持jpg,PNG.jpeg图片文件,文件小于等于2M</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" :xs="0" :sm="0" ></el-col>
                    <el-col :span="12" :xs="0" :sm="0" >
                        <el-form-item label="上传视频">
                            <el-upload
                            class="avatar-uploader video-uploader el-upload--text"
                            :action="uploadFileUrl"
                            :headers="headers"
                            :file-list="vedioFileList"
                            :on-success="handleVideoSuccess"
                            :before-upload="beforeUploadVideo"
                            :on-remove="vedioUploadRemove"
                            >
                                <video v-if="vedioFileList[0] && vedioFileList[0].url && videoFlag == false" :src="vedioFileList[0].url" class="vedio" controls="controls">您的浏览器不支持视频播放</video>
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                <!-- <el-progress v-if="videoFlag == true" type="circle" :percentage="videoUploadPercent" style="margin-top:30px;"></el-progress> -->
                                <div slot="tip" class="el-upload__tip">视频尺寸：16：9,仅支持MP4,AVI格式视频文件,文件小于等于150M</div>
                                <!-- <div class="mask"><i class="el-icon-upload"></i></div> -->
                            </el-upload>

                            <div class="upload-info">
                                <span class="time" v-if="form.checkTime">{{ form.checkTime }}</span>
                                <dict-tag :options="dict.type.lbg_profile_photo_check_status" :value="form.checkStatus"/>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="关于我们">
                            <WangEditor v-model="form.aboutMe"></WangEditor>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item style="text-align: left; margin-top: 20px;">
                    <el-button type="primary" :disabled="saving" @click="submitForm('ruleForm')">提交审核</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import WangEditor from '@/components/WangEditor/index.vue'
export default {
    name: 'product-list',
    components: {
        WangEditor
    },
    dicts: [
      'lbg_profile_photo_check_status',
    ],
    data () {
        return {
            uploadFileUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
            headers: {
                Authorization: "Bearer " + getToken()
            },
            loading: true,
            saving: false,
            form: {
                picUrl: '',
                vedioUrl: '',
                coverFileList: '',
                aboutMe: ''
            },
            picFileList: [],
            coverFileList: [],
            vedioFileList: [],
            fileSize: 10,
            videoFlag: false,
            videoUploadPercent: 0,
        };
    },
    created() {
        this.getDetails();
    },
    methods: {
        getDetails() {
            this.$api.get('/merchantApi/storeShow')
            .finally(() => {
                this.loading = false;
            })
            .then(res => {
                 this.picFileList= [];
                this.coverFileList= [];
                this.vedioFileList= [];
                if(res.code == 200) {
                    if(res.data) {
                        this.form = res.data;
                    } else {
                        return false;
                    }
                    if(this.form.picUrl) {
                        this.picFileList.push({
                            name: 'banner图片',
                            fileId: this.form.picFileId,
                            url: this.$fileBaseUrl+this.form.picUrl
                        });
                    }
                    if(this.form.vedioPicFileId) {
                        this.coverFileList.push({
                            name: '视频封面',
                            fileId: this.form.vedioPicFileId,
                            url: this.$fileBaseUrl+this.form.vedioCover
                        });
                    }
                    if(this.form.vedioFileId) {
                        this.vedioFileList.push({
                            name: '视频',
                            fileId: this.form.vedioFileId,
                            url: this.$fileBaseUrl+this.form.vedioUrl
                        });
                    }
                }
            });
        },
        submitForm(formName) {
            if(this.saving) {
                return false;
            }
            // let form = Object.assign(this.form, {});
            let { id, picFileId, vedioPicFileId, vedioFileId, aboutMe } = this.form;
            let form = {
                id: id,
                picFileId: picFileId || 0,
                vedioPicFileId: vedioPicFileId || 0,
                vedioFileId: vedioFileId || 0,
                aboutMe: aboutMe
            };

            if(this.picFileList.length) {
                form.picFileId = this.picFileList[0].fileId;
            }else{
              this.$message.warning('请上传banner图片')
              return;
            }
            if(!this.form.aboutMe) {
                this.$message.warning('请输入关于我们内容');
                return;
            }
            if(this.coverFileList.length) {
                form.vedioPicFileId = this.coverFileList[0].fileId;
            }
            if(this.vedioFileList.length) {
                form.vedioFileId = this.vedioFileList[0].fileId;
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.saving = true;

                    if(this.form.id) {
                        this.$api.put('/merchantApi/storeShow', form)
                        .finally(() => {
                            this.saving = false;
                        })
                        .then(res => {
                            if(res.code == 200) {
                                this.$message.success(res.msg);
                                this.getDetails();
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    } else {
                        this.$api.post('/merchantApi/storeShow', form)
                        .finally(() => {
                            this.saving = false;
                        })
                        .then(res => {
                            if(res.code == 200) {
                                this.$message.success(res.msg);
                                this.getDetails();
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        picPreview() {},
        picUploadSuccess(res) {
            this.picFileList = [];
            if(res.code == 200) {
                this.picFileList.push(res.data);
                this.$message.success("上传成功");
            } else {
                this.picFileList = [];
                this.$message.error(res.msg);
            }
        },
        picUploadRemove() {
            this.picFileList = [];
            this.form.picFileId = '';
        },
        picBeforeUpload(file) {
            // 校检文件大小
            if (this.fileSize) {
                const isLt = file.size / 1024 / 1024 < this.fileSize;
                if (!isLt) {
                    this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
                    return false;
                }
            }
        },
        handleVideoSuccess(res) {
            console.log(res);
            this.videoFlag = false;
            this.vedioFileList = [];
            // this.videoUploadPercent = 0;
            if(res.code == 200){
                this.vedioFileList.push(res.data)
                this.$message.success("上传成功");
            }else{
                this.vedioFileList = [];
                this.$message.error('视频上传失败，请重新上传！');
            }
        },
        beforeUploadVideo(file) {
            const isLt10M = file.size / 1024 / 1024  < 10;
            if (['video/mp4', 'video/avi'].indexOf(file.type) == -1) {
                this.$message.error('请上传正确的视频格式');
                return false;
            }
            if (!isLt10M) {
                this.$message.error('上传视频大小不能超过10MB哦!');
                return false;
            }
        },
        uploadVideoProcess(event, file) {
            this.videoFlag = true;
            this.videoUploadPercent = file.percentage.toFixed(0);
        },
        vedioUploadRemove() {
            this.vedioFileList = [];
            this.form.vedioFileId = '';
        },
        filePreview() {},
        fileUploadSuccess(res) {
            console.log(res);
            this.coverFileList = [];
            if(res.code == 200) {
                this.coverFileList.push(res.data);
                this.$message.success("上传成功");
            } else {
                this.coverFileList = [];
                this.$message.error(res.msg);
            }
        },
        fileUploadRemove() {
            this.coverFileList = [];
            this.form.vedioPicFileId = '';
        },
        fileBeforeUpload(file) {
            // 校检文件大小
            const isLt = file.size / 1024 / 1024 < 2;
            if (!isLt) {
                this.$message.error(`上传文件大小不能超过2MB!`);
                return false;
            }
        },
    }
}

</script>
<style lang='less' scoped>
::v-deep .el-card {
  display: flex;
  flex-direction: column;

  .el-card__body {
    flex: 1;
    overflow-y: auto;
  }
}
::v-deep .form-inline {
    // width: 590px;
    .el-form-item {
    display: block !important;
    margin-bottom: 10px !important;
    .el-form-item__label {
        font-weight: bold;
    }
    .el-form-item__content {
        display: block !important;
    }
    .el-select {
        width: 100%;
    }
    .el-textarea__inner {
        height: 100px;
    }
    }
}
::v-deep .avatar-uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 100%;
    }

    .el-upload:hover {
      border-color: #409EFF;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 100%;
      height: 178px;
      display: block;
    }
    .el-upload__tip {
      line-height: 20px;
    }
    .vedio {
        width: 100%;
        height: 340px;
    }
}
::v-deep .video-uploader {
    .el-upload {
        height: 340px;
    }
}
.upload-info {
    display: flex;
    margin-top: 20px;
    .time {
        margin-right: 20px;
        color: #909399;
    }
}
</style>
