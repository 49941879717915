<template>
    <div class="detail-container">
      <el-card class="card-left">
        <div slot="header" class="clearfix">
          <span>{{ caseId ? '编辑业绩' : '添加业绩' }}</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="back">返回</el-button>
        </div>
        <el-form v-if="!loading" :inline="true" :model="form" class="form-inline" label-position="right" :rules="rules" ref="ruleForm">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="项目名称" prop="resultName">
                <el-input v-model="form.resultName" placeholder="请输入项目名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="项目编号" prop="resultCode">
                <el-input v-model="form.resultCode" placeholder="请输入项目编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="项目类型" prop="resultType">
                  <el-select v-model="form.resultType" placeholder="请选择项目类型" clearable>
                    <el-option
                      v-for="(item, index) in dict.type.store_result_type"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
              </el-form-item>  
            </el-col>
            <el-col :span="6">
              <el-form-item label="项目属性" prop="caseCate">
                <el-select v-model="form.caseCate" placeholder="请选择项目属性">
                    <el-option
                      v-for="(item, index) in dict.type.store_case_cate"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item label="项目地址" prop="provinceId">
                    <el-select v-model="form.provinceId" placeholder="请选择省" clearable @change="changeProvince">
                        <el-option
                          v-for="(item, index) in provinceList"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label=" " prop="cityId">
                    <el-select v-model="form.cityId" placeholder="请选择市" clearable @change="changeCity">
                        <el-option
                          v-for="(item, index) in cityList"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label=" " prop="districtId">
                    <el-select v-model="form.districtId" placeholder="请选择区" clearable>
                      <el-option
                        v-for="(item, index) in areaList"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6">
              <el-form-item label="详细地址" prop="address">
                <el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="签约日期" prop="signTime">
                <el-date-picker type="date" placeholder="选择日期" v-model="form.signTime" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="项目面积" prop="resultArea">
                <el-input placeholder="请输入项目面积" v-model="form.resultArea">
                  <template slot="append">㎡</template>
                </el-input>
              </el-form-item>
            </el-col>
            
            <el-col :span="6">
              <el-form-item label="工程造价" prop="spendMoney">
                <el-input placeholder="请输入工程造价" v-model="form.spendMoney">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="项目工程" prop="spendTime">
                <el-input placeholder="请输入项目工程" v-model="form.spendTime">
                  <template slot="append">天</template>
                </el-input>
              </el-form-item>
            </el-col>
             <el-col :span="6">
              <el-form-item label="建设单位" prop="bulidCompany">
                <el-input v-model="form.bulidCompany" placeholder="请输入建设单位"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <!-- <el-col :span="6">
              <el-form-item label="关联案例" prop="refCaseId">
                <el-input placeholder="请输入关联案例id" v-model="form.refCaseId">
                </el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="6">
              <el-form-item label="所属行业" prop="industryType">
                  <el-select v-model="form.industryType" placeholder="请选择所属行业" clearable>
                    <el-option
                      v-for="(item, index) in dict.type.firm_trade"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="合同属性" prop="contractType">
                <el-select v-model="form.contractType" placeholder="请选择合同属性">
                    <el-option
                      v-for="(item, index) in dict.type.store_contract_type"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row :gutter="20" style="height: 340px">
            <el-col :span="6">
              <el-form-item label="合同封面">
                <el-upload
                  class="avatar-uploader"
                  :action="uploadFileUrl"
                  :headers="headers"
                  :multiple="false"
                  accept=".jpeg, .png, .jpg"
                  :on-preview="pic1Preview"
                  :on-success="pic1UploadSuccess"
                  :on-remove="pic1UploadRemove"
                  :before-upload="handleBeforeUpload"
                  :file-list="files.pic1"
                >
                  <img v-if="files.pic1[0] && files.pic1[0].url" :src="files.pic1[0].url" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <div slot="tip" class="el-upload__tip">请上传大小不超过5MB,格式为png/jpg/jpeg的文件</div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="合同第一页">
                <el-upload
                  class="avatar-uploader"
                  :action="uploadFileUrl"
                  :headers="headers"
                  :multiple="false"
                  accept=".jpeg, .png, .jpg"
                  :on-preview="pic1Preview"
                  :on-success="pic2UploadSuccess"
                  :on-remove="pic2UploadRemove"
                  :before-upload="handleBeforeUpload"
                  :file-list="files.pic2"
                >
                  <img v-if="files.pic2[0] && files.pic2[0].url" :src="files.pic2[0].url" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <div slot="tip" class="el-upload__tip">请上传大小不超过5MB,格式为png/jpg/jpeg的文件</div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="付款方式页">
                <el-upload
                  class="avatar-uploader"
                  :action="uploadFileUrl"
                  :headers="headers"
                  :multiple="false"
                  accept=".jpeg, .png, .jpg"
                  :on-preview="pic1Preview"
                  :on-success="pic3UploadSuccess"
                  :on-remove="pic3UploadRemove"
                  :before-upload="handleBeforeUpload"
                  :file-list="files.pic3"
                >
                  <img v-if="files.pic3[0] && files.pic3[0].url" :src="files.pic3[0].url" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <div slot="tip" class="el-upload__tip">请上传大小不超过5MB,格式为png/jpg/jpeg的文件</div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="最后公章页">
                <el-upload
                  class="avatar-uploader"
                  :action="uploadFileUrl"
                  :headers="headers"
                  :multiple="false"
                  accept=".jpeg, .png, .jpg"
                  :on-preview="pic1Preview"
                  :on-success="pic4UploadSuccess"
                  :on-remove="pic4UploadRemove"
                  :before-upload="handleBeforeUpload"
                  :file-list="files.pic4"
                >
                  <img v-if="files.pic4[0] && files.pic4[0].url" :src="files.pic4[0].url" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <div slot="tip" class="el-upload__tip">请上传大小不超过5MB,格式为png/jpg/jpeg的文件</div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item style="text-align: right;">
            <el-button @click="back">取消</el-button>
            <el-button type="primary"  :disabled="saving" @click="submitForm('ruleForm')">提交审核</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
</template>

<script>
import { getToken } from '@/utils/auth'
export default {
    name: 'result-add',
    components: {},
    dicts: [
      'approval_status',
      'firm_trade',
      'store_contract_type',
      'store_result_type',
      'store_case_cate',
    ],
    data () {
      return {
        uploadFileUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
        headers: {
          Authorization: "Bearer " + getToken()
        },
        caseList: [],
        provinceList: [],
        cityList: [],
        areaList: [],
        saving: false,
        loading: true,
        caseId: '',
        form: {
          resultName: '',
          resultCode: '',
          bulidCompany: '',
          industryType: '',
          provinceId: '',
          cityId: '',
          districtId: '',
          address: '',
          signTime: '',
          resultArea: '',
          spendMoney: '',
          spendTime: '',
          resultType: '',
          caseCate: '',
          contractType: '',
          refCaseId: '',
          contractPic1FileId: '',
          contractPic2FileId: '',
          contractPic3FileId: '',
          contractPic4FileId: ''
        },
        files: {
          pic1: [],
          pic2: [],
          pic3: [],
          pic4: [],
        },
        rules: {
          resultName: [
            { required: true, message: '请输入项目名称', trigger: 'blur' }
          ],
          resultCode: [
            { required: true, message: '请输入项目编号', trigger: 'blur' }
          ],
          resultType: [
            { required: true, message: '请选择项目类型', trigger: 'change' }
          ],
          caseCate: [
            { required: true, message: '请选择项目属性', trigger: 'change' }
          ],
        },
        fileSize: 5,
        fileList: []
      };
    },
    created() {
      this.getProvinceList();
      this.caseId = this.$route.query.id || '';
      if(this.caseId) {
        this.getDetails();
      } else {
        this.loading = false;
      }
    },
    methods: {
      getDetails() {
        this.$api.get('merchantApi/storeResult/'+this.caseId, {
        }).then(res => {
          if(res.code == 200) {
            this.form = res.data;
            if(this.form.provinceId) {
              this.getCityList(this.form.provinceId);
            }
            if(this.form.cityId) {
              this.getAreaList(this.form.cityId);
            }
            this.showFiles();
            this.loading = false;
          }
        })
      },
      back() {
        this.$router.back();
      },
      save() {},
      submitForm(formName) {
        if(this.saving) {
          return false;
        }
        let form = Object.assign(this.form, {});
        if(this.files.pic1.length) {
          this.form.contractPic1FileId = this.files.pic1[0].fileId
        }
        if(this.files.pic2.length) {
          this.form.contractPic2FileId = this.files.pic2[0].fileId
        }
        if(this.files.pic3.length) {
          this.form.contractPic3FileId = this.files.pic3[0].fileId
        }
        if(this.files.pic4.length) {
          this.form.contractPic4FileId = this.files.pic4[0].fileId
        }
        console.log(form);
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saving = true;
            if(this.caseId) {
              this.$api.put('/merchantApi/storeResult', form)
              .finally(() => {
                this.saving = false;
              })
              .then(res => {
                if(res.code == 200) {
                  this.$message.success(res.msg);
                  this.back();
                } else {
                  this.$message.error(res.msg);
                }
              })
            } else {
              this.$api.post('/merchantApi/storeResult', form)
              .finally(() => {
                this.saving = false;
              })
              .then(res => {
                if(res.code == 200) {
                  this.$message.success(res.msg);
                  this.back();
                } else {
                  this.$message.error(res.msg);
                }
              })
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      showFiles() {
        if(this.form.contractPic1FileId) {
          let obj = {
            name: '合同封面',
            fileId: this.form.contractPic1FileId,
            url: this.$fileBaseUrl+this.form.contractPic1FileUrl
          };
          this.files.pic1.push(obj);
        }
        if(this.form.contractPic2FileId) {
          let obj = {
            name: '合同第一页',
            fileId: this.form.contractPic2FileId,
            url: this.$fileBaseUrl+this.form.contractPic2FileUrl
          };
          this.files.pic2.push(obj);
        }
        if(this.form.contractPic3FileId) {
          let obj = {
            name: '付款方式页',
            fileId: this.form.contractPic3FileId,
            url: this.$fileBaseUrl+this.form.contractPic3FileUrl
          };
          this.files.pic3.push(obj);
        }
        if(this.form.contractPic4FileId) {
          let obj = {
            name: '最后公章页',
            fileId: this.form.contractPic4FileId,
            url: this.$fileBaseUrl+this.form.contractPic4FileUrl
          };
          this.files.pic4.push(obj);
        }
      },
      getProvinceList() {
        this.$api.get('/lbg/province/list')
        .then(res => {
          if(res.code == 200) {
            this.provinceList = res.rows;
          }
        })
      },
      getCityList(id) {
        this.$api.get('/lbg/city/list', {
          provinceId: this.form.provinceId
        })
        .then(res => {
          if(res.code == 200) {
            this.cityList = res.rows;
          }
        })
      },
      getAreaList(id) {
        this.$api.get('/lbg/district/list', {
          cityId: id
        })
        .then(res => {
          if(res.code == 200) {
            this.areaList = res.rows;
          }
        })
      },
      changeProvince(id) {
        this.getCityList(id);
        this.form.cityId = '';
        this.form.districtId = '';
      },
      changeCity(id) {
        this.getAreaList(id);
        this.form.districtId = '';
      },
      handleBeforeUpload(file) {
        // 校检文件大小
        if (this.fileSize) {
          const isLt = file.size / 1024 / 1024 < this.fileSize;
          if (!isLt) {
            this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
            return false;
          }
        }
      },
      pic1UploadSuccess(res, file) {
        this.files.pic1 = [];
        if(res.code == 200) {
          this.files.pic1.push(res.data);
          this.$message.success("上传成功");
        } else {
          this.files.pic1 = [];
          this.$message.error(res.msg);
        }
      },
      pic1UploadRemove(file) {
        this.files.pic1 = [];
      },
      pic2UploadSuccess(res, file) {
        this.files.pic2 = [];
        if(res.code == 200) {
          this.files.pic2.push(res.data);
          this.$message.success("上传成功");
        } else {
          this.files.pic2 = [];
          this.$message.error(res.msg);
        }
      },
      pic2UploadRemove(file) {
        this.files.pic2 = [];
      },
      pic3UploadSuccess(res, file) {
        this.files.pic3 = [];
        if(res.code == 200) {
          this.files.pic3.push(res.data);
          this.$message.success("上传成功");
        } else {
          this.files.pic3 = [];
          this.$message.error(res.msg);
        }
      },
      pic3UploadRemove(file) {
        this.files.pic3 = [];
      },
      pic4UploadSuccess(res, file) {
        this.files.pic4 = [];
        if(res.code == 200) {
          this.files.pic4.push(res.data);
          this.$message.success("上传成功");
        } else {
          this.files.pic4 = [];
          this.$message.error(res.msg);
        }
      },
      pic4UploadRemove(file) {
        this.files.pic4 = [];
      },
      pic1Preview(file) {
        console.log(file);
      }
    }
}

</script>
<style lang='less' scoped>
::v-deep .el-card {
  display: flex;
  flex-direction: column;

  .el-card__body {
    flex: 1;
    overflow-y: auto;
  }
}
::v-deep .form-inline {
  // padding-left: 10px;
  .el-form-item {
    display: block !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
    .el-form-item__label {
      height: 40px;
      font-weight: bold;
    }
    .el-form-item__content {
      display: block !important;
    }
    .el-select {
      width: 100%;
    }
    .el-textarea__inner {
      height: 100px;
    }
  }
  .avatar-uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 100%;
    }

    .el-upload:hover {
      border-color: #409EFF;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 100%;
      height: 178px;
      display: block;
    }
    .el-upload__tip {
      line-height: 20px;
    }
  }
}
.case-prompt {
  .prompt-content {
    margin-top: 20px;
    font-size: 14px;
    color: #303133;

    &:first-child {
      margin: 0;
    }
  }
  .prompt-title {
    font-weight: bolder;
    font-size: medium;
  }
  .prompt-item {
    margin: 5px 0;
    color: #606266;
  }
}
</style>