<template>
    <div class="container basic-container">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="基本信息" name="1">
                <info></info>
            </el-tab-pane>
            <el-tab-pane label="业务设置" name="2">
                <el-form
                    v-if="!loading"
                    :inline="true"
                    :model="storeForm"
                    class="form-inline"
                    label-position="right"
                    :rules="storeRules"
                    ref="storeForm"
                    style="width: 450px"
                >
                    <el-form-item label="承接区域" prop="serviceRange">
                        <el-radio-group v-model="storeForm.serviceRange"  disabled="true">
                            <el-radio
                                v-for="(radio, index) in dict.type.area_type"
                                :key="index"
                                :label="radio.value"
                            >{{ radio.label }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="服务类型" prop="serviceType">
                        <el-radio-group v-model="storeForm.serviceType"  disabled="true">
                            <el-radio
                                v-for="(radio, index) in dict.type.lbg_enterprise_service_type"
                                :key="index"
                                :label="radio.value"
                            >{{ radio.label }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="主营面积" prop="serviceCaseAreas">
                        <el-checkbox-group v-model="storeForm.serviceCaseAreas"   disabled="true" >
                            <el-checkbox 
                                v-for="(item, index) in dict.type.area_array"
                                :key="index"
                                :label="item.value"
                                name="type"
                            >{{ item.label }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="擅长项目" prop="serviceCaseTypes">
                        <el-checkbox-group v-model="storeForm.serviceCaseTypes"  disabled="true">
                            <el-checkbox
                                v-for="(item, index) in dict.type.lbg_project_type"
                                :key="index"
                                :label="item.value"
                                name="category"
                            >{{ item.label }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="施工方式" prop="serviceCates">
                        <el-checkbox-group v-model="storeForm.serviceCates"  disabled="true">
                            <el-checkbox
                                v-for="(item, index) in dict.type.lbg_decorate_type"
                                :key="index"
                                :label="item.value"
                                name="way"
                            >{{ item.label }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                    <el-form-item label="设计费用" required v-if="designMoney">
                        <el-col :span="11">
                            <el-form-item prop="designMoneyStart">
                                <el-input v-model="storeForm.designMoneyStart" disabled="true">
                                    <i slot="suffix" style="font-style:normal;margin-right: 10px;">元/m²</i>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2"  style="text-align: center;">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="designMoneyEnd">
                                <el-input v-model="storeForm.designMoneyEnd" disabled="true">
                                    <i slot="suffix" style="font-style:normal;margin-right: 10px;">元/m²</i>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="施工费用" required v-if="constructionMoney">
                        <el-col :span="11">
                            <el-form-item prop="constructionMoneyStart">
                                <el-input v-model="storeForm.constructionMoneyStart" disabled="true">
                                    <i slot="suffix" style="font-style:normal;margin-right: 10px;">元/m²</i>
                                </el-input>
                            </el-form-item>    
                        </el-col>
                        <el-col :span="2" style="text-align: center;">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="constructionMoneyEnd">
                                <el-input v-model="storeForm.constructionMoneyEnd" disabled="true">
                                    <i slot="suffix" style="font-style:normal;margin-right: 10px;">元/m²</i>
                                </el-input>
                            </el-form-item>    
                        </el-col>
                    </el-form-item>


                    <!-- <el-form-item label="营业时间" prop="workDay">
                        <div>
                            <el-radio-group v-model="storeForm.workDay"  disabled="true">
                                <el-radio
                                    v-for="(radio, index) in dict.type.business_hours"
                                    :key="index"
                                    :label="radio.value"
                                >{{ radio.label }}</el-radio>
                            </el-radio-group>
                        </div>
                        <el-time-picker
                            is-range
                            v-model="timeRange"
                            value-format="HH:mm" format="HH:mm"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围" 
                            disabled="true"
                            >
                        </el-time-picker>
                    </el-form-item> -->

                    <!-- <el-form-item style="text-align: center; margin-top: 20px;">
                        <el-button type="primary" @click="submitStoreForm('storeForm')">提交(可以重复提交)</el-button>
                    </el-form-item> -->
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="实名认证" name="3">
                <el-form :inline="true" :model="realForm" :rules="realRules" class="form-inline" label-position="right" ref="realForm">
                    <el-row :gutter="20" style="height: 340px">
                        <el-col :span="8">
                            <el-form-item label="营业执照">
                                <el-upload
                                    class="upload-uploader"
                                    :action="uploadFileUrl"
                                    :headers="headers"
                                    accept=".jpeg, .png, .jpg"
                                    :before-upload="handleBeforeUpload"
                                    :on-success="picUploadSuccess"
                                    :on-remove="picUploadRemove"
                                    :file-list="picFileList"
                                    :on-preview="picPreview"
                                >
                                    <img v-if="picFileList[0] && picFileList[0].url" :src="picFileList[0].url" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    <div slot="tip" class="el-upload__tip">仅支持jpg、jpeg、png格式，大小控制在3M以内</div>
                                </el-upload>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="法人身份证人像面">
                                <el-upload
                                    class="upload-uploader"
                                    :action="uploadFileUrl"
                                    :headers="headers"
                                    accept=".jpeg, .png, .jpg"
                                    :before-upload="handleBeforeUpload"
                                    :on-success="card1UploadSuccess"
                                    :on-remove="card1UploadRemove"
                                    :file-list="card1FileList"
                                    :on-preview="picPreview"
                                >
                                    <img v-if="card1FileList[0] && card1FileList[0].url" :src="card1FileList[0].url" class="avatar">
                                    <!-- <img src="../../assets/images/card-1.svg" alt=""> -->
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    <div slot="tip" class="el-upload__tip">仅支持jpg、jpeg、png格式，大小控制在3M以内</div>
                                </el-upload>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="法人身份证国徽面">
                                <el-upload
                                    class="upload-uploader"
                                    :action="uploadFileUrl"
                                    :headers="headers"
                                    accept=".jpeg, .png, .jpg"
                                    :before-upload="handleBeforeUpload"
                                    :on-success="card2UploadSuccess"
                                    :on-remove="card2UploadRemove"
                                    :file-list="card2FileList"
                                    :on-preview="picPreview"
                                >
                                    <img v-if="card2FileList[0] && card2FileList[0].url" :src="card2FileList[0].url" class="avatar">
                                    <!-- <img src="../../assets/images/card-1.svg" alt=""> -->
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    <div slot="tip" class="el-upload__tip">仅支持jpg、jpeg、png格式，大小控制在3M以内</div>
                                </el-upload>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-form-item label="社会信用代码号" prop="socialCreditCode">
                                <el-input v-model="realForm.socialCreditCode" placeholder="请输入社会信用代码号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="证件编码" prop="idNumber">
                                <el-input v-model="realForm.idNumber" placeholder="请输入证件编码"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="身份证有效期" prop="vaildDate">
                                <el-date-picker
                                    v-model="realForm.vaildDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item style="text-align: center; margin-top: 20px;">
                        <el-button type="primary" @click="submitRealForm('realForm')">提交</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="企业深度认证" name="4">
                <el-form :model="companyForm" class="form-inline" style="width: 350px">
                    <el-form-item label="员工人数">
                        <el-input v-model="companyForm.employeeNum" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="服务范围">
                        <el-radio-group v-model="companyForm.serviceRange">
                            <el-radio
                                disabled
                                v-for="(radio, index) in dict.type.service_range"
                                :key="index"
                                :label="radio.value"
                            >{{ radio.label }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                
                     <el-form-item label="办公厂房业务占比" prop="officeProportion1">
                        <el-input disabled
                                    v-model="companyForm.officeProportion1"
                                    placeholder="请输入办公厂房业务占比"
                                    clearable
                                    size="small" >
                            <template #suffix>
                            %
                            </template>
                        </el-input>
                        </el-form-item>
                        <el-form-item label="展览厅业务占比" prop="officeProportion2">
                        <el-input disabled
                                    v-model="companyForm.officeProportion2"
                                    placeholder="请输入展览厅业务占比"
                                    clearable
                                    size="small" >
                            <template #suffix>
                            %
                            </template>
                        </el-input>
                        </el-form-item>
                        <el-form-item label="医疗医美业务占比" prop="officeProportion3">
                        <el-input disabled
                                    v-model="companyForm.officeProportion3"
                                    placeholder="请输入医疗医美业务占比"
                                    clearable
                                    size="small" >
                            <template #suffix>
                            %
                            </template>
                        </el-input>
                        </el-form-item>
                        <el-form-item label="实验室业务占比" prop="officeProportion4">
                        <el-input disabled
                                    v-model="companyForm.officeProportion4"
                                    placeholder="请输入实验室业务占比"
                                    clearable
                                    size="small" >
                            <template #suffix>
                            %
                            </template>
                        </el-input>
                        </el-form-item>
                        <el-form-item label="其他业务占比" prop="officeProportion5">
                        <el-input disabled
                                    v-model="companyForm.officeProportion5"
                                    placeholder="请输入其他业务占比"
                                    clearable
                                    size="small" >
                            <template #suffix>
                            %
                            </template>
                        </el-input>
                        </el-form-item>
                        
                </el-form>
                <el-form :model="companyForm" class="form-inline" label-width="100px" label-position="left">
                    <el-form-item label="资质认证">
                        <div class="pic-list" v-if="companyForm.pic1 && companyForm.pic1.length">
                            <div class="list" v-for="(item, index) in companyForm.pic1" :key="index">
                                 <el-image
                                    style="width: 246px; height: 167px"
                                    :src="item.url"
                                />
                                <span class="demonstration">证书名称: {{ item.desc || '-' }}</span>
                                <span class="demonstration">证书名称: {{ item.code || '-' }}</span>
                            </div>
                        </div>
                        <div v-else>暂无图片</div>
                    </el-form-item>

                    <el-form-item label="荣誉证书">
                        <div class="pic-list" v-if="companyForm.pic2 && companyForm.pic2.length">
                            <div class="list" v-for="(item, index) in companyForm.pic2" :key="index">
                                 <el-image
                                    style="width: 246px; height: 167px"
                                    :src="item.url"
                                />
                                <span class="demonstration">证书名称: {{ item.desc || '-' }}</span>
                            </div>
                        </div>
                        <div v-else>暂无图片</div>
                    </el-form-item>

                    <el-form-item label="企业内部环境">
                        <div class="pic-list" v-if="companyForm.pic3 && companyForm.pic3.length">
                            <div class="list" v-for="(item, index) in companyForm.pic3" :key="index">
                                 <el-image
                                    style="width: 246px; height: 167px"
                                    :src="item.url"
                                />
                                <span class="demonstration">图片名称: {{ item.desc || '-' }}</span>
                            </div>
                        </div>
                        <div v-else>暂无图片</div>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="分公司设置" name="5">
                <div class="content">
                    <div class="table-handle" style="padding-bottom: 10px; text-align: right;">
                        <el-button size="small" type="primary" @click="editBranchRow()">添加分公司</el-button>
                    </div>
                    <el-table
                        v-if="tableHeight"
                        :data="branchData"
                        style="width: 100%"
                        :max-height="tableHeight"
                        v-loading="loading"
                    >
                        <el-table-column
                            prop="url"
                            label="logo"
                            width="200">
                            <template slot-scope="scope">
                                <el-image
                                style="width: 50px; height: 50px"
                                :src="$fileBaseUrl + scope.row.iconFileUrl"
                                fit="contain">
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="公司名称"
                            width="240">
                        </el-table-column>
                        <el-table-column
                            prop="caseName"
                            label="项目地址"
                            width="200"
                        >
                            <template slot-scope="scope">
                                {{ scope.row.province }}{{ scope.row.city }}{{ scope.row.district }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="详细地址"
                            width="auto"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="status"
                            label="操作"
                            width="150"
                            fixed="right"
                            align="center">
                                <template slot-scope="scope">
                                <el-button type="text" @click="editBranchRow(scope.row.id)">编辑</el-button>
                                <el-button type="text" @click="remove(scope.row.id)" class="text-gray">删除</el-button>
                                </template>
                        </el-table-column>
                    </el-table>
                    <pagination
                        v-show="total>0"
                        :total="total"
                        :page.sync="query.pageNum"
                        :limit.sync="query.pageSize"
                        @pagination="getBranchData"
                    />
                </div>
            </el-tab-pane>
            <el-tab-pane label="安全设置" name="6">
                <setting></setting>
            </el-tab-pane>
        </el-tabs>

        <el-dialog :title="branchForm.id ? '编辑分公司' : '添加分公司'" :visible.sync="branchDialogVisible" width="650px" :before-close="closeBranchDialog">
            <el-form v-loading="detailLoading" :model="branchForm" :rules="branchRules" ref="branchForm" label-width="100px" label-position="left">
                <el-form-item label="分公司logo" prop="iconFileId">
                    <el-upload
                        v-model="branchForm.iconFileId"
                        class="avatar-uploader"
                        :action="uploadFileUrl"
                        :headers="headers"
                        accept=".jpeg, .png, .jpg"
                        :show-file-list="false"
                        :on-success="handleBranchAvatarSuccess"
                        :on-change="handleBranchAvatarChange"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="branchForm.iconFileUrl" :src="branchForm.iconFileUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>

                    <div class="el-upload__tip">logo尺寸：200×200像素,仅支持jpg,PNG.jpeg图片文件</div>
                    <!-- <div style="visibility: hidden; position: absolute;"><el-input v-model="branchForm.iconFileId"></el-input></div> -->
                </el-form-item>

                <el-form-item label="公司全称" prop="name">
                    <el-input v-model="branchForm.name" placeholder="请输入公司全称"></el-input>
                </el-form-item>

                <el-form-item label="项目地址" prop="provinceId">
                    <el-col :span="8">
                        <el-select v-model="branchForm.provinceId" placeholder="请选择省" clearable @change="changeProvince">
                            <el-option
                                v-for="(item, index) in provinceList"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-col>
                    <el-col class="line" :span="1">&nbsp;</el-col>
                    <el-col :span="7">
                        <el-select v-model="branchForm.cityId" placeholder="请选择市" clearable @change="changeCity">
                            <el-option
                                v-for="(item, index) in cityList"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-col>
                    <el-col class="line" :span="1">&nbsp;</el-col>
                    <el-col :span="7">
                        <el-select v-model="branchForm.districtId" placeholder="请选择区" clearable>
                            <el-option
                                v-for="(item, index) in areaList"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-col>
                </el-form-item>
                <el-form-item label="详细地址" prop="address">
                    <el-input v-model="branchForm.address" placeholder="请输入详细地址"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="branchDialogVisible = false">取 消</el-button>
                <el-button type="primary" :disabled="saving" @click="submitBranchForm('branchForm')">确 定</el-button>
            </div>
        </el-dialog>

         <el-dialog
            title="图片预览"
            :visible.sync="previewVisible"
            width="60%"
            >
            <img :src="previewPath" class="previewImg" style="max-width: 100%; margin: 0 auto; display: block;"/>
        </el-dialog>


    </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import _ from 'lodash';
import info from './info'
import setting from './setting'
export default {
    name: 'company-basic',
    components: {
        info,
        setting
    },
    dicts: [
      'area_type',
      'area_array',
      'lbg_enterprise_service_type',
      'lbg_project_type',
      'lbg_decorate_type',
      'business_hours',
      'service_range',
    ],
    data () {
        return {
            uploadFileUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
            headers: {
                Authorization: "Bearer " + getToken()
            },
            activeName: '1',
            isEdit: true,
            loading: true,
            designMoney:false,
            constructionMoney:false,
            storeForm: {
                serviceCaseAreas: [], // 主营面积
                serviceCaseTypes: [], // 擅长项目
                serviceRange: '', // 承接区域
                serviceType: '', // 服务类型
                serviceCates: [], // 施工方式
                workDay: '',
                workTimeEnd: '',
                workTimeStart: '',
            },
            realForm: {
                socialCreditCode: '',
                idNumber: '',
                vaildDate: '',
                businessLicensePicFileId: '',
                idNumberPic1FileId: '',
                idNumberPic2FileId: ''
            },
            realRules: {
                socialCreditCode: [
                    { required: true, message: '请输入社会信用代码号', trigger: 'blur' },
                    { min: 18, max: 18, message: '输入长度在18位', trigger: 'blur' }
                ],
                idNumber: [
                    { required: true, message: '请输入证件编码', trigger: 'blur' },
                    { min: 18, max: 20, message: '输入长度18至20位', trigger: 'blur' }
                ],
                vaildDate: [
                    { required: true, message: '请选择身份证有效期', trigger: 'change' }
                ],
            },
            tableHeight: '',
            branchData: [],
            branchRules: {
                name: [
                    { required: true, message: '请输入公司全称', trigger: 'blur' }
                ],
                iconFileId: [
                    {
                        required: true, message: '请上传公司logo', trigger: 'change'
                    },
                ]
            },
            query: {
                pageNum: 1,
                pageSize: 10
            },
            provinceList: [],
            cityList: [],
            areaList: [],
            total: 0,
            branchForm: {
                iconFileUrl: '',
                iconFileId: '',
                name: '',
                provinceId: '',
                cityId: '',
                districtId: '',
                address: '',
            },
            detailLoading: false,
            branchDialogVisible: false,
            companyForm: {},
            timeRange: '',
            imageUrl: '',

            storeRules: {
                serviceCaseAreas: [
                    { type: 'array', required: true, message: '请选择主营面积', trigger: 'change' }
                ],
                serviceCaseTypes: [
                    { type: 'array', required: true, message: '请选择擅长项目', trigger: 'change' }
                ],
                serviceCates: [
                    { type: 'array', required: true, message: '请选择施工方式', trigger: 'change' }
                ],
                serviceRange: [
                    { required: true, message: '请选择承接区域', trigger: 'change' }
                ],
                serviceType: [
                    { required: true, message: '请选择服务类型', trigger: 'change' }
                ],
            },

            form: {},
            rules: {},
            saving: false,
            picFileList: [],
            card1FileList: [],
            card2FileList: [],
            previewVisible: false,
            previewPath: '',
        };
    },
    created() {
        this.handleClick();
    },
    mounted() {
    //   let searchFormHeight = this.$refs['header'].clientHeight + 20 // 可变的查询高度
      let pageHeight = document.documentElement.clientHeight // 可用窗口的高度
      this.tableHeight = Number(pageHeight - 140);
    },
    methods: {
        handleClick() {
            this.loading = true;
            switch(this.activeName) {
                case '1':
                    // this.getBasicInfo();
                    break;
                case '2':
                    this.getStoreInfo();
                    break;
                case '3':
                    this.getRealInfo();
                    break;
                case '4':
                    this.getCompanyInfo();
                    break;
                case '5':
                    this.getBranchData();
                    break;
                case '6':
                    // this.getSetData();
                    break;
                default:
                    break;
            }
        },
        // 获取省市区
        getProvinceList() {
            this.provinceList = [];
            this.$api.get('/lbg/province/list')
            .then(res => {
                if(res.code == 200) {
                    this.provinceList = res.rows;
                }
            })
        },
        getCityList(id) {
            this.cityList = [];
            this.$api.get('/lbg/city/list', {
                provinceId: id
            })
            .then(res => {
                if(res.code == 200) {
                    this.cityList = res.rows;
                }
            })
        },
        getAreaList(id) {
            this.areaList = [];
            this.$api.get('/lbg/district//list', {
                cityId: id
            })
            .then(res => {
                if(res.code == 200) {
                    this.areaList = res.rows;
                }
            })
        },
        changeProvince(id) {
            this.getCityList(id);
            this.branchForm.cityId = '';
            this.branchForm.districtId = '';
        },
        changeCity(id) {
            this.getAreaList(id);
            this.branchForm.districtId = '';
        },
        beforeAvatarUpload(file) {
            // 校检文件大小
            const isLt = file.size / 1024 / 1024 < 5;
            if (!isLt) {
                this.$message.error(`上传文件大小不能超过5MB!`);
                return false;
            }
        },


        // 获取业务设置
        getStoreInfo() {
            this.$api.get('/merchantApi/enterprise/info/enterpriseStoreServerInfo')
            .finally(() => {
                this.loading = false;
            })
            .then(res => {
                if(res.code == 200) {
                    if(res.data) {
                        let data = res.data;
                        data.serviceCaseAreas = data.serviceCaseAreas ? data.serviceCaseAreas.split(',') : [];
                        data.serviceCaseTypes = data.serviceCaseTypes ? data.serviceCaseTypes.split(',') : [];
                        data.serviceCates = data.serviceCates ? data.serviceCates.split(',') : [];

                        data.serviceRange = String(data.serviceRange);
                        data.serviceType = String(data.serviceType);
                        data.workDay = String(data.workDay);
                        this.storeForm = data;
                        this.timeRange = [data.workTimeStart, data.workTimeEnd];

                         console.log(data.serviceType);
                         if("2"=== data.serviceType){
                            this.designMoney=true;
                            this.constructionMoney=false;
                        }else if("3"=== data.serviceType){
                            this.designMoney=false;
                            this.constructionMoney=true;
                        }else{
                            this.designMoney=true;
                            this.constructionMoney=true;
                        }

                    }
                }
            })
        },
         // 提交业务设置
        submitStoreForm(formName) {
            if(this.saving) {
                return false;
            }
            let form = _.cloneDeep(this.storeForm);
            for(let i in form) {
                if(Array.isArray(form[i])) {
                    form[i] = form[i].join();
                }
            }
            if(Array.isArray(this.timeRange) && this.timeRange.length) {
                form.workTimeStart = this.timeRange[0];
                form.workTimeEnd = this.timeRange[1];
            }
            // console.log(form);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.saving = true;
                    if(this.storeForm.id) {
                        this.$api.put('/merchantApi/enterprise/info/enterpriseStoreServerInfo', form)
                        .finally(() => {
                            this.saving = false;
                        })
                        .then(res => {
                            if(res.code == 200) {
                                this.$message.success(res.msg);
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    } else {
                        this.$api.post('/merchantApi/enterprise/info/enterpriseStoreServerInfo', form)
                        .finally(() => {
                            this.saving = false;
                        })
                        .then(res => {
                            if(res.code == 200) {
                                this.$message.success(res.msg);
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getTimeRange() {
            this.timeRange = ['00:00', '23:59'];
        },

        // 获取实名认证
        getRealInfo() {
            this.$api.get('/merchantApi/enterprise/info/enterpriseCertInfo')
            .finally(() => {
                this.loading = false;
            })
            .then(res => {
                if(res.code == 200) {
                    if(res.data) {
                        this.realForm = res.data;
                    }
                }
            })
        },
        // 提交实名认证
        submitRealForm(formName) {
            if(this.saving) {
                return false;
            }
            let form = _.cloneDeep(this.realForm);
            if(this.picFileList.length) {
                form.businessLicensePicFileId = this.picFileList[0].fileId;
            }
            if(this.card1FileList.length) {
                form.idNumberPic1FileId = this.card1FileList[0].fileId;
            }
            if(this.card2FileList.length) {
                form.idNumberPic2FileId = this.card2FileList[0].fileId;
            }
            // console.log(form);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.saving = true;
                    if(this.realForm.id) {
                        this.$api.put('/merchantApi/enterprise/info/enterpriseCertInfo', form)
                        .finally(() => {
                            this.saving = false;
                        })
                        .then(res => {
                            if(res.code == 200) {
                                this.$message.success(res.msg);
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    } else {
                        this.$api.post('/merchantApi/enterprise/info/enterpriseCertInfo', form)
                        .finally(() => {
                            this.saving = false;
                        })
                        .then(res => {
                            if(res.code == 200) {
                                this.$message.success(res.msg);
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        picPreview(file) {
            // console.log(file);
            this.previewVisible = true;
            this.previewPath = file.url;
        },
        handleBeforeUpload(file) {
            // 校检文件大小
            const isLt = file.size / 1024 / 1024 < 3;
            if (!isLt) {
                this.$message.error(`上传文件大小不能超过3MB!`);
                return false;
            }
        },
        picUploadSuccess(res) {
            this.picFileList = [];
            if(res.code == 200) {
                this.picFileList.push(res.data);
                this.$message.success("上传成功");
            } else {
                this.picFileList = [];
                this.$message.error(res.msg);
            }
        },
        card1UploadSuccess(res) {
            this.card1FileList = [];
            if(res.code == 200) {
                this.card1FileList.push(res.data);
                this.$message.success("上传成功");
            } else {
                this.card1FileList = [];
                this.$message.error(res.msg);
            }
        },
        card2UploadSuccess(res) {
            this.card2FileList = [];
            if(res.code == 200) {
                this.card2FileList.push(res.data);
                this.$message.success("上传成功");
            } else {
                this.card2FileList = [];
                this.$message.error(res.msg);
            }
        },
        picUploadRemove() {
            this.picFileList = [];
            this.form.businessLicensePicFileId = '';
        },
        card1UploadRemove() {
            this.card1FileList = [];
            this.form.idNumberPic1FileId = '';
        },
        card2UploadRemove() {
            this.card2FileList = [];
            this.form.idNumberPic2FileId = '';
        },

        // 获取深度认证
        getCompanyInfo() {
            this.$api.get('/merchantApi/enterprise/info/enterpriseDeepCertInfo')
            .finally(() => {
                this.loading = false;
            })
            .then(res => {
                if(res.code == 200) {
                    if(res.data) {
                        let data = res.data;
                        this.companyForm = data;
                    }
                }
            })
        },

        // 获取分公司列表
        getBranchData() {
            this.loading = true;
            this.$api.get('/merchantApi/enterprise/info/list', {
                ...this.query
            })
            .finally(() => {
                this.loading = false;
            })
            .then(res => {
                if(res.code == 200) {
                    this.branchData = res.rows;
                    this.total = res.total;
                }
            })
        },
        // 添加编辑 分公司
        editBranchRow(id) {
            this.branchDialogVisible = true;
            this.getProvinceList();
            if(id) {
                this.detailLoading = true;
                this.$api.get('/merchantApi/enterprise/info/'+id, {})
                .finally(() => {
                    this.detailLoading = false;
                })
                .then(res => {
                    if(res.code == 200) {
                        this.branchForm = res.data;
                        this.branchForm.iconFileUrl = this.$fileBaseUrl + this.branchForm.iconFileUrl;
                        if(this.branchForm.provinceId) {
                            this.getCityList(this.branchForm.provinceId);
                        }
                        if(this.branchForm.cityId) {
                            this.getAreaList(this.branchForm.cityId);
                        }
                    }
                })
            }

        },

        submitBranchForm(formName) {
            if(this.saving) {
                return false;
            }
            let form = _.cloneDeep(this.branchForm);
            // console.log(form);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.saving = true;
                    if(this.branchForm.id) {
                        this.$api.put('/merchantApi/enterprise/info', form)
                        .finally(() => {
                            this.saving = false;
                        })
                        .then(res => {
                            if(res.code == 200) {
                                this.$message.success(res.msg);
                                this.closeBranchDialog();
                                this.getBranchData();
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    } else {
                        this.$api.post('/merchantApi/enterprise/info', form)
                        .finally(() => {
                            this.saving = false;
                        })
                        .then(res => {
                            if(res.code == 200) {
                                this.$message.success(res.msg);
                                this.closeBranchDialog();
                                this.getBranchData();
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 分公司logo
        handleBranchAvatarSuccess(res) {},
        handleBranchAvatarChange(res) {
            if(res.response) {
                if(res.response.code == 200) {
                    this.branchForm.iconFileId = res.response.data.fileId;
                    this.branchForm.iconFileUrl = res.response.data.url;
                    this.$message.success("上传成功");
                }else {
                    this.branchForm.iconFileId = '';
                    this.branchForm.iconFileUrl = '';
                    this.$message.error(res.msg);
                }
            }
        },
        // 关闭弹窗清空表单
        closeBranchDialog() {
            this.$refs['branchForm'].resetFields();
            this.$refs['branchForm'].clearValidate();
            this.branchForm.cityId = '';
            this.branchForm.districtId = '';
            this.branchForm.iconFileUrl = '';
            this.branchDialogVisible = false;
        },
        // 删除分公司
        remove(id) {
            let params = [];
            if(id) {
                // 单个删除
                params.push(id);
            } else {
            // 多个删除
                let ref = this.$refs.multipleTable;
                if(ref.selection && ref.selection.length) {
                    ref.selection.map(item => {
                        params.push(item.id);
                    })
                } else {
                    this.$message({
                        message: '没有选中数据',
                        type: 'warning'
                    });
                    return false;
                }
            }
            this.$confirm('是否确认删除？')
            .then( res => {
                this.$api.delete('/merchantApi/enterprise/info/'+params.join()).then(res => {
                    this.getBranchData();
                })
                done();
            })
            .catch( res => {});
        },

    }
}

</script>
<style lang='less' scoped>

::v-deep .el-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;

    .el-tabs__content {
        height: 100%;
        overflow-y: scroll;
    }
}
::v-deep .form-inline {
  // padding-left: 10px;
  .el-form-item {
    display: block !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
    .el-form-item__label {
      height: 40px;
      font-weight: bold;
    }
    .el-form-item__content {
      display: block !important;
    }
    .el-select {
      width: 100%;
    }
    .el-textarea__inner {
      height: 100px;
    }
    .el-range-separator {
        width: 30px;
    }
    .el-date-editor {
        width: 100%;
    }
  }
  .upload-uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 180px;
    }

    .el-upload:hover {
      border-color: #409EFF;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 100%;
      height: 178px;
      display: block;
    }
    .el-upload__tip {
      line-height: 20px;
    }
  }
}
.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 60px;
    height: 60px;
}
.avatar-uploader:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}
.avatar {
    width: 60px;
    height: 60px;
    display: block;
}
.pic-list {
    display: flex;

    .list {
        display: flex;
        flex-direction: column;
    }
}
::v-deep .set-dialog {
    .el-tabs__nav-wrap::after {
        display: none;
    }
    .el-tabs__nav-scroll {
        display: flex;
        justify-content: center;
    }
    .set-ruleForm {
        width: 85%;
        margin: 0 auto;

        .input-code {
            display: flex;
        }
        .code-btn {
            margin-left: 20px;
        }
    }
    .el-dialog__body {
        padding: 30px 20px 10px;
    }
    .dialog-footer {
        width: 85%;
        margin: 0 auto;
    }
}

</style>
