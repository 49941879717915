<template>
  <el-dialog
    :destroy-on-close="true"
    title="新增管理体系认证认可"
    :visible.sync="visible"
    width="1000px"
    :before-close="handleClose">
    <div style="padding: 0 60px">
      <el-row>
        <el-form ref="form" :model="form" label-width="80px">
          <el-col :span="12">
              <el-form-item label="证书名称">
                <el-input v-model="form.name" placeholder="请填写"></el-input>
              </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证书编号">
              <el-input v-model="form.num" placeholder="请填写"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-col :span="12">
              <el-form-item label="发证日期">
                <el-date-picker type="date" placeholder="选择日期" v-model="form.startTime" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="有效期">
                <el-date-picker type="date" placeholder="选择日期" v-model="form.endTime" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="12">
              <el-form-item label="颁奖机构">
                <el-input v-model="form.issuingAuthority" placeholder="请填写"></el-input>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="12">
              <el-form-item label="证书图片">
                <MyUpload ref="MyUpload"></MyUpload>
              </el-form-item>
            </el-col>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="onSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import MyUpload from '@/components/MyUpload/index.vue'
export default {
  components:{MyUpload},
  data() {
    return {
      visible: false,
      form:{},
    }
  },//data
  methods: {
    handleClose() {
      this.form = {}
      this.visible = false
    },
    edit(id){
      this.visible = true
      this.$api.get(`/merchantApi/enterpriseAtteZz/${id}`).then(res=>{
        this.form = res.data
        res.data.fileList.forEach(e=>{
          this.$refs.MyUpload.imageList.push({
            name:e.fileUrl,
            fileId:e.fileId
          })
        })
      })
    },
    onSubmit(){
      let fileList = []
      this.$refs.MyUpload.imageList.forEach(e=>{
        fileList.push({
          fileUrl:e.url,
          fileId:e.fileId
        })
      })
      this.form.fileList = fileList
      this.form.type = 'gltx'
      if(!this.form.id){
        this.$api.post('/merchantApi/enterpriseAtteZz',{...this.form}).then(res=>{
          if(res.code == 200){
            this.visible = false
            this.$message.success(res.msg)
            this.$emit('ok')
          }else{
            this.visible = false
            this.$message.error(res.msg)
          }
        })
      }else{
        this.$api.put('/merchantApi/enterpriseAtteZz',{...this.form}).then(res=>{
          if(res.code == 200){
            this.visible = false
            this.$message.success(res.msg)
            this.$emit('ok')
          }else{
            this.visible = false
            this.$message.error(res.msg)
          }
        })
      }

    },
  },//methods
}
</script>

<style scoped>

</style>
