import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './element-css/index.css'
import router from './router'
import './permission' // permission control
import Api from './api/index.js';
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
Vue.prototype.$api = Api;
import store from './store'
import App from './App.vue'



// 字典标签组件
import DictTag from '@/components/DictTag'
// 字典数据组件
import DictData from '@/components/DictData'
// 富文本组件
import Editor from "@/components/Editor"
// 分页组件
import Pagination from "@/components/Pagination";

import { parseTime, resetForm } from "@/utils/ruoyi";
import SlideVerify from 'vue-monoplasty-slide-verify';

Vue.use(SlideVerify);

// 全局方法挂载
Vue.prototype.resetForm = resetForm
Vue.prototype.parseTime = parseTime


if(process.env.NODE_ENV === 'production'){
  Vue.prototype.$fileBaseUrl = 'https://libangongoss.oss-cn-shanghai.aliyuncs.com/prod'
}else{
  Vue.prototype.$fileBaseUrl = 'https://libangongoss.oss-cn-shanghai.aliyuncs.com/test'
}

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.component('DictTag', DictTag)
Vue.component('Editor', Editor)
Vue.component('Pagination', Pagination)
DictData.install()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
