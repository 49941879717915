<template>
    <div v-loading="loading">
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
          prop="signNum"
          label="合同编号" 
         
        />
        <el-table-column
          prop="createTime"
          label="合同类型"
        >
          <template slot-scope="scope">
            <dict-tag
              :options="dict.type.contract_type"
              :value="scope.row.contractType"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="signTime"
          label="签约时间" 
          width="200px"       
        />
        <el-table-column
          prop="contractAmount"
          label="签约金额"
        >
        </el-table-column>
        <el-table-column
          prop="createAccountId"
          label="提交者"
        >
        </el-table-column>
        <el-table-column
          prop="businessStartTime"
          label="开工日期"
        >
        </el-table-column>
        <el-table-column
          prop="businessFinishTime"
          label="完工日期"
        />
        <el-table-column
          prop="processTime"
          label="审核状态"
        >
          <template slot-scope="scope">  
            <dict-tag
              :options="dict.type.sign_record_check_status"
              :value="scope.row.checkStatus"
            />        
          </template>
        </el-table-column>
      </el-table>

      <pagination
          v-show="total>0"
          :total="total"
          :page.sync="query.pageNum"
          :limit.sync="query.pageSize"
          @pagination="getContractList"
      />
    </div>
</template>

<script>
export default {
    name: 'contract',
    components: {},
    dicts: [
      'contract_type',
      'sign_record_check_status',
    ],
    props: ['businessId'],
    data () {
      return {
        loading: true,
        tableData: [],
        total: 0,
        query: {
          pageNum: 1,
          pageSize: 10
        },
      };
    },
    created() {
      this.getContractList();
    },
    methods: {
      getContractList() {
        this.loading = true;
        this.$api.get('/merchantApi/businessOpportunity/getSignContractList', {
          businessOpportunityRefId: this.businessId
        })
        .finally(() => {
          this.loading = false;
        })
        .then(res => {
          if(res.code = 200) {
            this.tableData = res.rows;
            this.total = res.total;
          }
        })
      }
    }
}

</script>
<style lang='less' scoped>
.el-descriptions {
  margin-bottom: 20px;
}
</style>