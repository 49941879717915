<template>
    <div class="detail-container">
      <el-card class="card-left">
        <div slot="header" class="clearfix">
          <span>{{ caseId ? '编辑案例' : '添加案例' }}</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="back">返回</el-button>
        </div>
        <el-form v-if="!loading" :inline="true" :model="form" class="form-inline" label-position="right" :rules="rules" ref="ruleForm">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item prop="caseName">
                <template slot="label">
                  案例名称
                  <el-popover
                    placement="top-start"
                    title="标题"
                    trigger="hover"
                    width="500"
                  >
                    <i class="el-icon-question" slot="reference"></i>
                    <template>
                      1. 标题字数在10-20字<br />
                      2. 建议可以从【空间类型】【面积】【企业名称】【设计亮点】这几点关键信息来写标题
                    </template>
                  </el-popover>
                </template>
                <el-input v-model="form.caseName" placeholder="请输入项目名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="地址" prop="address">
                <el-input v-model="form.address" placeholder="请输入项目地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所属行业" prop="industryType">
                  <el-select v-model="form.industryType" placeholder="请选择所属行业" clearable>
                    <el-option
                      v-for="(item, index) in dict.type.firm_trade"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            
            <el-col :span="8">
              <el-form-item label="项目造价" prop="spendMoney">
                <el-input type="number" placeholder="请输入项目造价" v-model="form.spendMoney">
                  <template slot="append">万元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="项目面积" prop="caseArea">
                <el-input type="number" placeholder="请输入项目面积" v-model="form.caseArea">
                  <template slot="append">㎡</template>
                </el-input>
              </el-form-item>
            </el-col>

            <!-- <el-col :span="8">
              <el-form-item label="项目属性" prop="caseCate">
                  <el-select v-model="form.caseCate" placeholder="请选择项目属性">
                    <el-option
                      v-for="(item, index) in dict.type.lbg_request_type"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
              </el-form-item>
            </el-col> -->
          </el-row>

          <el-row :gutter="20">
             <el-col :span="8">
              <el-form-item label="项目类型" prop="type">
                  <el-select v-model="form.type" placeholder="请选择项目类型" clearable @change="changeType">
                    <el-option
                      v-for="(item, index) in caseCategory"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
              </el-form-item>
            </el-col>
           
            <el-col :span="8">
              <el-form-item label="图片类型" prop="imageType">
                  <el-select v-model="form.imageType" placeholder="请选择图片类型" clearable >
                    <el-option
                      v-for="(item, index) in dict.type.image_type"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
              </el-form-item>
            </el-col>

             <el-col :span="8">
              <el-form-item label="推荐类型" prop="isRecommend">
                  <el-select v-model="form.isRecommend" placeholder="请选择推荐类型" clearable disabled>
                    <el-option
                      v-for="(item, index) in dict.type.case_is_recommend"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
              </el-form-item>
            </el-col>

          </el-row>

          <el-row :span="24" v-if="caseCategories.id">
            <el-col>
              <el-form-item :label="caseCategories.name+'（单选）'" prop="type1">
                <el-radio-group v-model="form.type1" size="medium" v-if="categories.length">
                  <el-radio-button
                    v-for="(item, index) in categories"
                    :key="index"
                    :label="item.id"
                  >{{ item.name }}</el-radio-button>
                </el-radio-group>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
                <el-form-item>
                  <template slot="label">
                    案例封面
                    <el-popover
                      placement="top-start"
                      title="封面"
                      trigger="hover"
                      width="400"
                    >
                      <i class="el-icon-question" slot="reference"></i>
                      <template>
                        1. 封面照片滤镜清淡，光线自然，构图方正。<br />
                        2. 图片空间整洁，视角开阔，非户型图。<br />
                        3. 展示最能够体现案例装修亮点的空间。
                      </template>
                    </el-popover>
                  </template>
                  <el-upload
                    :action="uploadFileUrl"
                    :headers="headers"
                    accept=".jpeg, .png, .jpg"
                    list-type="picture-card"
                    :on-preview="picPreview"
                    :before-upload="handleBeforeUpload"
                    :on-success="handleCoverUploadSuccess"
                    :on-remove="handleCoverUploadRemove"
                    :file-list="coverFileList"
                  >
                    <i class="el-icon-plus"></i>
                    <div slot="tip" class="el-upload__tip">请上传 大小不超过 5MB 格式为 png/jpg/jpeg 的文件</div>
                  </el-upload>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <!-- <el-col :span="24">
              <el-form-item label="团队设置">
                  <el-button icon="el-icon-plus" circle @click="showDialog"></el-button>
              </el-form-item>
            </el-col> -->
            <el-col :span="24">
              <el-form-item label="案例详情" prop="caseDesc">

                <template slot="label">
                    案例详情
                    <i class="el-icon-question" slot="reference"></i>
                </template>
                <div class="description-box">
                  <p class="title">上传要求</p>
                  <p>① 图片类型：实景图优先，效果图次之 </p>
                  <p>② 尺寸要求：宽度不小于1050px，高度不限；大小要求：单张不超过5M；格式要求：png 或 jpg或 jpeg </p>
                  <p>③ 不带水印 </p>
                  <p>④ 案例图建议上传顺序：门厅（前台、等候/洽谈区）——展示区（logo墙）—员工办公区—经理室独立办公室—董事长总经理室—会议空间—文印区—茶水间—休闲区</p>
                </div>
<!--                <el-input-->
<!--                  type="textarea"-->
<!--                  v-model="form.caseDesc"-->
<!--                  placeholder="请添加导语内容（可以描述业主需求和期望，设计师的设计理念）"-->
<!--                  maxlength="150"-->
<!--                  show-word-limit-->
<!--                  resize="none"-->
<!--                ></el-input>-->
<!--                <Editor :minHeight="200" v-model="form.caseDesc"></Editor>-->
                <WangEditor v-model="form.caseDesc"></WangEditor>
              </el-form-item>
            </el-col>
          </el-row>



          <!-- <el-row>
            <el-col :span="24">
              <el-form-item label="效果图(多张)">
                <template slot="label">
                    效果图(多张)
                    <el-popover
                      placement="top-start"
                      title="效果图"
                      trigger="hover"
                      width="400"
                    >
                      <i class="el-icon-question" slot="reference"></i>
                      <template>
                        *图片尺寸不低于500*500px，格式可为jpg，png,<br />
                        图片文件大小不超过15M，横图宽高比不超过16:9，竖图高宽比不超过16:9<br />
                        1. 图片分辨率高，无黑边白边，不使用局部照片<br />
                        2. 必须包含平面方案图，且需标出空间名称<br />
                      </template>
                    </el-popover>
                  </template>
                <el-upload
                  :action="uploadFileUrl"
                  :before-upload="handleBeforeUpload"
                  :headers="headers"
                  multiple
                  accept=".jpeg, .png, .jpg"
                  list-type="picture-card"
                  :on-preview="picPreview"
                  :on-success="handleUploadSuccess"
                  :on-remove="handleUploadRemove"
                  :file-list="fileList">
                  <i class="el-icon-plus"></i>
                  <div slot="tip" class="el-upload__tip">请上传 大小不超过 5MB 格式为 png/jpg/jpeg 的文件</div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row> -->

          <el-form-item style="text-align: right;">
            <el-button @click="back">取消</el-button>
            <!-- <el-button @click="save" type="primary" plain>仅保存</el-button> -->
            <el-button type="primary" :disabled="saving" @click="submitForm('ruleForm')">提交审核</el-button>
          </el-form-item>
        </el-form>
      </el-card>

      <!-- <el-card class="card-right">
        <div slot="header" class="clearfix">
          <span>案例创作攻略</span>
        </div>
        <div class="case-prompt">
            <div class="prompt-content">
            <div class="prompt-title">一.封面</div>
              <div class="prompt-item">1. 封面照片滤镜清淡，光线自然，构图方正。</div>
              <div class="prompt-item">2. 图片空间整洁，视角开阔，非户型图。</div>
              <div class="prompt-item">3. 展示最能够体现案例装修亮点的空间，更能睛</div>
            </div>
            <div class="prompt-content">
              <div class="prompt-title">二.标题</div>
              <div class="prompt-item">1. 标题字数在10-20字</div>
              <div class="prompt-item">
                2.
                建议可以从【空间类型】【面积】【企业名称】【设计亮点】这几点关键信息来写标题
              </div>
              <div class="prompt-item"></div>
            </div>
            <div class="prompt-content">
                <div class="prompt-title">三.方案说明</div>
                <div class="prompt-item">1. 导语字数≥50字</div>
                <div class="prompt-item">2. 禁止出现灌水、广告、图文无关等情况</div>
                <div class="prompt-item">3. 描述项目背景、客户需求、设计思路</div>
            </div>
            <div class="prompt-content">
                <div class="prompt-title">四.空间分类</div>
                <div class="prompt-item">
                  1. 办公类案例必须包含前厅、办公区，选择包含会议室，独立办公室其中1个空间
                </div>
                <div class="prompt-item">2. 建议空间数≥3个，能够全面展示整体装修情况</div>
                <div class="prompt-item"></div>
            </div>
            <div class="prompt-content">
                <div class="prompt-title">五.图片</div>
                <div class="prompt-item">
                  *图片尺寸不低于500*500px，格式可为jpg，png。图片文件大小不超过15M，横图宽高比不超过16:9，竖图高宽比不超过16:9
                </div>
                <div class="prompt-item">1. 图片分辨率高，无黑边白边，不使用局部照片</div>
                <div class="prompt-item">2. 必须包含平面方案图，且需标出空间名称，</div>
            </div>
            <div class="prompt-content">
                <div class="prompt-title">六.文案</div>
                <div class="prompt-item">1. 禁止灌水、广告、图文无关等情况</div>
                <div class="prompt-item">2. 每个主空间（前厅、办公区）都有一处描述</div>
                <div class="prompt-item">
                  3.
                  文字描述与本案例相关，建议可以描述设计思路、颜色搭配技巧、材料使用情况、空间动线处理等，具有参考价值的案例更容易被收藏哦
                </div>
            </div>
        </div>
      </el-card> -->

      <el-dialog title="选择团队人员" :visible.sync="dialogTableVisible">
        <el-table :data="gridData">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column property="head" label="头像" width="120"></el-table-column>
          <el-table-column property="name" label="姓名" width="120"></el-table-column>
          <el-table-column property="job" label="职称"></el-table-column>
          <el-table-column
            align="right">
            <template #header>
              <el-input
                v-model="memberName"
                size="mini"
                @change="search"
                placeholder="输入关键字搜索,回车查询"/>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page.sync="page"
          :page-size="size"
          layout="total, prev, pager, next"
          :total="total">
        </el-pagination>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogTableVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogTableVisible = false">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog
        title="图片预览"
        :visible.sync="previewVisible"
        width="60%"
        >
          <img :src="previewPath" class="previewImg" style="max-width: 100%; margin: 0 auto; display: block;"/>
      </el-dialog>

    </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import WangEditor from '@/components/WangEditor/index.vue'
export default {
    name: 'case-add',
    components: {WangEditor},
    dicts: [
      'approval_status',
      'case_is_recommend',
      'firm_trade',
      'area_array',
      'lbg_project_type',
      'lbg_request_type',
      'image_type',
    ],
    data () {
      return {
        caseId: '',
        uploadFileUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
        headers: {
          Authorization: "Bearer " + getToken()
        },
        fileSize: 5,
        videoForm: {},
        videoFlag: false,
        videoUploadPercent: 0,
        saving: false,
        loading: true,
        form: {
          caseName: '',
          caseArea: '',
          industryType: '',
          caseType: '',
          caseCate: '',
          caseDesc: '',
          spendMoney: '',
          isRecommend: '0',
          address: '',
          coverFileId: '',
          storeCasePic3List: [],
          type: '',
          type1: '',
          imageType:'',
        },
        rules: {
          caseName: [
            { required: true, message: '请输入案例名称', trigger: 'blur' }
          ],
          address: [
            { required: true, message: '请输入项目地址', trigger: 'blur' }
          ],
          caseDesc: [
            { required: true, message: '请输入项目描述', trigger: 'blur' }
          ],
          industryType: [
            { required: true, message: '请选择所属行业', trigger: 'change' }
          ],
          type: [
            { required: true, message: '请选择项目类型', trigger: 'change' }
          ],
          type1: [
            { required: true, message: '请至少选择1个分类', trigger: 'change' }
          ],
          caseCate: [
            { required: true, message: '请选择项目属性', trigger: 'change' }
          ],
          imageType: [
            { required: true, message: '请选择图片类型', trigger: 'change' }
          ],
        },
        caseCategory: [], // 一级
        caseCategories: {}, // 二级
        categories: [], // 三级
        coverFileList: [],
        fileList: [], // 效果图反显
        dialogTableVisible: false,
        gridData: [],
        page: 1,
        size: 20,
        total: 0,
        memberName: '',
        previewVisible: false,
        previewPath: '',
      };
    },
    created() {
      this.caseId = this.$route.query.id || '';
      if(this.caseId) {
        this.getDetails();
      } else {
        this.getCaseCategory();
        this.loading = false;
      }
    },
    methods: {
      getDetails() {
        this.$api.get('merchantApi/storeCase/'+this.caseId, {
        }).then(res => {
          if(res.code == 200) {
            this.form = res.data;

            if(this.form.coverFileUrl) {
              this.coverFileList = [{
                fileId: this.form.coverFileId,
                url: this.$fileBaseUrl+this.form.coverFileUrl
              }];
            }

            if(this.form.storeCasePic3List && this.form.storeCasePic3List.length) {
              this.form.storeCasePic3List.map(item => {
                let obj = {
                  fileId: item.picFileId,
                  url: this.$fileBaseUrl+item.picFileUrl
                };
                this.fileList.push(obj);
              })
            }

            this.getCaseCategory();
            this.getCaseCategory(this.form.type);

            this.loading = false;
          }
        })
      },
      async getCaseCategory(id) {
        if(id) {
          // 获取二级分类和三级
         await this.$api.get('merchantApi/storeCaseCategory/getInfoByParentId/'+id, {
          }).then(res => {
            if(res.code == 200) {
              if(res.data.length){
                this.caseCategories = res.data[0]; // 二级第一个
                this.$api.get('merchantApi/storeCaseCategory/getInfoByParentId/'+this.caseCategories.id, {
                }).then(res => {
                  if(res.code == 200) {
                    this.categories = res.data; // 三级
                  }
                })
              }
            }
          })
        } else {
          // 获取一级分类
          await this.$api.get('merchantApi/storeCaseCategory/getInfoByParentId/0', {
          }).then(res => {
            if(res.code == 200) {
              this.caseCategory = res.data;
            }
          })
        }
        
      },
      changeType(value) {
        this.form.type1 = ''
        if(value) {
          this.getCaseCategory(value);
        } else {
          this.caseCategories = {};
          this.categories = [];
        }
      },
      back() {
        this.$router.back();
      },
      save() {},
      submitForm(formName) {
        if(this.saving) {
          return false;
        }
        let form = Object.assign(this.form, {});

        if(this.coverFileList.length) {
          form.coverFileId = this.coverFileList[0].fileId
        }
        console.log(form);
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saving = true;

            if(this.caseId) {
              this.$api.put('/merchantApi/storeCase', form)
              .finally(() => {
                this.saving = false;
              })
              .then(res => {
                if(res.code == 200) {
                  this.$message.success(res.msg);
                  this.back();
                } else {
                  this.$message.error(res.msg);
                }
              })
            } else {
              this.$api.post('/merchantApi/storeCase', form)
              .finally(() => {
                this.saving = false;
              })
              .then(res => {
                if(res.code == 200) {
                  this.$message.success(res.msg);
                  this.back();
                } else {
                  this.$message.error(res.msg);
                }
              })
            }

          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      showDialog() {
        this.dialogTableVisible = true;
      },
      search() {
        console.log(this.memberName);
      },
      handleVideoSuccess() {
        this.videoFlag = false;
        this.videoUploadPercent = 0;
        if(res.status == 200){
            this.videoForm.videoUploadId = res.data.uploadId;
            this.videoForm.Video = res.data.uploadUrl;
        }else{
            this.$message.error('视频上传失败，请重新上传！');
        }
      },
      beforeUploadVideo(file) {
        const isLt10M = file.size / 1024 / 1024  < 10;
        if (['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb'].indexOf(file.type) == -1) {
            this.$message.error('请上传正确的视频格式');
            return false;
        }
        if (!isLt10M) {
            this.$message.error('上传视频大小不能超过10MB哦!');
            return false;
        }
      },
      uploadVideoProcess(file) {
        this.videoFlag = true;
        this.videoUploadPercent = file.percentage.toFixed(0);
      },
      handlePictureCardPreview() {},
      handleCoverUploadRemove(file, fileList) {
        this.coverFileList = [];
      },
      handleUploadRemove(file, fileList) {
        console.log(file);
        let index = this.form.storeCasePic3List.findIndex(x => x.picFileId === file.fileId);
        // console.log(index);
        if(index != -1) {
          // this.fileList.splice(index, 1);
          this.form.storeCasePic3List.splice(index, 1);
        }
        console.log(this.form.storeCasePic3List);
      },
      handleBeforeUpload(file) {
        // 校检文件大小
        if (this.fileSize) {
          const isLt = file.size / 1024 / 1024 < this.fileSize;
          if (!isLt) {
            this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
            return false;
          }
        }
      },
      handleCoverUploadSuccess(res, file, fileList) {
        this.coverFileList = [];
        if(res.code == 200) {
          this.coverFileList.push(res.data);
          // this.form.coverFileId = res.data.fileId;
          this.$message.success("上传成功");
        } else {
          this.coverFileList = [];
          this.$message.error(res.msg);
        }
      },
      handleUploadSuccess(res, file, fileList) {
        if(res.code == 200) {
          let obj = { picFileId: res.data.fileId };
          this.form.storeCasePic3List.push(obj);
        } else {
          this.$message.error(res.msg);
        }
      },
      picPreview(file) {
        // console.log(file);
        this.previewVisible = true;
        this.previewPath = file.url;
      },
    }
}

</script>
<style lang='less' scoped>
::v-deep .el-card {
  display: flex;
  flex-direction: column;

  .el-card__body {
    flex: 1;
    overflow-y: auto;
  }
}
::v-deep .form-inline {
  .el-form-item {
    display: block !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
    .el-form-item__label {
      font-weight: bold;
    }
    .el-form-item__content {
      display: block !important;
    }
    .el-select {
      width: 100%;
    }
    .el-textarea__inner {
      height: 100px;
    }
    .el-radio-group {
      .el-radio-button {
        margin: 0 16px 16px 0;
        &:focus {
          box-shadow: none;
        }
      }
      .el-radio-button__inner {
        padding: 0 10px;
        min-width: 80px;
        height: 30px;
        line-height: 30px;
        background: #FFFFFF;
        border-radius: 15px;
        border: 1px solid #E5EAED;
        color: #333333;
        box-shadow: none;
      }
      .is-active {
        .el-radio-button__inner {
          background: rgba(232, 75, 34, .1);
          border-radius: 15px;
          border: 1px solid #E84B22;
          color: #FF6E48;
        }
      }
    }
  }
}
.case-prompt {
  .prompt-content {
    margin-top: 20px;
    font-size: 14px;
    color: #303133;

    &:first-child {
      margin: 0;
    }
  }
  .prompt-title {
    font-weight: bolder;
    font-size: medium;
  }
  .prompt-item {
    margin: 5px 0;
    color: #606266;
  }
}
::v-deep .el-upload {
  position: relative;

  .mask {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(255, 255, 255, .5);

    i {
      font-size: 48px;
    }
  }

  &:hover {
    .mask {
      display: flex;
    }
  }
}
.description-box {
  position: relative;
  margin: 5px 0 12px;
  padding: 10px 20px;
  height: auto;
  background: rgba(244, 245, 250, .5);
  border-radius: 3px;
  color: #999;

  &::after {
    content: '';
    position: absolute;
    top: -22px;
    left: 72px;
    width: 0px;
    height: 0px;
    border: 11px solid;
    border-bottom-color: rgba(244, 245, 250, .5);
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }

  .title {
    color: #666;
  }
  p {
    line-height: 24px;
  }
}
</style>
