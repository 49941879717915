<template>
  <div>
    <div class="top-btn-box">
      <div style="font-weight: 500">管理体系认证认可</div>
      <div>
        <div class="btn" @click="handleBatchSubmit">提交审核</div>
        <div class="btn" style="background-color: #E94B22;color: #ffffff;margin-left: 8px" @click="openManagementModal">新增</div>
      </div>
    </div>
    <div>
      <el-table
        ref="multipleTable"
        :border="true"
        :header-cell-style="{background:'#F5F5F6',color:'#999999'}"
        :data="managementList"
        @selection-change="handleSelectionChange"
        style="width: 100%">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="60">
        </el-table-column>
        <el-table-column
          prop="name"
          label="证书名称"
          width="180">
        </el-table-column>
        <el-table-column
          prop="num"
          label="证书编号">
        </el-table-column>
        <el-table-column
          prop="startTime"
          label="发证日期">
        </el-table-column>
        <el-table-column
          prop="endTime"
          label="有效期">
        </el-table-column>
        <el-table-column
          prop="issuingAuthority"
          label="发证机关">
        </el-table-column>
        <el-table-column
          prop="approvalStatus"
          label="状态">
          <template slot-scope="scope">
            <span :style="{'color':productionStatus[scope.row.approvalStatus || 9999].color}">{{productionStatus[scope.row.approvalStatus || 9999].name}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" style="color: #07A2FF" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" style="color: #07A2FF" @click="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getManagementList"
      />
    </div>
    <managementModal ref="managementModal" @ok="getManagementList"></managementModal>
  </div>
</template>

<script>
import managementModal from "./modal/managementModal.vue";
export default {
  components:{managementModal},
  data(){
    return{
      //table数据源
      managementList:[],
      productionStatus:{
        0:{name:'未审核',color:'#FF6D72'},
        1:{name:'审核中',color:'#666666'},
        2:{name:'已审核',color:'#FAAD15'},
        3:{name:'已驳回',color:'#333333'},
        9999:{name:'未审核',color:'#333333'}
      },
      //选中的行
      multipleSelection: [],
      queryParams:{
        pageNum:1,
        pageSize:10,
      },
      total:0,
    }
  },//data
  created() {
    this.getManagementList()
  },//created
  methods:{
    /**
     * 点击复选框回调
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 批量提交
     */
    handleBatchSubmit(){
      if(this.multipleSelection.length == 0){
        this.$message.warning('请至少选择一行')
      }else{
        let ids = this.multipleSelection.map(e=> e.id)
        this.$api.post(`/merchantApi/enterpriseAtteZz/submitApproval/${ids}`).then(res=>{
          if(res.code == 200){
            this.$message.success(res.msg)
            this.getManagementList()
          }else{
            this.$message.error(res.msg)
          }
        })
      }
    },
    handleEdit(row){
      this.$refs.managementModal.edit(row.id)
    },
    handleDel(row){
      this.$confirm('是否确认删除？').then(() => {
        this.$api.delete(`/merchantApi/enterpriseAtteZz/${row.id}`).then(res=>{
          if(res.code == 200){
            this.$message.success(res.msg)
            this.getManagementList()
          }else{
            this.$message.error(res.msg)
          }
        })
      })
    },
    /**
     * 打开新增
     */
    openManagementModal(){
      this.$refs.managementModal.visible = true
    },
    /**
     * 获取管理体系认证许可
     */
    getManagementList(){
      this.$api.get('/merchantApi/enterpriseAtteZz/list',{
        type:'gltx',
        ...this.queryParams
      }).then(res=>{
        this.managementList = res.rows
        this.total = res.total
        this.managementList.forEach(e=>{
          e.name = e.name?.split(',')
        })
      })
    },
  },//methods
}
</script>

<style scoped lang="less">
* {
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 14px;
}
.top-btn-box{
  width: 100%;
  margin-top: 17px;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &>div:nth-child(2){
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
.btn {
  width: 80px;
  height: 35px;
  background: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #E94B22;
  line-height: 35px;
  text-align: center;
  color: #E94B22;
  font-weight: 500;
  font-size: 14px;
  &:hover{
    cursor: pointer;
  }
}
</style>
