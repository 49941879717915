<template>
  <div class="flex-container">
    <div class="header" ref="header">
      <el-form :inline="true" :model="formInline" class="form-inline" ref="formInline">
        <el-row>
          <el-col :span="4">
             <el-form-item label-width="0" prop="resultName">
              <el-input v-model="formInline.resultName" placeholder="关键词"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0" prop="industryType">
              <el-select v-model="formInline.industryType" placeholder="行业类型" clearable>
                <el-option
                  v-for="(item, index) in dict.type.firm_trade"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0" prop="caseCate">
              <el-select v-model="formInline.caseCate" placeholder="合同属性" clearable>
                <el-option
                  v-for="(item, index) in dict.type.store_contract_type"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0" prop="checkStatus">
              <el-select v-model="formInline.checkStatus" placeholder="审核状态" clearable>
                <el-option
                  v-for="(item, index) in dict.type.approval_status"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0">
              <el-button type="primary" @click="onSubmit">查询</el-button>
              <el-button @click="reset('formInline')" type="button">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="content">
      <div class="table-handle">
        <el-button size="small" type="primary" @click="add">添加业绩</el-button>
        <el-button size="small" @click="remove()">删除</el-button>
      </div>
      <el-table
        v-if="tableHeight"
        :data="tableData"
        style="width: 100%"
        :max-height="tableHeight"
        v-loading="loading"
        ref="multipleTable"
      >
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            prop="resultName"
            label="项目名称"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="resultType"
            label="项目类型"
          >
            <template slot-scope="scope">
              <dict-tag :options="dict.type.store_result_type" :value="scope.row.resultType"/>
            </template>
          </el-table-column>
          <el-table-column
              prop="contractType"
              label="合同属性"
              width="120">
              <template slot-scope="scope">
                <dict-tag :options="dict.type.store_contract_type" :value="scope.row.contractType"/>
              </template>
          </el-table-column>
          <el-table-column
              prop="addressAll"
              label="项目区域"
              width="200">
          </el-table-column>
          <el-table-column
              prop="resultArea"
              label="项目面积"
              width="120">
          </el-table-column>
          <el-table-column
              prop="spendMoney"
              label="项目造价"
              width="120">
          </el-table-column>
          <el-table-column
              prop="checkTime"
              label="审核时间"
              width="150">
          </el-table-column>
          <el-table-column
              prop="checkStatus"
              label="审核状态"
              width="120">
              <template slot-scope="scope">
                <dict-tag :options="dict.type.store_result_check_status" :value="scope.row.checkStatus"/>
              </template>
          </el-table-column>
          <el-table-column
              prop="checkAccountName"
              label="审核人"
              width="150">
          </el-table-column>
          <el-table-column
              prop="status"
              label="操作"
              width="120"
              fixed="right"
              align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
                  <el-button type="text" @click="remove(scope.row.id)" class="text-gray">删除</el-button>
                </template>
          </el-table-column>
      </el-table>

      <pagination
          v-show="total>0"
          :total="total"
          :page.sync="formInline.pageNum"
          :limit.sync="formInline.pageSize"
          @pagination="getList"
      />
    </div>
  </div>
</template>

<script>
import { industryList, contractList, statusList } from '@/utils/variable.js';
export default {
    name: 'result-list',
    components: {},
    dicts: [
      'store_case_cate',
      'store_result_check_status',
      'firm_trade',
      'store_contract_type',
      'store_result_type'
    ],
    data () {
      return {
        industryList: industryList,
        statusList: statusList,
        contractList: contractList,
        total: 0,
        loading: true,
        formInline: {
          pageNum: 1,
          pageSize: 10,
          caseCate: '',
          checkStatus: '',
          industryType: '',
          merchantId: '',
          resultName: '',
        },
        tableData: [],
        tableHeight: ''
      };
    },
    created() {
      this.getList();
    },
    mounted() {
      let searchFormHeight = this.$refs['header'].clientHeight + 20 // 可变的查询高度
      let pageHeight = document.documentElement.clientHeight // 可用窗口的高度
      this.tableHeight = Number(pageHeight - (searchFormHeight + 100 + 130));
    },
    methods: {
      getList() {
        this.loading = true;
        this.$api.get('/merchantApi/storeResult/list', {
          ...this.formInline
        })
        .finally(() => {
          this.loading = false;
        })
        .then(res => {
          if(res.code == 200) {
            this.tableData = res.rows;
            this.total = res.total;
          }
        });
      },
      add() {
        this.$router.push({
          path: '/store/result/add'
        });
      },
      edit(id) {
        this.$router.push({
          path: '/store/result/add',
          query: {id: id}
        });
      },
      remove(id) {
        let params = [];
        if(id) {
          // 单个删除
          params.push(id);
        } else {
          // 多个删除
          let ref = this.$refs.multipleTable;
          if(ref.selection && ref.selection.length) {
            ref.selection.map(item => {
              params.push(item.id);
            })
          } else {
            this.$message({
              message: '没有选中数据',
              type: 'warning'
            });
            return false;
          }
        }
        this.$confirm('是否确认删除？')
        .then( res => {
          this.$api.delete('/merchantApi/storeResult/'+params.join()).then(res => {
            this.getList();
          })
          done();
        })
        .catch( res => {});
      },
      onSubmit() {
        this.formInline.pageNum = 1;
        this.getList();
      },
      reset(formName) {
        this.$refs[formName].resetFields();
        this.onSubmit();
      },
    }
}

</script>
<style lang='less' scoped>
::v-deep .form-inline {
  .el-form-item {
    display: block !important;
    margin-bottom: 10px !important;
    .el-form-item__content {
      display: block !important;
    }
    .el-select {
      width: 100%;
    }
  }
}
</style>