<template>
  <div class="flex-container">
    <div class="header" ref="header">
      <el-form :model="queryParams" ref="queryForm" :inline="true" class="form-inline">
        <el-row>
          <!-- <el-col :span="8">
            <el-form-item label-width="0" prop="id">
              <el-date-picker
                v-model="queryParams.id"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col> -->
          <el-col :span="4">
            <el-form-item label-width="0" prop="payInfoType">
              <el-select v-model="queryParams.payInfoType" placeholder="交易类型" clearable>
                <el-option
                  v-for="dict in dict.type.payInfo_type"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0" prop="payStatus">
              <el-select v-model="queryParams.payStatus" placeholder="交易状态" clearable>
                <el-option
                  v-for="dict in dict.type.pay_status"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0">
              <el-button type="primary" @click="handleQuery">查询</el-button>
              <el-button @click="resetQuery">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="content">
      <el-table v-if="tableHeight" v-loading="loading" :data="tableData"  :max-height="tableHeight">
          <el-table-column
            type="index"
            width="50">
          </el-table-column>
          <el-table-column label="项目名称" align="center" prop="businessOpportunityProjectName" :show-overflow-tooltip="true" width="200" />
          <el-table-column label="流水号" align="center" prop="payNo" width="200" />
          <el-table-column label="交易类型" align="center" prop="payInfoType" width="120">
            <template slot-scope="scope">
              <dict-tag :options="dict.type.payInfo_type" :value="scope.row.payInfoType"/>
            </template>
          </el-table-column>
          <el-table-column label="可用余额" align="center" prop="balance"  width="120"/>
          <el-table-column label="交易金额" align="center" prop="payBalance"  width="120"/>
          <el-table-column label="创建人	" align="center" prop="createBy" width="120" />
          <el-table-column label="交易状态" align="center" prop="payStatus" width="120">
            <template slot-scope="scope">
              <dict-tag :options="dict.type.pay_status" :value="scope.row.payStatus"/>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" prop="createTime" width="150"/>
          <el-table-column label="说明" align="center" prop="remark" />
          <el-table-column
            label="操作"
            width="100"
            fixed="right"
            align="center">
            <template slot-scope="scope">
              <!-- <el-button type="text" @click="handleUpdate(scope.row)">编辑</el-button> -->
              <el-button type="text" class="text-gray" @click="handleDetail(scope.row.id)">详情</el-button>
            </template>
          </el-table-column>

      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
      
    </div>

    <el-dialog title="商机宝记录" :visible.sync="dialogTableVisible" width="720px"  destroy-on-close>


      <el-descriptions title="支付详情" v-loading="detailLoading" :column="2">
       
        <el-descriptions-item label="交易类型" >
          <dict-tag :options="dict.type.payInfo_type" :value="details.payInfoType"/>
        </el-descriptions-item>
        <el-descriptions-item label="交易金额" >{{ details.payBalance }}</el-descriptions-item>
        <!-- <el-descriptions-item label="交易时间">{{ details.payNo }}</el-descriptions-item> -->
        <el-descriptions-item label="交易状态">
          <dict-tag :options="dict.type.pay_status" :value="details.payStatus"/>
        </el-descriptions-item>
        <!-- <el-descriptions-item label="操作人">{{ details.payNo }}</el-descriptions-item> -->
      </el-descriptions>

      <el-descriptions title="项目信息" :column="1">
       <el-descriptions-item label="项目名称" >{{ details.businessOpportunityProjectName ? details.businessOpportunityProjectName : '' }}</el-descriptions-item>
       <el-descriptions-item label="商机编号">{{ details.businessOpportunity ? details.businessOpportunity.businessCode : '' }}</el-descriptions-item>
       <el-descriptions-item label="项目面积">{{ details.businessOpportunity ? details.businessOpportunity.measureArea : '' }}</el-descriptions-item>
       <el-descriptions-item label="所属楼宇/园区">{{ details.businessOpportunity ? details.businessOpportunity.park : '' }}</el-descriptions-item>

        <el-descriptions-item label="场地类型">
          <dict-tag v-if="details.businessOpportunity" :options="dict.type.lbg_site_type" :value="details.businessOpportunity.siteProperties"/>
        </el-descriptions-item>
      
        <el-descriptions-item label="需求类型">
          <dict-tag
            v-if="details.businessOpportunity"
            :options="dict.type.lbg_request_type"
            :value="details.businessOpportunity.demandType"
          />
        </el-descriptions-item>
        <el-descriptions-item label="项目地址">{{ details.businessOpportunity ? details.businessOpportunity.addressAll : '' }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
// 商机宝
export default {
  dicts: [
    'contract_type',
    'sign_record_check_status',
    'payInfo_type',
    'lbg_site_type',
    'pay_status',
    'lbg_request_type',
  ],
  name: 'order-trade',
  data() {
      return {
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          payInfoType: '',
          payStatus: '',
        },
        // 显示搜索条件
        tableData: [],
        loading:false,
        total:2,
        tableHeight:'',
        dialogTableVisible: false,
        detailLoading: true,
        details: {}
      }
  },
  created() {
    this.getList();
  },
  mounted() {
    let searchFormHeight = this.$refs['header'].clientHeight + 20 // 可变的查询高度
    let pageHeight = document.documentElement.clientHeight // 可用窗口的高度
    this.tableHeight = Number(pageHeight - (searchFormHeight + 190));
  },
  methods: {
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    handleDetail(id){
      this.dialogTableVisible = true;
      this.detailLoading = true;
      this.$api.get('/merchantApi/enterprisePowerFlowInfo/'+id)
      .finally(() => {
        this.detailLoading = false;
      })
      .then(res => {
        if(res.code == 200) {
          this.details = res.data;
        }
      });
    },
    getList() {
      this.loading = true;
      this.$api.get('/merchantApi/enterprisePowerFlowInfo/list', {
        ...this.queryParams
      })
      .finally(() => {
        this.loading = false;
      })
      .then(res => {
        if(res.code == 200) {
          this.tableData = res.rows;
          this.total = res.total;
        }
      });
    },
  }
}
</script>

<style lang='less' scoped>
@import '../../assets/css/form.less';
::v-deep .el-dialog {
  .el-descriptions-item__container {
    align-items: center;
  }
}
</style>
