<template>
    <div v-loading="loading">
      <el-table
        :data="tableData"
        style="width: 100%">

        <el-table-column
          prop="createTime"
          label="提交时间" 
          width="200px"       
        />

        <el-table-column
          prop="followType"
          label="跟进方式"
          width="200px"   
        >
          <template slot-scope="scope">
            <dict-tag
              :options="dict.type.follow_type"
              :value="scope.row.followType"
            />
          </template>
        </el-table-column>

        <el-table-column
          prop="nextTime"
          label="下次跟进时间" 
          width="200px"       
        />

        <el-table-column
          prop="followInfo"
          label="跟进内容"
        >
        </el-table-column>
       
      </el-table>

      <pagination
          v-show="total>0"
          :total="total"
          :page.sync="query.pageNum"
          :limit.sync="query.pageSize"
          @pagination="getContractList"
      />
    </div>
</template>

<script>
export default {
    name: 'contract',
    components: {},
    dicts: [
      'contract_type',
      'sign_record_check_status',
      'follow_type',
    ],
    props: ['businessId'],
    data () {
      return {
        loading: true,
        tableData: [],
        total: 0,
        query: {
          pageNum: 1,
          pageSize: 10
        },
      };
    },
    created() {
      this.getContractList();
    },
    methods: {
      getContractList() {
        this.loading = true;
        this.$api.get('/merchantApi/businessOpportunity/getPlateFormBoFollowRecordList', {
          objectId: this.businessId
        })
        .finally(() => {
          this.loading = false;
        })
        .then(res => {
          if(res.code = 200) {
            this.tableData = res.rows;
            this.total = res.total;
          }
        })
      }
    }
}

</script>
<style lang='less' scoped>
.el-descriptions {
  margin-bottom: 20px;
}
</style>