<template>
    <div class="flex-container">
        <div class="header" ref="header">
            <el-form :inline="true" :model="formInline" class="form-inline">
                <el-row>
                    <el-col :span="4">
                        <el-form-item label-width="0" prop="businessStatus">
                        <el-select v-model="formInline.businessStatus" placeholder="商机阶段" clearable>
                            <el-option
                                v-for="(item, index) in dict.type.business_platform_status"
                                :key="index"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                    <el-form-item label-width="0" prop="businessOpportunitySource">
                        <el-select v-model="formInline.businessOpportunitySource" placeholder="派单类型" clearable>
                            <el-option
                                v-for="(item, index) in dict.type.lbg_business_source"
                                :key="index"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    </el-col>
                    <el-col :span="4">
                    <el-form-item label-width="0" prop="demandType">
                        <el-select v-model="formInline.demandType" placeholder="需求类型" clearable>
                             <el-option
                                v-for="(item, index) in dict.type.lbg_request_type"
                                :key="index"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    </el-col>
                    <el-col :span="4">
                    <el-form-item label-width="0" prop="dockType">
                        <el-select v-model="formInline.dockType" placeholder="对接方式" clearable>
                            <el-option
                                v-for="(item, index) in dict.type.lbg_docking_type"
                                :key="index"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8">
                        <el-form-item label-width="0">
                            <el-date-picker
                                v-model="formInline.date"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="4">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="onSubmit">查询</el-button>
                            <el-button @click="reset('formInline')" type="button">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="content">
            <el-table
                v-if="tableHeight"
                :data="tableData"
                style="width: 100%"
                :max-height="tableHeight"
                v-loading="loading"
                ref="multipleTable"
                @row-click="rowClick"
            >
                <el-table-column
                    label="商机编号"
                    prop="businessCode"
                    width="80"
                    show-overflow-tooltip>
                </el-table-column>

                <el-table-column
                    prop="projectName"
                    label="项目名称"
                     width="320"
                    show-overflow-tooltip>
                </el-table-column>

                <el-table-column
                    prop="measureArea"
                    label="项目面积"
                >
                </el-table-column>

                <el-table-column
                    prop="demandType"
                    label="需求类型"
                >
                    <template slot-scope="scope">
                        <dict-tag
                            :options="dict.type.lbg_request_type"
                            :value="scope.row.demandType"
                        />
                    </template>
                </el-table-column>
                   <el-table-column
                    prop="projectNature"
                    label="项目性质"
                >
                    <template slot-scope="scope">
                        <dict-tag :options="dict.type.project_nature" :value="scope.row.projectNature"/>
                    </template>
                </el-table-column>


                <el-table-column
                    prop="projectType"
                    label="项目类型"
                >
                    <template slot-scope="scope">
                        <dict-tag
                            :options="dict.type.lbg_project_type"
                            :value="scope.row.projectType"
                        />
                    </template>
                </el-table-column>

                <!-- <el-table-column
                    prop="dockType"
                    label="对接方式"
                >
                <template slot-scope="scope">
                        <dict-tag
                            :options="dict.type.lbg_docking_type"
                            :value="scope.row.dockType"
                        />
                    </template>
                </el-table-column> -->

                <!-- <el-table-column
                    prop="sjStatus"
                    label="设计状态"
                >
                    <template slot-scope="scope">
                        <dict-tag
                            :options="dict.type.bidding_status"
                            :value="scope.row.sjStatus"
                        />
                    </template>
                </el-table-column>

                <el-table-column
                    prop="sgStatus"
                    label="施工状态"
                >
                    <template slot-scope="scope">
                        <dict-tag
                            :options="dict.type.bidding_status"
                            :value="scope.row.sgStatus"
                        />
                    </template>
                </el-table-column> -->
                <el-table-column
                    prop="biddingStatusSh"
                    label="竞标状态"
                >
                    <template slot-scope="scope">
                        <dict-tag
                            :options="dict.type.bidding_status"
                            :value="scope.row.biddingStatusSh"
                        />
                    </template>
                </el-table-column>

                

                <el-table-column
                    prop="businessOpportunitySource"
                    label="派单类型"
                >
                    <template slot-scope="scope">
                        <dict-tag
                            :options="dict.type.lbg_business_source"
                            :value="scope.row.businessOpportunitySource"
                        />
                    </template>
                </el-table-column>
                <el-table-column prop="haveReceiveNum" label="应标进度">
                    <template slot-scope="scope">
                    {{scope.row.haveReceiveNum ? scope.row.haveReceiveNum : 0 }}/{{scope.row.bidMax}}
                    </template>
                </el-table-column>


                <el-table-column
                    prop="bidDistributionTime"
                    label="分标时间"
                    width="120">
                </el-table-column>

               <!--  <el-table-column
                    prop="nextDate"
                    label="下次跟进时间"
                    width="120"
                    show-overflow-tooltip
                ></el-table-column> -->

                <el-table-column
                    prop="surveyTime"
                    label="对接时间"
                    width="120">
                </el-table-column>
                
                <!-- <el-table-column
                    prop="updateBy"
                    label="更新人"
                >
                </el-table-column> -->

                <el-table-column
                    prop="status"
                    label="操作"
                    width="100"
                    fixed="right"
                    align="center">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            class="text-gray"
                            @click.native.stop="handleDetail(scope.row.businessOpportunityRefId)"
                        >详情</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <pagination
                v-show="total>0"
                :total="total"
                :page.sync="formInline.pageNum"
                :limit.sync="formInline.pageSize"
                @pagination="getList"
            />
        </div>
  </div>
</template>

<script>
// 平台商机
export default {
    name: 'business-platform',
    components: {},
    dicts: [
      'business_platform_status',
      'lbg_business_source',
      'lbg_project_type',
      'lbg_request_type',
      'lbg_docking_type',
      'bidding_status',
       'project_nature',
    ],
    data () {
        return {
            loading: true,
            total: 0,
            formInline: {
                pageNum: 1,
                pageSize: 10,
                businessOpportunitySource: '',
                businessStatus: '',
                demandType: '',
                dockType: '',
            },
            tableData: [],
            tableHeight: ''
        };
    },
    created() {
        this.getList();
    },
    mounted() {
        let searchFormHeight = this.$refs['header'].clientHeight + 20 // 可变的查询高度
        let pageHeight = document.documentElement.clientHeight // 可用窗口的高度
        this.tableHeight = Number(pageHeight - (searchFormHeight + 190));
    },
    methods: {
        getList() {
            this.loading = true;
            this.$api.get('/merchantApi/businessOpportunity/platformOpportunities/list', {
                ...this.formInline
            })
            .finally(() => {
                this.loading = false;
            })
            .then(res => {
            if(res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            }
            });
        },
        onSubmit() {
            this.formInline.pageNum = 1;
            this.getList();
        },
        reset(formName) {
            this.$refs[formName].resetFields();
            this.onSubmit();
        },
        rowClick(row) {
            this.$router.push({path: '/business/platform/detail', query: { id: row.businessOpportunityRefId }});
        },
        handleDetail(bId) {
            this.$router.push({path: '/business/platform/detail', query: { id: bId }});
        },
        handleSizeChange() {},
        handleCurrentChange() {}
    }
}
</script>

<style lang="less" scoped>
@import '../../../assets/css/form.less';
</style>