<template>
    <div class="flex-container">
        <div class="content">
            <el-table
                v-if="tableHeight"
                :data="tableData"
                style="width: 100%"
                :max-height="tableHeight"
                @row-click="rowClick"
            >
                <el-table-column
                    label="商机编号"
                    prop="businessCode"
                    width="80"
                    show-overflow-tooltip
                >
                </el-table-column>
                
                <!-- <el-table-column
                    prop="coustomerFirmName"
                    label="客户"
                    width="120"
                    show-overflow-tooltip
                >
                </el-table-column> -->
                
                <el-table-column
                    prop="projectName"
                    label="项目名称"
                    width="320"
                    show-overflow-tooltip
                >
                </el-table-column>

                <el-table-column
                    prop="projectType"
                    label="项目类型"
                >
                    <template slot-scope="scope">
                        <dict-tag :options="dict.type.lbg_project_type" :value="scope.row.projectType"/>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="projectNature"
                    label="项目性质"
                >
                    <template slot-scope="scope">
                        <dict-tag :options="dict.type.project_nature" :value="scope.row.projectNature"/>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="demandType"
                    label="需求类型"
                >
                    <template slot-scope="scope">
                        <dict-tag :options="dict.type.lbg_request_type" :value="scope.row.demandType"/>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="measureArea"
                    label="项目面积"
                >
                </el-table-column>
                <el-table-column
                    prop="haveReceiveNum"
                    label="应标进度"
                >
                    <template slot-scope="scope">
                        {{scope.row.haveReceiveNum ? scope.row.haveReceiveNum : 0 }}/{{scope.row.bidMax}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="bidDistributionTime"
                    label="发布时间"
                    width="120">
                </el-table-column>
                <!-- <el-table-column
                    prop="dockType"
                    label="对接方式"
                >
                    <template slot-scope="scope">
                        <dict-tag :options="dict.type.lbg_docking_type" :value="scope.row.dockType"/>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="projectBudget"
                    label="项目预算"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="surveyTime"
                    label="勘测时间"
                    width="150">
                </el-table-column>
                <el-table-column
                    prop="planTime"
                    label="预计装修时间"
                    width="150">
                </el-table-column> -->
                
                <el-table-column
                    prop="status"
                    label="操作"
                    width="200"
                    fixed="right"
                    align="center">
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.isrob == 0"
                            type="text"
                            @click.native.stop="handleOrder(scope.row.id)">一键抢单</el-button>
                        <el-button
                            v-else
                            disabled
                            type="text"
                        >已抢单</el-button>
                        <el-button
                            type="text"
                            class="text-gray"
                            @click="handleDetail(scope.row.id)"
                        >详情</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <pagination
                v-show="total>0"
                :total="total"
                :page.sync="formInline.pageNum"
                :limit.sync="formInline.pageSize"
                @pagination="getList"
            />
        </div>
        <el-dialog
            title="提示"
            width="600px"
            :visible.sync="orderDialogVisible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            class="order-dialog"
            >
            <span></span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="orderDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleConfirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
// 抢单大厅
export default {
    name: 'business-order',
    dicts: [
      'lbg_docking_type',
      'lbg_project_type',
      'lbg_request_type',
      'project_nature',
    ],
    data() {
        return {
            tableData: [
            ],
            formInline: {
                pageNum: 1,
                pageSize: 10,
            },
            total: 0,
            tableHeight: '',
            orderDialogVisible: false,
            selectedRow: null
        }
    },
    created() {
        this.getList();
    },
    mounted() {
      let pageHeight = document.documentElement.clientHeight // 可用窗口的高度
      this.tableHeight = Number(pageHeight - 190);
    },
    methods: {
        getList() {
            this.loading = true;
            this.$api.get('/merchantApi/businessOpportunity/hall/list', {
                ...this.formInline
            })
            .finally(() => {
                this.loading = false;
            })
            .then(res => {
            if(res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            }
            });
        },
        rowClick(row) {
            this.$router.push({path: '/business/order/detail', query: { id: row.id }});
        },
        handleOrder(id) {
            this.$confirm('您确定需要抢此商机吗？操作后，将直接从您商机宝余额进行扣费！')
            .then( res => {
                this.$api.post('/merchantApi/businessOpportunity/grabbingOrder',
                    { 'opportunityId': id }
                )
                .finally(() => {
                })
                .then(res => {
                    if(res.code == 200) {
                        this.$message.success(res.msg);
                        this.getList();
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                done();
            })
            .catch( res => {});
        },
        handleDetail(id) {
            this.$router.push({path: '/business/order/detail', query: { id: id }});
        },
        handleConfirm() {
            
        },
        handleSizeChange() {},
        handleCurrentChange() {}
    }
}
</script>

<style lang="less" scope>

</style>