<template>
    <div>
        <div class="submit-form">
            <el-avatar :size="40" :src="avatar"></el-avatar>
            <el-form :inline="true" :model="form" class="form-inline" label-position="right" :rules="rules" ref="ruleForm">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="" prop="followInfo">
                            <el-input 
                            type="textarea"
                            v-model="form.followInfo"
                            placeholder="请输入跟进记录"
                            show-word-limit
                            resize="none"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="" prop="followType">
                            <el-select v-model="form.followType" placeholder="请选择跟进方式" clearable>
                                <el-option
                                    v-for="(item, index) in dict.type.follow_type"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="" prop="nextTime">
                            <el-date-picker type="date" placeholder="请选择下次跟进日期" v-model="form.nextTime" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">&nbsp;</el-col>
                    <el-col :span="4">
                        <el-form-item>
                            <el-button v-if="sdStatusSh != 1" type="primary" :disabled="saving" @click="submitForm('ruleForm')">提交</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="submit-list" v-for="(item, index) in followList" :key="index">
            <el-avatar :size="40" :src="avatar"></el-avatar>
            <div class="list">
                <div class="line">
                    <dict-tag
                        :options="dict.type.follow_type"
                        :value="item.followType"/>
                    <el-tag type="success">{{ item.nextTime }}</el-tag>
                </div>
                <p class="info">{{ item.followInfo }}</p>
                <p class="time">{{ item.createTime }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import avatar from '@/assets/images/avatar.png';
export default {
    name: 'follow',
    components: {},
    props: ['businessId', 'sdStatusSh'],
    dicts: [
      'follow_type',
    ],
    data () {
        return {
            avatar: avatar,
            followList: [],
            form: {
                nextTime: '',
                followInfo: '',
                followType: '',
            },
            rules: {
                followInfo: [
                    { required: true, message: '请输入项目编号', trigger: 'blur' }
                ],
                followType: [
                    { required: true, message: '请选择跟进方式', trigger: 'change' }
                ],
                nextTime: [
                    { required: true, message: '请选择下次跟进日期', trigger: 'change' }
                ],
            },
            saving: false,
            loading: true,
        };
    },
    created() {
        this.getList();
    },
    methods: {
        submitForm(formName) {
            if(this.saving) {
                return false;
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.saving = true;
                    this.$api.post('/merchantApi/businessOpportunity/insertBoFollowRecord',
                    {
                        ...this.form,
                        objectId: this.businessId
                    })
                    .finally(() => {
                        this.saving = false;
                    })
                    .then(res => {
                        if(res.code == 200) {
                        this.$message.success(res.msg);
                        this.$refs['ruleForm'].resetFields();
                        // this.$refs['ruleForm'].clearValidate();
                        this.getList();
                        } else {
                        this.$message.error(res.msg);
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getList() {
            this.loading = true;
            this.$api.get('/merchantApi/businessOpportunity/getBoFollowRecordList', {
                objectId: this.businessId
            }).then(res => {
                if(res.code == 200) {
                    this.followList = res.rows;
                    this.loading = false;
                }
            })
        },
    }
}

</script>
<style lang='less' scoped>
@import '../../../../assets/css/form.less';
::v-deep .submit-form {
    display: flex;
    margin-bottom: 20px;

    .el-form {
        margin-left: 20px;
        width: 600px;

        textarea {
            height: 80px;
        }
        .el-form-item {
            margin-bottom: 20px !important;
        }
    }
}
.submit-list {
    margin-bottom: 10px;
    display: flex;
    

    .list {
        margin-left: 20px;
        width: 600px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f1f1f1;

        .el-tag {
            margin-left: 10px;
        }

        .line {
            display: flex;
        }
        .info {
            margin: 10px 0;
            font-size: 14px;
            word-wrap: break-word;
        }
        .time {
            color: #666;
            font-size: 12px;
        }
    }
}
</style>