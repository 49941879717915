<template>
    <el-container id="app">
		<el-aside :width="'auto'">
			<el-header class="flex justify-center align-center">
				<div class="logo" :class="isCollapse ? 'logo-small' : ''"></div>
			</el-header>
			<el-menu class="el-menu-vertical-demo" :collapse="isCollapse" :unique-opened="true" router :default-active="$route.path">
				<el-menu-item index="/index">
					<i class="el-icon-s-home"></i>
					<span slot="title">首页</span>
				</el-menu-item>
				<el-submenu index="2">
					<template slot="title">
						<i class="el-icon-s-shop"></i>
						<span slot="title">商机管理</span>
					</template>
					<el-menu-item index="/business/order/list">抢单大厅</el-menu-item>
					<el-menu-item index="/business/platform/list">我的商机</el-menu-item>
					<el-menu-item index="/order/record">我的退单</el-menu-item>
				</el-submenu>
				<el-submenu index="6">
					<template slot="title">
						<i class="el-icon-s-finance"></i>
						<span slot="title">合同管理</span>
					</template>
					<el-menu-item index="/order/contract">签约管理</el-menu-item>
				</el-submenu>
				<el-submenu index="3">
					<template slot="title">
						<i class="el-icon-s-order"></i>
						<span slot="title">收支管理</span>
					</template>
					<el-menu-item index="/order/wallet">狸钱包</el-menu-item>
					<el-menu-item index="/order/trade">商机宝</el-menu-item>
					<!-- <el-menu-item index="/order/contract">签约管理</el-menu-item> -->
					<!-- <el-menu-item index="/order/record">退单记录</el-menu-item> -->
				</el-submenu>
				<el-submenu index="4">
					<template slot="title">
						<i class="el-icon-s-goods"></i>
						<span slot="title">店铺管理</span>
					</template>
					<el-menu-item index="/store/case/list">案例管理</el-menu-item>
					<el-menu-item index="/store/result/list">业绩管理</el-menu-item>
					<el-menu-item index="/store/product">品宣管理</el-menu-item>
          			<el-menu-item index="/auth/index">深度认证管理</el-menu-item>
				</el-submenu>
				<el-submenu index="5">
					<template slot="title">
						<i class="el-icon-s-tools"></i>
						<span slot="title">公司管理</span>
					</template>
					<el-menu-item index="/company/basic">商户基本信息</el-menu-item>
					<!-- <el-menu-item index="/company/account/list">人员管理</el-menu-item> -->
				</el-submenu>
			</el-menu>
		</el-aside>
		<el-container>
			<el-header>
				<div class="header-flex">
					<div class="header-flex">
						<i class="collapse" :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'" @click="isCollapse = !isCollapse"></i>
						<span class="ml20">{{ userInfo.enterpriseName }}</span>
					</div>
					<el-menu class="el-menu-demo" mode="horizontal">
						<el-submenu index="2">
							<template slot="title">{{ userInfo ? userInfo.name : '我的工作台'}}</template>
							<el-menu-item index="2-1" @click="quit">退出系统</el-menu-item>
						</el-submenu>
					</el-menu>
				</div>
			</el-header>
			<el-main>
				<router-view/>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
import { getUserInfo } from '@/utils/auth'
export default {
    name: '',
    data() {
        return {
            active: '/index/index',
			isCollapse: false,
			userInfo: getUserInfo() ? JSON.parse(getUserInfo()) : null
        }
    },
    created() {
		console.log(this.userInfo);
        this.active = this.$route.path;
    },
    methods: {
        quit() {
			this.$confirm('确定注销并退出系统吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$store.dispatch('LogOut').then(() => {
					location.href = '/login';
				})
			}).catch(() => {});
		}
    }
}
</script>

<style lang="less" scoped>
.el-header, .el-footer {
	padding: 0;
	background-color: #fff;
	color: #333;
	line-height: 60px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
	width: 200px;
}
.el-aside {
	background-color: #D3DCE6;
	color: #333;
	line-height: 200px;

	transition: width 0.15s;
	-webkit-transition: width 0.15s;
	-moz-transition: width 0.15s;
	-webkit-transition: width 0.15s;
	-o-transition: width 0.15s;
}

.el-main {
	background-color: #E9EEF3;
	color: #333;
}
.collapse {
	font-size: 20px;
	cursor: pointer;
}
.header-flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.logo {
	width: 160px;
	height: 20px;
	overflow: hidden;
	background: url('../../assets/images/index-logo.png') no-repeat left center;
	background-size: cover
}
.logo-small {
	width: 42px;
}
</style>
