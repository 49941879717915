<template>
  <div style="width: 100ch">
    <el-upload
      :action="uploadUrl"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload">
      <div style="display: flex;align-items: center;flex-wrap: wrap">
        <div style="margin-bottom: 10px">
          <div class="avatar-uploader-box"></div>
        </div>
        <div v-for="(item,i) in imageList" :key="i" @click.stop="handleDeleteImg(i)" class="imgs-box">
          <img :src="host + item.name" class="avatar">
          <div class="icon">
            <i class="el-icon-close"></i>
          </div>
        </div>
      </div>
    </el-upload>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageList:[],
      uploadUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
    };
  },
  computed:{
    host(){
      return this.$fileBaseUrl
    }
  },//computed
  created() {
  },
  methods: {
    /**
     * 删除
     */
    handleDeleteImg(index){
      this.imageList.splice(index,1)
    },
    handleAvatarSuccess(res, file) {
      this.imageList.push({
        name: '/files'+res.data.name,
        url:res.data.url,
        fileId:res.data.fileId
      })
    },
    beforeAvatarUpload(file) {
      console.log(file.type)
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (isJPG || isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      return (isJPG||isPNG) && isLt5M;
    }
  }
}
</script>

<style scoped lang="less">
.imgs-box{
  margin-bottom: 10px;
  position: relative;
}
.icon{
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ffffff;
  padding: 4px;
}
.avatar-uploader-box{
  width: 250px;
  height: 161px;
  background-color: #F2F2F2;
  line-height: 161px;
  text-align: center;
  background-image: url("../../assets/images/upload.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 3px;
  margin-right: 10px;
}
.avatar {
  width: 250px;
  height: 161px;
  display: block;
  margin-right: 10px;
}
</style>
