<template>
    <div>
        <el-form v-if="!loading" :inline="true" :model="basicForm" class="form-inline" label-position="right" :rules="basicRules" ref="basicForm">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="公司logo">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadFileUrl"
                            :headers="headers"
                            accept=".jpeg, .png, .jpg"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="basicForm.logoFileUrl" :src="basicForm.logoFileUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form-item label="公司全称" prop="enterpriseName">
                        <el-input v-model="basicForm.enterpriseName" placeholder="请输入公司全称"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="公司店铺名称(如需变更联系管理员)" prop="storeName">
                        <el-input v-model="basicForm.storeName" placeholder="公司店铺名称" disabled="true"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="slogan" prop="slogan">
                        <el-input v-model="basicForm.slogan" placeholder="请输入slogan"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form-item label="办公面积" prop="officeSpace">
                        <el-input placeholder="请输入办公面积" v-model="basicForm.officeSpace">
                        <template slot="append">㎡</template>
                        </el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="联系人" prop="contactName">
                        <el-input v-model="basicForm.contactName" placeholder="请输入联系人"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="管理员手机" prop="contactPhone">
                        <el-input v-model="basicForm.contactPhone" placeholder="请输入管理员手机"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <el-form-item label="公司地址" prop="provinceId">
                                <el-select v-model="basicForm.provinceId" placeholder="请选择省" clearable @change="changeProvince">
                                    <el-option
                                    v-for="(item, index) in provinceList"
                                    :key="index"
                                    :label="item.name"
                                    :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label=" " prop="cityId">
                                <el-select v-model="basicForm.cityId" placeholder="请选择市" clearable @change="changeCity">
                                    <el-option
                                    v-for="(item, index) in cityList"
                                    :key="index"
                                    :label="item.name"
                                    :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label=" " prop="districtId">
                                <el-select v-model="basicForm.districtId" placeholder="请选择区" clearable>
                                <el-option
                                    v-for="(item, index) in areaList"
                                    :key="index"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="详细地址" prop="address">
                        <el-input v-model="basicForm.address" placeholder="请输入详细地址"></el-input>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="8">
                  <el-form-item label="关于我们" prop="aboutMe">
                    <el-input v-model="basicForm.aboutMe" type="textarea"
                              minlength="150"
                              maxlength="300"
                              placeholder="请输入关于我们"></el-input>
                  </el-form-item>
                </el-col> -->
            </el-row>

            <el-form-item style="text-align: center; margin-top: 20px;">
                <div class="btn-handle">
                    <dict-tag :options="dict.type.approval_status" :value="basicForm.logoCheckStatus"/>
                    <el-button v-if="basicForm.logoCheckStatus != 1" type="primary" :disabled="saving" @click="submitBasicForm('basicForm')">提交审核</el-button>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import _ from 'lodash';
export default {
    name: 'basic-info',
    components: {},
    dicts: [
      'approval_status',
    ],
    data () {
        return {
            uploadFileUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
            headers: {
                Authorization: "Bearer " + getToken()
            },
            loading: true,
            basicForm: {
                logoFileUrl: '',
                enterpriseName: '',
                storeName: '',
                slogan: '',
                address: '',
                officeSpace: '',
                // aboutMe: '',
            },
            basicRules: {
                enterpriseName: [
                    { required: true, message: '请输入公司全称', trigger: 'blur' }
                ],
                storeName: [
                    { required: true, message: '请输入公司简称', trigger: 'blur' }
                ],
                officeSpace: [
                    { required: true, message: '请输入办公面积', trigger: 'blur' }
                ],
                contactName: [
                    { required: true, message: '请输入联系人', trigger: 'blur' }
                ],
                contactPhone: [
                    { required: true, message: '请输入管理员手机', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' }
                ],
                provinceId: [
                    { required: true, message: '请选择省', trigger: 'change' }
                ],
                aboutMe: [
                  { required: true, message: '长度在 150 到 300 个字符', trigger: 'blur',min: 150, max: 300, },
                ],
            },
            provinceList: [],
            cityList: [],
            areaList: [],
            saving: false
        };
    },
    created() {
        this.getBasicInfo();
    },
    methods: {
        // 获取基本信息
        getBasicInfo() {
            this.$api.get('/merchantApi/enterprise/info/enterpriseBaseInfo')
            .finally(() => {
                this.loading = false;
            })
            .then(res => {
                if(res.code == 200) {
                    this.basicForm = res.data;
                    this.basicForm.logoFileUrl = this.basicForm.logoFileUrl ? this.$fileBaseUrl + this.basicForm.logoFileUrl : '';
                    this.getProvinceList();
                    if(this.basicForm.provinceId) {
                        this.getCityList(this.basicForm.provinceId);
                    }
                    if(this.basicForm.cityId) {
                        this.getAreaList(this.basicForm.cityId);
                    }
                }
            })
        },
        // 提交基本信息
        submitBasicForm(formName) {
            if(this.saving) {
                return false;
            }
            let form = _.cloneDeep(this.basicForm);
            // console.log(form);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.saving = true;
                    this.$api.put('/merchantApi/enterprise/info/enterpriseBaseInfo', form)
                    .finally(() => {
                        this.saving = false;
                    })
                    .then(res => {
                        if(res.code == 200) {
                            this.getBasicInfo();
                            this.$message.success(res.msg);
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 公司logo
        handleAvatarSuccess(res) {
            if(res.code == 200) {
                this.basicForm.logoFileId = res.data.fileId;
                this.basicForm.logoFileUrl = res.data.url;
                this.$message.success("上传成功");
            } else {
                this.basicForm.logoFileId = '';
                this.basicForm.logoFileUrl = '';
                this.$message.error(res.msg);
            }
        },
        beforeAvatarUpload(file) {
            // 校检文件大小
            const isLt = file.size / 1024 / 1024 < 5;
            if (!isLt) {
                this.$message.error(`上传文件大小不能超过5MB!`);
                return false;
            }
        },
        // 获取省市区
        getProvinceList() {
            this.provinceList = [];
            this.$api.get('/lbg/province/list')
            .then(res => {
                if(res.code == 200) {
                    this.provinceList = res.rows;
                }
            })
        },
        getCityList(id) {
            this.cityList = [];
            this.$api.get('/lbg/city/list', {
                provinceId: id
            })
            .then(res => {
                if(res.code == 200) {
                    this.cityList = res.rows;
                }
            })
        },
        getAreaList(id) {
            this.areaList = [];
            this.$api.get('/lbg/district//list', {
                cityId: id
            })
            .then(res => {
                if(res.code == 200) {
                    this.areaList = res.rows;
                }
            })
        },
        changeProvince(id) {
            this.getCityList(id);
            this.basicForm.cityId = '';
            this.basicForm.districtId = '';
        },
        changeCity(id) {
            this.getAreaList(id);
            this.basicForm.districtId = '';
        },
    }
}

</script>
<style lang='less' scoped>
::v-deep .form-inline {
  // padding-left: 10px;
  .el-form-item {
    display: block !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
    .el-form-item__label {
      height: 40px;
      font-weight: bold;
    }
    .el-form-item__content {
      display: block !important;
    }
    .el-select {
      width: 100%;
    }
    .el-textarea__inner {
      height: 100px;
    }
    .el-range-separator {
        width: 30px;
    }
    .el-date-editor {
        width: 100%;
    }
  }
  .upload-uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 180px;
    }

    .el-upload:hover {
      border-color: #409EFF;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 100%;
      height: 178px;
      display: block;
    }
    .el-upload__tip {
      line-height: 20px;
    }
  }
}
.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 60px;
    height: 60px;
}
.avatar-uploader:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}
.avatar {
    width: 60px;
    height: 60px;
    display: block;
}
.pic-list {
    display: flex;

    .list {
        display: flex;
        flex-direction: column;
    }
}
.btn-handle {
    display: flex;
    justify-content: center;

    .el-button {
        margin-left: 20px;
    }
}
</style>
