<template>
    <div>
      <el-descriptions title="" border :column="1">
          <el-descriptions-item label="输单原因" :labelStyle="{'width': '100px'}">kooriookami</el-descriptions-item>
          <el-descriptions-item label="输单备注" :labelStyle="{'width': '100px'}">18100000000</el-descriptions-item>
      </el-descriptions>
    </div>
</template>

<script>
export default {
    name: 'upLost',
    components: {},
    data () {
      return {
      };
    },
    created() {},
    methods: {}
}

</script>
<style lang='less' scoped>
</style>