<template>
    <div style="border: 1px solid #ccc;">
        <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <Editor
            style="height: 500px; overflow-y: hidden;"
            :defaultConfig="editorConfig"
            :mode="mode"
            v-model="currentValue"
            @onCreated="onCreated"
            @customPaste="onPaste"
        />
    </div>
</template>
<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { getToken } from "@/utils/auth";

export default Vue.extend({
    components: { Editor, Toolbar },
    props: {
        /* 编辑器的内容 */
        value: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            editor: null,
            toolbarConfig: {
                toolbarKeys: [
                    'uploadImage',
                ]
            },
            currentValue: "",
            editorConfig: {
                placeholder: '请输入内容...',
                MENU_CONF: {
                    // 图片上传配置
                    uploadImage: {
                        server: process.env.VUE_APP_BASE_API + "/file/upload",
                        fieldName: 'file',
                        headers: {
                            Authorization: "Bearer " + getToken()
                        },
                        maxFileSize: 5 * 1024 * 1024, //8M
                        // 自定义插入图片
                        customInsert(res, insertFn) {
                            // res 即服务端的返回结果
                            // 从 res 中找到 url alt href ，然后插图图片
                            insertFn(res.data.url + '/caseviwe', '', '')
                        },
                    }
                }
            },
            mode: 'default', // or 'simple'
        }
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
            console.log(this.editor.getAllMenuKeys())
            // 配置 server 接口地址
            // this.editor.config.uploadImgServer = process.env.VUE_APP_BASE_API + "/file/upload";
        },
        // 处理粘贴数据
        onPaste(editor, event) {
          const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
          editor.insertText(text)
          event.preventDefault()
          return false
        }
    },
    mounted() {
        // 模拟 ajax 请求，异步渲染编辑器

    },
    watch: {
        value: {
            handler(val) {
                if (val !== this.currentValue) {
                    this.currentValue = val === null ? "" : val;
                }
            },
            immediate: true,
        },
        currentValue(val) {
            this.$emit('input', val);
        }
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
