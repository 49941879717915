<template>
  <el-dialog
    :destroy-on-close="true"
    title="新增报建项目"
    :visible.sync="visible"
    width="600px"
    :before-close="handleClose">
    <div style="padding: 0 60px">
      <el-row>
        <el-form ref="form" :model="form" label-width="100px">
          <el-col :span="24">
              <el-form-item label="项目名称">
                <el-input v-model="form.name" placeholder="请填写"></el-input>
              </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="项目编号">
              <el-input v-model="form.num" placeholder="请填写"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
              <el-form-item label="项目属地">
                <el-col :span="8">
                  <el-select v-model="form.provinceId" placeholder="请选择省" clearable @change="changeProvince" style="width: 100%">
                    <el-option
                      v-for="(item, index) in provinceList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">
                <el-select v-model="form.cityId" placeholder="请选择市" clearable @change="changeCity" style="width: 100%">
                  <el-option
                    v-for="(item, index) in cityList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
                </el-col>
                <el-col :span="8">
                <el-select v-model="form.districtId" placeholder="请选择区" clearable style="width: 100%">
                  <el-option
                    v-for="(item, index) in areaList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
                </el-col>
              </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="建设单位">
              <el-input v-model="form.constructionUnit" placeholder="请填写"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="项目总投资">
              <el-input v-model="form.projectMoney" placeholder="请填写" type="number">
                <template slot="append">万元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="onSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      form:{},
      provinceList: [],
      cityList: [],
      areaList: [],
    }
  },//data
  created() {
    this.getProvinceList();
  },//created
  methods: {
    handleClose() {
      this.form = {}
      this.visible = false
    },
    edit(id){
      this.visible = true
      this.$api.get(`/merchantApi/enterpriseAtteBjxm/${id}`).then(res=>{
        this.form = res.data
        if(this.form.provinceId) {
          this.getCityList(this.form.provinceId);
        }
        if(this.form.cityId) {
          this.getAreaList(this.form.cityId);
        }
        // res.data.fileList.forEach(e=>{
        //   this.$refs.MyUpload.imageList.push({
        //     name:e.fileUrl,
        //     fileId:e.fileId
        //   })
        // })
      })
    },
    onSubmit(){
      if(!this.form.id){
        this.$api.post('/merchantApi/enterpriseAtteBjxm',{...this.form}).then(res=>{
          if(res.code == 200){
            this.visible = false
            this.$message.success(res.msg)
            this.$emit('ok')
          }else{
            this.visible = false
            this.$message.error(res.msg)
          }
        })
      }else{
        this.$api.put('/merchantApi/enterpriseAtteBjxm',{...this.form}).then(res=>{
          if(res.code == 200){
            this.visible = false
            this.$message.success(res.msg)
            this.$emit('ok')
          }else{
            this.visible = false
            this.$message.error(res.msg)
          }
        })
      }
    },
    getProvinceList() {
      this.$api.get('/lbg/province/list')
        .then(res => {
          if(res.code == 200) {
            this.provinceList = res.rows;
          }
        })
    },
    changeProvince(id) {
      this.getCityList(id);
      this.form.cityId = '';
      this.form.districtId = '';
    },
    changeCity(id) {
      this.getAreaList(id);
      this.form.districtId = '';
    },
    getCityList(id) {
      this.$api.get('/lbg/city/list', {
        provinceId: this.form.provinceId
      })
        .then(res => {
          if(res.code == 200) {
            this.cityList = res.rows;
          }
        })
    },
    getAreaList(id) {
      this.$api.get('/lbg/district/list', {
        cityId: id
      })
        .then(res => {
          if(res.code == 200) {
            this.areaList = res.rows;
          }
        })
    },

  },//methods
}
</script>

<style scoped>

</style>
