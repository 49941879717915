<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'order-index',
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        
    }
}
</script>

<style lang="less" scope>

</style>